const defaultProps = {
    children: null,
    description: null,
    imageAlt: '',
    imageSrc: null,
    title: null,
};

interface ErrorPageProps {
    children?: React.ReactNode;
    description?: string;
    imageAlt?: string;
    imageSrc?: string;
    title?: string;
}

const ErrorPage = ({ children, description, imageSrc, imageAlt, title }: ErrorPageProps) => {
    return (
        <div className="main-content error-message">
            {imageSrc && <img data-testid="errorImage" src={imageSrc} alt={imageAlt} />}

            {title && (
                <h2 data-testid="errorTitle" className="error-message__title">
                    {title}
                </h2>
            )}

            {description && (
                <p data-testid="errorDescription" className="error-message__description">
                    {description}
                </p>
            )}

            {children}
        </div>
    );
};

ErrorPage.defaultProps = defaultProps;

export default ErrorPage;
