import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';

export async function acceptEULAAgreementData(AgreementStatus: string) {
    const res = await axios.post(createApiPath('clinical-workers/current/consents'), {
        type: AgreementStatus,
    });
    return res.data;
}
