import Grid from '@mui/material/Grid';

const defaultProps = {
    actions: null,
};

interface IMainContentHeaderProps {
    actions?: React.ReactNode;
    description: string;
    title: string;
}

const bemBlockName = 'main-content-header';

const MainContentHeader = ({ actions, description, title }: IMainContentHeaderProps) => {
    return (
        <header className={bemBlockName}>
            <Grid className={`${bemBlockName}__page-header`} container>
                <Grid item xs={12}>
                    <h1 className={`${bemBlockName}__title`}>{title}</h1>
                </Grid>

                <Grid item xs>
                    <p className={`${bemBlockName}__description`}>{description}</p>
                </Grid>

                {actions && (
                    <Grid className={`${bemBlockName}__actions-column`} item xs="auto">
                        {actions}
                    </Grid>
                )}
            </Grid>
        </header>
    );
};

MainContentHeader.defaultProps = defaultProps;

export default MainContentHeader;
