import { createHcpPublicPagesPath } from 'src/helpers/createPath';

import { analyticsEventNames } from './analytics';

import { FooterNavigationElement } from '../app.models';
import facebook from '../../assets/images/socialNetworks/facebook.svg';
import instagram from '../../assets/images/socialNetworks/instagram.svg';
import twitter from '../../assets/images/socialNetworks/twitter.svg';
import youtube from '../../assets/images/socialNetworks/youtube.svg';

export const links: FooterNavigationElement[] = [
    {
        title: 'footer.aboutInsulet',
        analyticsEventName: analyticsEventNames.VIEW_ABOUT_INSULET,
        url: 'https://www.omnipod.com/about-insulet',
        type: 'link',
    },
    {
        title: 'footer.contactUs',
        analyticsEventName: analyticsEventNames.VIEW_CONTACT_US,
        url: 'https://www.omnipod.com/hcp/omnipod-connect-contact',
        type: 'link',
    },
    {
        title: 'footer.insuletAlerts',
        analyticsEventName: analyticsEventNames.VIEW_INSULET_ALERTS,
        url: 'https://www.omnipod.com/insulet-alerts',
        type: 'link',
    },
    {
        title: 'footer.privacyPolicy',
        analyticsEventName: analyticsEventNames.VIEW_PRIVACY_POLICY,
        url: 'https://www.omnipod.com/privacy-policy',
        type: 'link',
    },
    {
        title: 'footer.HIPAAPrivacyNotice',
        analyticsEventName: analyticsEventNames.VIEW_HIPAA_PRIVACY_NOTICE,
        url: 'https://www.omnipod.com/hipaa-notice',
        type: 'link',
    },
    {
        title: 'footer.cookiePolicy',
        analyticsEventName: analyticsEventNames.VIEW_COOKIE_POLICY,
        url: createHcpPublicPagesPath('docs/cookies.html'),
        type: 'link',
    },
    {
        title: 'footer.termsOfUse',
        analyticsEventName: analyticsEventNames.VIEW_TERMS_OF_USE,
        url: 'https://www.omnipod.com/terms-of-use',
        type: 'link',
    },
    {
        title: 'footer.endUserLicenseAgreement',
        analyticsEventName: analyticsEventNames.VIEW_END_USER_LICENSE_AGREEMENT,
        url: createHcpPublicPagesPath('docs/legal.html'),
        type: 'link',
    },
    {
        title: 'footer.importantSafetyInformation',
        analyticsEventName: analyticsEventNames.VIEW_IMPORTANT_SAFETY_INFORMATION,
        url: 'https://www.omnipod.com/safety',
        type: 'link',
    },
    {
        title: 'footer.californiaSupplyChainsAct',
        analyticsEventName: analyticsEventNames.VIEW_CALIFORNIA_SUPPLY_CHAINS_ACT,
        url: 'https://www.omnipod.com/california-supply-chains-act',
        type: 'link',
    },
    {
        title: 'footer.californiaHealthAndSafetyCode',
        analyticsEventName: analyticsEventNames.VIEW_CALIFORNIA_HEALTH_AND_SAFETY_CODE,
        url: 'https://www.omnipod.com/declaration-under-ca-health-safety-code',
        type: 'link',
    },
    {
        title: 'footer.consumerHealthDataPrivacyPolicy',
        analyticsEventName: analyticsEventNames.VIEW_CONSUMER_HEALTH_DATA_PRIVACY_POLICY,
        url: 'https://www.omnipod.com/consumer-health-data-privacy-policy',
        type: 'link',
    },
];

export const socialNetworks = [
    {
        icon: facebook,
        analyticsEventName: analyticsEventNames.VIEW_FACEBOOK,
        url: 'https://www.facebook.com/myomnipod/',
    },
    {
        icon: instagram,
        analyticsEventName: analyticsEventNames.VIEW_INSTAGRAM,
        url: 'https://www.instagram.com/omnipod/',
    },
    {
        icon: twitter,
        analyticsEventName: analyticsEventNames.VIEW_TWITTER,
        url: 'https://twitter.com/myOmnipod',
    },
    {
        icon: youtube,
        analyticsEventName: analyticsEventNames.VIEW_YOUTUBE,
        url: 'https://www.youtube.com/channel/UCgwZ9hRegg6DZXqY4ji60vQ',
    },
];
