interface ISummaryActualTimeData {
    hours: number;
    minutes: number;
}
interface ISummaryActualCorrectData {
    percent: number;
    time: ISummaryActualTimeData;
}
interface ISummaryActualData {
    aboveTarget: ISummaryActualCorrectData;
    belowTarget: ISummaryActualCorrectData;
    veryLow: ISummaryActualCorrectData;
    low: ISummaryActualCorrectData;
    target: ISummaryActualCorrectData;
    high: ISummaryActualCorrectData;
    veryHigh: ISummaryActualCorrectData;
}

interface ISummaryTargetData {
    aboveTarget: { percent: number };
    belowTarget: { percent: number };
    target: { percent: number };
    veryHigh: { percent: number };
    veryLow: { percent: number };
}

interface IIntervalData {
    lowerBound: number;
    upperBound: number;
}
interface IGlucoseRangesData {
    veryLow: IIntervalData;
    low: IIntervalData;
    target: IIntervalData;
    high: IIntervalData;
    veryHigh: IIntervalData;
}

export function createTimeInRangeStructure(
    summaryActual: ISummaryActualData,
    summaryTarget: ISummaryTargetData,
    glucoseRanges: IGlucoseRangesData,
    units: string
) {
    return [
        {
            id: 'veryLow',
            title: 'Very Low',
            actualPercent: summaryActual.veryLow.percent,
            sumPercents: summaryActual?.veryLow.percent,
            actualHours: summaryActual?.veryLow.time.hours,
            actualMinutes: summaryActual?.veryLow.time.minutes,
            targetPercent: `<${summaryTarget.veryLow.percent}`,
            interval: `<${glucoseRanges.veryLow.upperBound}`,
            units,
            position: 'left',
            isShowInTarget: false,
        },
        {
            id: 'low',
            title: 'Low',
            actualPercent: summaryActual?.low.percent,
            sumPercents: summaryActual.belowTarget.percent,
            actualHours: summaryActual.belowTarget.time.hours,
            actualMinutes: summaryActual.belowTarget.time.minutes,
            targetPercent: `<${summaryTarget.belowTarget.percent}`,
            interval: `${glucoseRanges.low.lowerBound}-${glucoseRanges.low.upperBound}`,
            units,
            position: 'left',
            isShowInTarget: true,
        },
        {
            id: 'target',
            title: 'Time in Range',
            actualPercent: summaryActual?.target.percent,
            sumPercents: summaryActual?.target.percent,
            actualHours: summaryActual?.target.time.hours,
            actualMinutes: summaryActual?.target.time.minutes,
            targetPercent: `>${summaryTarget.target.percent}`,
            interval: `${glucoseRanges.target.lowerBound}-${glucoseRanges.target.upperBound}`,
            units,
            position: 'center',
            isShowInTarget: true,
        },
        {
            id: 'high',
            title: 'High',
            actualPercent: summaryActual?.high.percent,
            sumPercents: summaryActual.aboveTarget.percent,
            actualHours: summaryActual.aboveTarget.time.hours,
            actualMinutes: summaryActual.aboveTarget.time.minutes,
            targetPercent: `<${summaryTarget.aboveTarget.percent}`,
            interval: `${glucoseRanges.high.lowerBound}-${glucoseRanges.high.upperBound}`,
            units,
            position: 'right',
            isShowInTarget: true,
        },
        {
            id: 'veryHigh',
            title: 'Very High',
            actualPercent: summaryActual?.veryHigh.percent,
            sumPercents: summaryActual?.veryHigh.percent,
            actualHours: summaryActual?.veryHigh.time.hours,
            actualMinutes: summaryActual?.veryHigh.time.minutes,
            targetPercent: `<${summaryTarget.veryHigh.percent}`,
            interval: `>${glucoseRanges.veryHigh.lowerBound}`,
            units,
            position: 'right',
            isShowInTarget: false,
        },
    ];
}
