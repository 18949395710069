import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ClinicAdmins from './ClinicAdmins';
import Patients from './Patients';
import Providers from './Providers';

import { reinviteClinicalWorker } from '../../helpers/services/SuperAdmins';
import { IClinic } from '../../models/table.models';
import { getClinics } from '../../helpers/services/Clinics';
import Tabs from '../tabs/Tabs';
import Loading from '../loading/Loading';
import Button from '../button/Button';

export enum SuperAdminTabId {
    ClinicAdminsTab = 'clinicAdminsTab',
    ProvidersTab = 'providersTab',
    PatientsTab = 'patientsTab',
}

interface IClinicParams {
    id: string;
}

const Clinic = () => {
    const { t } = useTranslation();
    const { id: clinicId } = useParams<IClinicParams>();

    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [selectedClinicalWorkerId, setSelectedClinicalWorkerId] = useState<string | null>(null);
    const [activeTabId, setActiveTabId] = useState<string>(SuperAdminTabId.ClinicAdminsTab);

    const loadClinicName = async (id: string) => {
        const res = await getClinics();
        const clinicName = res.find((clinic: IClinic) => clinic.id === id)?.name;

        if (clinicName) {
            setName(clinicName);
            setLoading(false);
        }
    };

    const handleClinicalWorkerSelectionChange = useCallback((id: string | null) => {
        setSelectedClinicalWorkerId(id);
    }, []);

    const handleReinviteClinicalWorkerClick = async () => {
        setLoading(true);
        if (selectedClinicalWorkerId) {
            await reinviteClinicalWorker(selectedClinicalWorkerId);
        }
        setLoading(false);
    };

    const handleActiveTabChange = (id: string) => {
        setActiveTabId(id);
    };

    useEffect(() => {
        if (clinicId) {
            loadClinicName(clinicId);
            setSelectedClinicalWorkerId(null);
        }
    }, [clinicId]);

    useEffect(() => {
        setSelectedClinicalWorkerId(null);
    }, [activeTabId]);

    const clinicElements = [
        {
            id: SuperAdminTabId.ClinicAdminsTab,
            name: t('clinic.admins'),
            component: (
                <ClinicAdmins
                    handleClinicalWorkerSelectionChange={handleClinicalWorkerSelectionChange}
                    selectedClinicalWorkerId={selectedClinicalWorkerId}
                    clinicId={clinicId}
                />
            ),
        },
        {
            id: SuperAdminTabId.ProvidersTab,
            name: t('clinic.providers'),
            component: (
                <Providers
                    handleClinicalWorkerSelectionChange={handleClinicalWorkerSelectionChange}
                    selectedClinicalWorkerId={selectedClinicalWorkerId}
                    clinicId={clinicId}
                />
            ),
        },
        { id: SuperAdminTabId.PatientsTab, name: t('clinic.patients'), component: <Patients clinicId={clinicId} /> },
    ];

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="main-content clinic">
            <div className="clinic__title">{name}</div>

            {[`${SuperAdminTabId.ClinicAdminsTab}`, `${SuperAdminTabId.ProvidersTab}`].includes(activeTabId) && (
                <div className="clinic__reinvite-button">
                    <Button
                        dataTestId="clinic__button-reinvite"
                        onClick={handleReinviteClinicalWorkerClick}
                        text={t('clinic.reinvite')}
                        disabled={!selectedClinicalWorkerId}
                    />
                </div>
            )}

            <Tabs elements={clinicElements} onActiveTabChange={handleActiveTabChange} />
        </div>
    );
};

export default Clinic;
