import { IPersonName, PdmDeviceClasses } from 'src/models/app.models';

interface ISuperAdminsPatientData {
    birthdate: string;
    customerId?: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    id: string;
    isActive: boolean;
    name: IPersonName;
    smsNumber?: string;
}

export function createSuperAdminsPatientStructure(item: ISuperAdminsPatientData) {
    return {
        birthdate: item.birthdate,
        customerId: item.customerId,
        deviceClass: item.deviceClass,
        email: item.email,
        firstName: item.name.first,
        id: item.id,
        lastName: item.name.last,
        isActive: item.isActive,
        smsNumber: item.smsNumber,
    };
}

export function createSuperAdminsPatientsStructure(items: ISuperAdminsPatientData[]) {
    return items.map(createSuperAdminsPatientStructure);
}
