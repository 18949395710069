import { Route, useHistory, Switch } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';

import { analyticsEventNames } from 'src/models/consts/analytics';
import { urls } from 'src/models/consts/urls';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import createUrl from 'src/helpers/createURL';
import oktaConfig from 'src/helpers/oktaConfig';
import LoginInsuletIDCallback from 'src/components/loginInsuletIDCallback/LoginInsuletIDCallback';
import Landing from 'src/components/landing/Landing';
import withAppInsights, { reactPlugin, sendAzureEvent } from 'src/helpers/appInsights';
import LoginCallback from 'src/components/loginCallback/LoginCallback';

import '../styles/common.scss';

import PatientDetails from '../pages/PatientDetails';

const oktaAuth = new OktaAuth(oktaConfig());

const App = () => {
    const history = useHistory();

    const baseUrl = window.location.origin + getRuntimeConfig(variables.PUBLIC_URL);

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history.replace(toRelativeUrl(originalUri || '/', baseUrl));
    };

    let onAuthRequired;

    if (isPauseItEnabled()) {
        onAuthRequired = (_oktaAuth: OktaAuth) => {
            sendAzureEvent(analyticsEventNames.REDIRECT_TO_LOGIN, {
                redirectPage: _oktaAuth.options.issuer || '',
            });

            _oktaAuth.signInWithRedirect({ originalUri: '/patients' });
        };
    } else {
        const returnUrl = encodeURIComponent(`${baseUrl}/insuletid/callback`);
        const INSULET_ID_APP_NAME = getRuntimeConfig(variables.INSULET_ID_APP_NAME);
        const loginURL = `${createUrl(urls.INSULET_ID)}/login?app=${INSULET_ID_APP_NAME}&ReturnURL=${returnUrl}`;

        onAuthRequired = () => {
            sendAzureEvent(analyticsEventNames.REDIRECT_TO_LOGIN, { redirectPage: loginURL });
            window.location.assign(loginURL);
        };
    }

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
                <Switch>
                    <Route exact path="/login/callback" component={LoginCallback} />
                    <Route exact path="/insuletid/callback" component={LoginInsuletIDCallback} />
                    <Route exact path="/report" component={PatientDetails} />
                    <Route path="/" component={Landing} />
                </Switch>
            </Security>
        </AppInsightsContext.Provider>
    );
};

export default withAppInsights(App);
