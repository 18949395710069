import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { patientInviteBemBlockName } from './PatientInvite';

import Button from '../button/Button';

interface IPatientsInviteButtonProps {
    isSubmitDisabled: boolean;
    isReinvite: boolean | null;
}

const PatientsInviteButton = ({ isSubmitDisabled, isReinvite }: IPatientsInviteButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button
            className={clsx('btn', `${patientInviteBemBlockName}__submit-button`)}
            disabled={isSubmitDisabled}
            text={isReinvite ? t('patientInvite.reinviteSubmitLabel') : t('patientInvite.inviteSubmitLabel')}
            type="submit"
        />
    );
};

export default PatientsInviteButton;
