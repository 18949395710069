import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import moment from 'moment';

import PatientsPhysicianSelect from './PatientsPhysicianSelect';
import PatientsFirstNameController from './PatientsFirstNameController';
import PatientsLastNameController from './PatientsLastNameController';
import PatientsEmailController from './PatientsEmailController';
import PatientsDateOfBirthController from './PatientsDateOfBirthController';
import PatientsSelectInsulinDeviceController from './PatientsSelectInsulinDeviceController';
import PatientsInviteButton from './PatientsInviteButton';
import MovePatientPromptModal from './MovePatientPromptModal';
import { patientInviteBemBlockName, IRedirectToPatientsDirectory } from './PatientInvite';
import { IPatientInviteFormValues, IPatientInvitePauseItOnFormValues } from './patientInviteForm';

import { getProviders } from '../../helpers/services/Providers';
import { ErrorPages, IDetailedPatients } from '../../models/table.models';
import {
    NotificationSeverity,
    PdmDeviceClasses,
    CurrentUserRole,
    INotification,
    IProvider,
    IMovePatientToClinic,
    IInvitePatient,
} from '../../models/app.models';
import {
    findPatientByEmail,
    getPatientData,
    movePatient,
    invitePatient,
    reinvitePatient,
} from '../../helpers/services/Patients';
import { StatusCode } from '../../models/consts/statusCodes';
import { PatientExistErrorCodes } from '../../models/invitePatient.models';
import { setActivePage } from '../../stores/appStore/appActions';
import { RootStateType } from '../../stores/store';
import Loading from '../loading/Loading';
import Notification from '../notification/Notification';
import Header from '../header/Header';

interface IPatientInvitePauseItOnProps {
    redirectToPatientsDirectory: (args: IRedirectToPatientsDirectory) => void;
    isReinvite: boolean | null;
    searchParamsPatientId: string | null;
}

// TODO: replace isReinvite and searchParamsPatientId with reinvitePatientId.

const PatientInvitePauseItOn = ({
    redirectToPatientsDirectory,
    isReinvite,
    searchParamsPatientId,
}: IPatientInvitePauseItOnProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const {
        control,
        formState: { isValid, isSubmitted, isSubmitting },
        handleSubmit,
        reset,
        setValue,
        getValues,
    } = useForm<IPatientInviteFormValues>({
        mode: 'onChange',
    });

    const [showMovePatientModal, setShowMovePatientModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [notification, setNotification] = useState<INotification | undefined>(undefined);
    const [providers, setProviders] = useState<IProvider[] | null>(null);
    const [existingPatient, setExistingPatient] = useState<IDetailedPatients | null>(null);

    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const loadReinvitePatient = useCallback(
        async (patientId) => {
            const patientData = await getPatientData(patientId, t);

            if (!patientData) {
                return;
            }

            reset({
                patientsDateOfBirth: moment(patientData.dob),
                patientsEmail: patientData.email,
                patientsFirstName: patientData.firstName,
                patientsLastName: patientData.lastName,
                patientsPdm: patientData.deviceClass,
                patientsPhysician: patientData.physicianId,
            });
        },
        [reset, t]
    );

    const loadProviders = useCallback(async () => {
        const data = await getProviders();

        setProviders(data.items ?? []);
    }, []);

    useEffect(() => {
        if (isReinvite === null) {
            return;
        }

        loadProviders();
    }, [isReinvite, loadProviders]);

    useEffect(() => {
        if (providers === null) {
            return;
        }

        if (isReinvite) {
            loadReinvitePatient(searchParamsPatientId);

            setLoading(false);

            return;
        }

        if (currentUser.role !== CurrentUserRole.Physician) {
            setLoading(false);
            return;
        }

        const isCurrentUserIdInProviders = providers?.find((provider: IProvider) => provider.id === currentUser.id);

        if (isCurrentUserIdInProviders) {
            setValue('patientsPhysician', currentUser.id);
        }

        setLoading(false);
    }, [providers, loadReinvitePatient, isReinvite, currentUser, searchParamsPatientId, setValue]);

    const createUpsertPatientData = (
        data: IPatientInvitePauseItOnFormValues
    ): IInvitePatient | IMovePatientToClinic => {
        return {
            birthdate: moment(data.patientsDateOfBirth).format('YYYY-MM-DD'),
            deviceClass: data.patientsPdm as PdmDeviceClasses,
            email: data.patientsEmail,
            name: {
                first: data.patientsFirstName,
                last: data.patientsLastName,
            },
            physicianClinicalWorkerId: data.patientsPhysician,
        };
    };

    const handleInvitePatientError = (errStatus: StatusCode, errCode: string, email: string) => {
        switch (errStatus) {
            case StatusCode.BAD_REQUEST:
                setLoading(false);
                setNotification({
                    message: t('patientInvite.pleaseCheckYourFormAndTryAgain'),
                    severity: NotificationSeverity.Error,
                });

                break;
            case StatusCode.CONFLICT:
                (async () => {
                    const patient = await findPatientByEmail(email, t);

                    if (patient && errCode === PatientExistErrorCodes.PatientExistsInSameClinic) {
                        redirectToPatientsDirectory({
                            patientId: patient.id,
                            newNotification: {
                                message: t('patientInvite.patientExistsInTheClinic', {
                                    first: patient.firstName,
                                    last: patient.lastName,
                                    email,
                                }),
                                severity: NotificationSeverity.Error,
                            },
                        });
                    } else if (patient && errCode === PatientExistErrorCodes.PatientExistsInDifferentClinic) {
                        setLoading(false);
                        setExistingPatient(patient);
                        setShowMovePatientModal(true);
                    } else {
                        dispatch(setActivePage(ErrorPages.recall));
                    }
                })();

                break;
            default:
                dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const handleConfirmMovePatientModal = async () => {
        setLoading(true);

        const data = getValues();

        const movePatientData = createUpsertPatientData(data as IPatientInvitePauseItOnFormValues);

        let patient;

        try {
            if (existingPatient) {
                patient = await movePatient(existingPatient.id, movePatientData, t);
            }
        } catch {
            // do nothing
        }

        if (patient) {
            redirectToPatientsDirectory({
                patientId: patient.id,
                newNotification: {
                    message: t('patientInvite.movedToYourClinic', {
                        first: patient.firstName,
                        last: patient.lastName,
                    }),
                    severity: NotificationSeverity.Success,
                },
            });
        }
    };

    const handleCloseMovePatientModal = () => {
        setShowMovePatientModal(false);
    };

    const doHandleSubmit = async (data: IPatientInviteFormValues) => {
        setLoading(true);

        const invitePatientData = createUpsertPatientData(data as IPatientInvitePauseItOnFormValues);

        let patient;

        try {
            if (isReinvite) {
                patient = await reinvitePatient(invitePatientData, searchParamsPatientId as string, t);
            } else {
                patient = await invitePatient(invitePatientData, t);
            }
        } catch (err: any) {
            handleInvitePatientError(err.response?.status, err.response?.data?.error?.code, invitePatientData.email);
            return;
        }

        if (patient) {
            redirectToPatientsDirectory({
                newNotification: {
                    message: t('patientInvite.invitedAndAddedToYourClinic', {
                        first: patient.firstName,
                        last: patient.lastName,
                    }),
                    severity: NotificationSeverity.Success,
                },
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const isSubmitDisabled = !isValid || isSubmitting;

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={patientInviteBemBlockName} data-testid={patientInviteBemBlockName}>
            <Header
                title={isReinvite ? t('patientInvite.reinvitePatient') : t('patientInvite.invitePatient')}
                showBackButton
            />
            <div className={clsx('main-content', `${patientInviteBemBlockName}__container`)}>
                <form
                    autoComplete="off"
                    className={`${patientInviteBemBlockName}__form`}
                    noValidate
                    onSubmit={handleSubmit(doHandleSubmit)}
                >
                    <Grid container spacing={5}>
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <PatientsFirstNameController control={control} isSubmitted={isSubmitted} />
                            </Grid>

                            <Grid item xs={6}>
                                <PatientsLastNameController control={control} isSubmitted={isSubmitted} />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <div>
                                    <PatientsDateOfBirthController control={control} />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <PatientsEmailController control={control} isSubmitted={isSubmitted} />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <PatientsPhysicianSelect
                                    control={control}
                                    name="patientsPhysician"
                                    providers={providers}
                                    rules={{ required: true }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <PatientsSelectInsulinDeviceController
                                    control={control}
                                    pdmDeviceClassess={[
                                        PdmDeviceClasses.Omnipod5,
                                        PdmDeviceClasses.DASH,
                                        PdmDeviceClasses.Eros,
                                    ]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item justifyContent="center" spacing={2}>
                            <Grid item>
                                <PatientsInviteButton isSubmitDisabled={isSubmitDisabled} isReinvite={isReinvite} />
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <Notification applyHeaderOffset applyMaxWidth data={notification} />

            {existingPatient && (
                <MovePatientPromptModal
                    isOpen={showMovePatientModal}
                    onClose={handleCloseMovePatientModal}
                    onConfirmMove={handleConfirmMovePatientModal}
                    patient={existingPatient}
                />
            )}
        </div>
    );
};

export default PatientInvitePauseItOn;
