export enum StatusCode {
    OK = 200,
    CREATED = 201,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    CONFLICT = 409,
    INTERNAL_SERVER_ERROR = 500,
}

export enum ApiErrorCode {
    DeviceFileNotFound = 'DeviceFileNotFound',
}
