import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ClinicalWorkerStatus } from '../../models/app.models';

function isValidClinicalWorkerStatus(value: string): value is ClinicalWorkerStatus {
    return Object.values<string>(ClinicalWorkerStatus).includes(value);
}

const defaultProps = {
    disabled: false,
};

interface ClinicalWorkerStatusSelectProps {
    value: ClinicalWorkerStatus;
    onStatusChange: (status: ClinicalWorkerStatus) => void;
    disabled?: boolean;
}

const ClinicalWorkerStatusSelect = ({ value, onStatusChange, disabled }: ClinicalWorkerStatusSelectProps) => {
    const { t } = useTranslation();

    const handleChange: (event: SelectChangeEvent) => void = (event) => {
        const status: string = event.target.value;

        if (isValidClinicalWorkerStatus(status)) {
            onStatusChange(status);
        }
    };

    return (
        <FormControl>
            <Select
                className={clsx('Select', 'clinical-worker-status-select')}
                disableUnderline
                value={value}
                variant="standard"
                onChange={handleChange}
                disabled={disabled}
                MenuProps={{
                    classes: {
                        root: 'SelectPopover',
                    },
                }}
            >
                <MenuItem value="active">{t('clinicalWorkerStatus.active')}</MenuItem>
                <MenuItem value="inactive">{t('clinicalWorkerStatus.inactive')}</MenuItem>
            </Select>
        </FormControl>
    );
};

ClinicalWorkerStatusSelect.defaultProps = defaultProps;

export default ClinicalWorkerStatusSelect;
