import { ApplicationInsights, ICustomProperties } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';
import { store } from 'src/stores/store';
import { IUser } from 'src/models/app.models';

const browserHistory = createBrowserHistory({ basename: '' });
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        instrumentationKey: getRuntimeConfig(variables.AZURE_APP_INSIGHTS_KEY),
        maxBatchInterval: 0,
        disableCookiesUsage: true,
    },
});

let currentUserId: string | null = null;
let currentUser: IUser | null = null;
let signInUrl: string | null = null;

store.subscribe(() => {
    const { appState } = store.getState();

    const newCurrentUserId = appState.currentUser?.id ?? null;

    if (currentUserId === newCurrentUserId) {
        return;
    }

    if (newCurrentUserId !== null) {
        appInsights.setAuthenticatedUserContext(newCurrentUserId);
    } else {
        appInsights.clearAuthenticatedUserContext();
    }

    currentUserId = newCurrentUserId;
});

store.subscribe(() => {
    const { appState } = store.getState();

    currentUser = appState.currentUser;
});

store.subscribe(() => {
    const { appState } = store.getState();

    signInUrl = appState.signInUrl;
});

appInsights.addTelemetryInitializer((envelope) => {
    envelope.data = envelope.data || {};

    const clinicId = currentUser?.clinic?.id;
    const userType = currentUser?.role;

    envelope.data = {
        ...envelope.data,
        ...(clinicId && { clinicId }),
        ...(userType && { userType }),
    };
});

appInsights.addTelemetryInitializer((envelope) => {
    envelope.data = envelope.data || {};

    envelope.data = {
        ...envelope.data,
        ...(signInUrl && { signInUrl }),
    };
});

appInsights.loadAppInsights();

export { appInsights, reactPlugin };
export default (Component: any) => withAITracking(reactPlugin, Component);

export const sendAzureEvent = (eventName: string, properties?: ICustomProperties) =>
    appInsights.trackEvent({ name: eventName }, properties);
