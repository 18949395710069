import { useEffect, useState, lazy, Suspense, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import clsx from 'clsx';
import i18n from 'i18next';

import { IPatientData } from 'src/models/app.models';
import { RoutePath } from 'src/models/table.models';
import { checkUrlPatientDetails } from 'src/helpers/checkUrlPatientDetails';
import { parseOktaData } from 'src/helpers/parseOktaData';
import { generateReportFileName } from 'src/helpers/generateReportFileName';

import { getPatientInfo } from '../helpers/services/PatientInfoService';
import { getAppMarketTranslation } from '../helpers/getAppMarketTranslation';
import Loading from '../components/loading/Loading';

const ReportSummary = lazy(() => import('../components/reportSummary/ReportSummary'));
const ReportDaily = lazy(() => import('../components/reportDaily/ReportDaily'));
const ReportSettings = lazy(() => import('src/components/reportSettings/ReportSettings'));

export const reportFileNameElementId = 'patient-details__file-name';

const PatientDetails = () => {
    const history = useHistory();

    const [patientInfo, setPatientInfo] = useState<{ statusCode: number; data: IPatientData | null }>({
        data: null,
        statusCode: 200,
    });

    const fileName = !patientInfo.data
        ? null
        : generateReportFileName(
              patientInfo.data?.birthdate ?? null,
              patientInfo.data?.createdDate ?? null,
              patientInfo.data?.firstName ?? null,
              patientInfo.data?.lastName ?? null
          );

    const authState = useOktaAuth()?.authState;

    const setAuthorizationHeader = useCallback(() => {
        if (authState?.accessToken) {
            const oktaDataConfig = parseOktaData(authState);

            axios.defaults.headers.common.Authorization = oktaDataConfig.access;
        }
    }, [authState]);

    const loadPatientInfo = useCallback(async () => {
        const { search } = window.location;
        const { patientId, daysCount, timeZone } = checkUrlPatientDetails(search);

        const data = await getPatientInfo(patientId, daysCount, timeZone);

        const patientInfoData = data?.patientInfoData;
        const statusCode = data?.statusCode;

        if (!patientInfoData) {
            setPatientInfo((currentPatientInfo) => ({ ...currentPatientInfo, statusCode }));

            return;
        }

        setPatientInfo({ data: patientInfoData as IPatientData, statusCode });
    }, []);

    useEffect(() => {
        const { href } = window.location;

        if (!href?.includes('?')) {
            history.push(RoutePath.patients);
            return;
        }

        if (authState != null) {
            i18n.changeLanguage(getAppMarketTranslation());

            setAuthorizationHeader();
            loadPatientInfo();
        }
    }, [history, authState, setAuthorizationHeader, loadPatientInfo]);

    if (patientInfo.statusCode >= 400) return <div className="request-error">Oops... Something went wrong</div>;

    const { displayOnPrint } = checkUrlPatientDetails(window.location.search);

    return (
        <div
            className={clsx('container', 'patient-details', {
                'display-on-print-only': displayOnPrint,
            })}
        >
            {patientInfo.data ? (
                <>
                    <div id={reportFileNameElementId} className="hidden" data-testid={reportFileNameElementId}>
                        {fileName}
                    </div>

                    <Suspense fallback={<Loading className="loading-content" />}>
                        <ReportSummary data={patientInfo.data} />
                        <ReportDaily data={patientInfo.data} />
                        <ReportSettings data={patientInfo.data} />
                    </Suspense>
                </>
            ) : (
                <Loading className="loading-content" />
            )}
        </div>
    );
};

export default PatientDetails;
