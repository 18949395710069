import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import ReactDOM from 'react-dom';

import './locales/i18n';
import './styles/common.scss';

import { variables } from './models/consts/variables';
import { getRuntimeConfig } from './helpers/envReader';
import App from './app/App';
import { store } from './stores/store';
import theme from './theme/theme';

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter basename={getRuntimeConfig(variables.PUBLIC_URL)}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App />
                </LocalizationProvider>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>,
    document.getElementById('react-root')
);
