import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';

import { IClinicalWorkerInviteFormValues } from './clinicalWorkerInviteFormValues';
import ClinicalWorkersEmailController from './ClinicalWorkersEmailController';
import ClinicalWorkersFirstNameController from './ClinicalWorkersFirstNameController';
import ClinicalWorkersLastNameController from './ClinicalWorkersLastNameController';

import {
    ClinicalWorkerExistsInSameClinicErrorCodes,
    ClinicalWorkerExistsInDifferentClinicError,
    IClinicalWorkerInvitation,
} from '../../models/inviteClinicalWorker.models';
import { ErrorPages, RoutePath } from '../../models/table.models';
import { setActivePage } from '../../stores/appStore/appActions';
import { isEmpty } from '../../helpers/objectVerification';
import {
    createClinicalWorkerInvitation,
    getClinicalWorkerInvitation,
    removeClinicalWorkerInvitation,
    updateClinicalWorkerInvitation,
} from '../../helpers/services/Providers';
import { getRuntimeConfig } from '../../helpers/envReader';
import { variables } from '../../models/consts/variables';
import { IUpsertClinicalWorkerInvitation, INotification, NotificationSeverity } from '../../models/app.models';
import Button from '../button/Button';
import Callout from '../callout/Callout';
import Header from '../header/Header';
import Loading from '../loading/Loading';
import AlertModal from '../alertModal/alertModal';
import PromptModal from '../promptModal/promptModal';

const bemBlockName = 'clinical-worker-invite';

interface IEmailValidationErrorModal {
    description: string;
    isOpen: boolean;
    title: string;
}

const ClinicalWorkerInvite = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation<Location>();
    const urlSearchParams = new URLSearchParams(location.search);
    const searchParamsInvitationId: string | null = urlSearchParams.get('invitationId');

    const [existingClinicalWorkerInvitation, setExistingClinicalWorkerInvitation] =
        useState<IClinicalWorkerInvitation | null>(null);
    const [isReinvite, setIsReinvite] = useState<boolean>(false);
    const [loading, setLoading] = useState(!!searchParamsInvitationId);
    const [showRemoveInvitationConfirmationModal, setShowRemoveInvitationConfirmationModal] = useState(false);

    const [emailValidationErrorModal, setEmailValidationErrorModal] = useState<IEmailValidationErrorModal>({
        description: '',
        isOpen: false,
        title: '',
    });

    const {
        control,
        formState: { isValid, isSubmitted, isSubmitting, errors },
        handleSubmit,
        reset,
    } = useForm<IClinicalWorkerInviteFormValues>({
        mode: 'all',
    });

    const createClinicalWorkerInvitationData = (
        data: IClinicalWorkerInviteFormValues
    ): IUpsertClinicalWorkerInvitation => {
        return {
            email: data.clinicalWorkersEmail,
            name: {
                first: data.clinicalWorkersFirstName,
                last: data.clinicalWorkersLastName,
            },
        };
    };

    const loadClinicalWorkerInvitation = useCallback(
        async (invitationId) => {
            const clinicalWorkerInvitation = await getClinicalWorkerInvitation(invitationId);

            if (!clinicalWorkerInvitation) {
                setLoading(false);
                return;
            }

            setExistingClinicalWorkerInvitation(clinicalWorkerInvitation);

            reset({
                clinicalWorkersEmail: clinicalWorkerInvitation.email,
                clinicalWorkersFirstName: clinicalWorkerInvitation.firstName,
                clinicalWorkersLastName: clinicalWorkerInvitation.lastName,
            });

            setLoading(false);
        },
        [reset]
    );

    const handleUpsertClinicalWorkerInvitationError = async (errCode: string) => {
        setLoading(false);

        switch (errCode) {
            case ClinicalWorkerExistsInSameClinicErrorCodes.ClinicalWorkerApproved:
                setEmailValidationErrorModal({
                    title: t('clinicalWorkerInvite.approvedInSameClinicErrorTitle'),
                    description: t('clinicalWorkerInvite.approvedInSameClinicErrorDescription'),
                    isOpen: true,
                });
                break;
            case ClinicalWorkerExistsInSameClinicErrorCodes.ClinicalWorkerInvited:
                setEmailValidationErrorModal({
                    title: t('clinicalWorkerInvite.invitedToSameClinicErrorTitle'),
                    description: t('clinicalWorkerInvite.invitedToSameClinicErrorDescription'),
                    isOpen: true,
                });
                break;
            case ClinicalWorkerExistsInSameClinicErrorCodes.ClinicalWorkerPending:
                setEmailValidationErrorModal({
                    title: t('clinicalWorkerInvite.pendingInSameClinicErrorTitle'),
                    description: t('clinicalWorkerInvite.pendingInSameClinicErrorDescription'),
                    isOpen: true,
                });
                break;
            case ClinicalWorkerExistsInSameClinicErrorCodes.ClinicalWorkerDenied:
                setEmailValidationErrorModal({
                    title: t('clinicalWorkerInvite.deniedInSameClinicErrorTitle'),
                    description: '',
                    isOpen: true,
                });
                break;
            case ClinicalWorkerExistsInDifferentClinicError:
                setEmailValidationErrorModal({
                    title: t('clinicalWorkerInvite.existsInDifferentClinicErrorTitle'),
                    description: t('clinicalWorkerInvite.existsInDifferentClinicErrorDescription'),
                    isOpen: true,
                });
                break;
            default:
                dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const redirectToClinicalWorkersDirectory = useCallback(
        ({ newNotification }: { newNotification?: INotification }) => {
            history.push({
                pathname: RoutePath.adminProviders,
                ...(newNotification && { state: { notification: newNotification } }),
            });
        },
        [history]
    );

    const doHandleSubmit = async (data: IClinicalWorkerInviteFormValues) => {
        setLoading(true);

        const upsertClinicalWorkerInvitationData = createClinicalWorkerInvitationData(data);

        let clinicalWorkerInvitation;

        try {
            if (isReinvite && searchParamsInvitationId) {
                clinicalWorkerInvitation = await updateClinicalWorkerInvitation(
                    upsertClinicalWorkerInvitationData,
                    searchParamsInvitationId
                );
            } else {
                clinicalWorkerInvitation = await createClinicalWorkerInvitation(upsertClinicalWorkerInvitationData);
            }
        } catch (err: any) {
            handleUpsertClinicalWorkerInvitationError(err.response?.data?.error?.code);
            return;
        }

        if (clinicalWorkerInvitation) {
            let successMessage = 'clinicalWorkerInvite.invitedToYourClinicNotification';

            if (isReinvite) {
                successMessage = 'clinicalWorkerInvite.reinvitedToYourClinicNotification';
            }

            redirectToClinicalWorkersDirectory({
                newNotification: {
                    message: t(successMessage, {
                        first: clinicalWorkerInvitation.firstName,
                        last: clinicalWorkerInvitation.lastName,
                    }),
                    severity: NotificationSeverity.Success,
                },
            });
        }
    };

    const handleCloseEmailValidationErrorModal = () => {
        setEmailValidationErrorModal({
            description: '',
            isOpen: false,
            title: '',
        });
    };

    const handleClickRemoveClinicalWorkerInvitation = () => setShowRemoveInvitationConfirmationModal(true);

    const handleCloseRemoveClinicalWorkerInvitationModal = () => setShowRemoveInvitationConfirmationModal(false);

    const handleConfirmRemoveClinicalWorkerInvitation = async () => {
        if (!existingClinicalWorkerInvitation) {
            return;
        }

        setLoading(true);

        await removeClinicalWorkerInvitation(existingClinicalWorkerInvitation.id);

        redirectToClinicalWorkersDirectory({
            newNotification: {
                message: t('clinicalWorkerInvite.removedInvitationNotification', {
                    first: existingClinicalWorkerInvitation.firstName,
                    last: existingClinicalWorkerInvitation.lastName,
                }),
                severity: NotificationSeverity.Success,
            },
        });
    };

    useEffect(() => {
        const newIsReinvite = !!searchParamsInvitationId;
        setIsReinvite(newIsReinvite);

        if (newIsReinvite) {
            loadClinicalWorkerInvitation(searchParamsInvitationId);
        }
    }, [loadClinicalWorkerInvitation, searchParamsInvitationId]);

    const isSubmitDisabled = !isValid || isSubmitting || !isEmpty(errors);
    const invitationExpirationDays = Number(getRuntimeConfig(variables.CLINICAL_WORKER_INVITATION_EXPIRATION_DAYS));

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={bemBlockName} data-testid={bemBlockName}>
            <Header
                title={
                    isReinvite
                        ? t('clinicalWorkerInvite.reinvitePageHeaderTitle')
                        : t('clinicalWorkerInvite.pageHeaderTitle')
                }
                showBackButton
            />

            <div className={clsx('main-content', `${bemBlockName}__container`)}>
                <form
                    autoComplete="off"
                    className={`${bemBlockName}__form`}
                    noValidate
                    onSubmit={handleSubmit(doHandleSubmit)}
                >
                    <Grid container columnSpacing={2} rowSpacing={4}>
                        <Grid item xs={12}>
                            <Callout severity={NotificationSeverity.Info}>
                                {t('clinicalWorkerInvite.invitationExpirationNotification', {
                                    count: invitationExpirationDays,
                                })}
                            </Callout>
                        </Grid>

                        <Grid item xs={6}>
                            <ClinicalWorkersFirstNameController control={control} isSubmitted={isSubmitted} />
                        </Grid>

                        <Grid item xs={6}>
                            <ClinicalWorkersLastNameController control={control} isSubmitted={isSubmitted} />
                        </Grid>

                        <Grid item xs={12}>
                            <ClinicalWorkersEmailController control={control} isSubmitted={isSubmitted} />
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <div className={`${bemBlockName}__text-centered`}>
                                    <Button
                                        className={clsx('btn', `${bemBlockName}__submit-button`)}
                                        disabled={isSubmitDisabled}
                                        text={
                                            isReinvite
                                                ? t('clinicalWorkerInvite.reinviteSubmitLabel')
                                                : t('clinicalWorkerInvite.submitLabel')
                                        }
                                        type="submit"
                                    />
                                </div>
                            </Grid>

                            {existingClinicalWorkerInvitation && (
                                <Grid item xs={12}>
                                    <div className={`${bemBlockName}__text-centered`}>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <Link
                                            className="Link"
                                            onClick={handleClickRemoveClinicalWorkerInvitation}
                                            data-testid="remove-clinical-worker-invitation-link"
                                            color="inherit"
                                        >
                                            {t('clinicalWorkerInvite.removeInvitation')}
                                        </Link>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>

            <AlertModal
                className={`${bemBlockName}__email-validation-error-alert-modal`}
                description={emailValidationErrorModal.description}
                displayIcon
                isOpen={emailValidationErrorModal.isOpen}
                okButtonLabel={t('ok')}
                onClose={handleCloseEmailValidationErrorModal}
                title={emailValidationErrorModal.title}
            />

            {existingClinicalWorkerInvitation && (
                <PromptModal
                    title={t('clinicalWorkerInvite.removeInvitationConfirmationTitle', {
                        first: existingClinicalWorkerInvitation.firstName,
                        last: existingClinicalWorkerInvitation.lastName,
                    })}
                    description={t('clinicalWorkerInvite.removeInvitationConfirmationDescription')}
                    isOpen={showRemoveInvitationConfirmationModal}
                    onClose={handleCloseRemoveClinicalWorkerInvitationModal}
                    onOkClick={handleConfirmRemoveClinicalWorkerInvitation}
                    okButtonLabel={t('clinicalWorkerInvite.removeInvitationConfirmationOkButtonLabel')}
                />
            )}
        </div>
    );
};

export default ClinicalWorkerInvite;
