import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, ChangeEvent } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Button from '../button/Button';
import Modal from '../modal/Modal';
import { Resource, ResourceAction } from '../../models/app.models';
import { didUserPerformResourceAction, userPerformResourceAction } from '../../helpers/services/CurrentUser';
import { setCurrentUser, setRole } from '../../stores/appStore/appActions';
import { RootStateType } from '../../stores/store';
import { getActiveRole } from '../../helpers/getActiveRole';

// TODO: Handle printing inside InstructionsModal

interface IInstructionsModalProps {
    contentFilePath: string;
    onPrint: () => void;
    title: string;
    contentTitle: string;
    resource: Resource;
}

const bemBlockName = 'modal-instructions';

const InstructionsModal = ({ contentFilePath, onPrint, title, contentTitle, resource }: IInstructionsModalProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const [isOpen, setIsOpen] = useState(false);
    const [doNotShowAgain, setDoNotShowAgain] = useState(false);

    const handlePrintClick = () => onPrint();

    const handleCloseClick = async () => {
        setIsOpen(false);

        if (doNotShowAgain) {
            const updatedUser = await userPerformResourceAction(resource, ResourceAction.reviewed);
            const newActiveRole = getActiveRole(updatedUser);

            dispatch(setCurrentUser(updatedUser));
            dispatch(setRole(newActiveRole));
        }
    };

    const handleDoNotShowAgainChanged = (event: ChangeEvent<HTMLInputElement>) => {
        setDoNotShowAgain(event.target.checked);
    };

    const getContentFileSource = () => {
        const contentFileUrl = new URL(contentFilePath);
        contentFileUrl.hash = 'view=fitH&toolbar=0';
        return contentFileUrl.toString();
    };

    useEffect(() => {
        const didCurrentUserPerformResourceAction = didUserPerformResourceAction(
            currentUser,
            resource,
            ResourceAction.reviewed
        );

        setIsOpen(!didCurrentUserPerformResourceAction);
    }, [currentUser, resource]);

    if (!isOpen) return null;

    return (
        <Modal className={bemBlockName}>
            <>
                <div className={`${bemBlockName}__title`}>{title}</div>
                <div className={`${bemBlockName}__iframe-container`}>
                    <iframe
                        className={`${bemBlockName}__iframe`}
                        data-testid="instructions-iframe"
                        src={getContentFileSource()}
                        title={contentTitle}
                    />
                </div>
                <div className={`${bemBlockName}__checkbox-container`}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                className="Checkbox"
                                color="primary"
                                onChange={handleDoNotShowAgainChanged}
                                checked={doNotShowAgain}
                                data-testid="do-not-show-again-checkbox"
                            />
                        }
                        label={<p>{t('instructionsModal.doNotShowAgainCheckbox')}</p>}
                    />
                </div>
                <div className={`${bemBlockName}__actions`}>
                    <Button
                        text={t('instructionsModal.printButton')}
                        onClick={handlePrintClick}
                        dataTestId="instructions-modal-print-button"
                        className={`btn ${bemBlockName}__actions-button`}
                    />
                    <Button
                        text={t('instructionsModal.okButton')}
                        onClick={handleCloseClick}
                        dataTestId="instructions-modal-ok-button"
                        className={`btn ${bemBlockName}__actions-button`}
                    />
                </div>
            </>
        </Modal>
    );
};

export default InstructionsModal;
