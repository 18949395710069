import { TFunction } from 'i18next';

import {
    getPendingPatientsData,
    approvePendingPatientsData,
    denyPendingPatientsData,
} from 'src/web-services/Admin.service';

import { createPendingPatientsStructure } from '../createPendingPatientsStructure';

export async function getPendingPatients(t: TFunction) {
    const res = await getPendingPatientsData();
    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createPendingPatientsStructure(items, t) : [],
        totalCount: totalCount || 0,
    };
}

export async function approvePatients(patientsId: string[]) {
    await approvePendingPatientsData(
        patientsId.map((patientId) => ({
            id: patientId,
        }))
    );
}

export async function denyPatients(patientsId: string[]) {
    await denyPendingPatientsData(
        patientsId.map((patientId) => ({
            id: patientId,
        }))
    );
}
