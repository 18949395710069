import { ISummary, ICommon, IPatient, ISegmentsData } from 'src/models/report.models';
import { ISummaryChartSettings } from 'src/models/summaryChart.model';

import { createInsulinToCarbRatioStructure, createEmptyValue } from './createInsulinToCarbRatioStructure';
import { createCorrectionFactorStructure } from './createCorrectionFactorStructure';
import { createTargetGlucoseStructure } from './createTargetGlucoseStructure';
import { createCorrectAboveStructure } from './createCorrectAboveStructure';

export const patientInfoStructure = (patient: IPatient) => ({
    birthdate: patient?.birthdate,
    firstName: patient?.name.first,
    lastName: patient?.name.last,
    age: patient?.age,
});

const summarySettingDataStructure = <T>(
    data: ISegmentsData<T>,
    settingsStructureCreator: (data: T[]) => ISummaryChartSettings[]
) => (data?.segments && data?.segments.length ? settingsStructureCreator(data?.segments) : createEmptyValue());

export const summarySettingsStructure = (summary: ISummary, common: ICommon) => ({
    targetGlucoseSettings: {
        data: summarySettingDataStructure(
            summary?.settings?.bolus ? summary.settings.bolus.targetGlucoseAndCorrectAbove : { segments: [] },
            createTargetGlucoseStructure
        ),
        areSegmentsDisplayed: summary?.settings?.bolus?.targetGlucoseAndCorrectAbove?.areSegmentsDisplayed,
        units: common?.measurementUnits.glucose,
    },
    correctAboveSettings: {
        data: summarySettingDataStructure(
            summary?.settings?.bolus ? summary.settings.bolus.targetGlucoseAndCorrectAbove : { segments: [] },
            createCorrectAboveStructure
        ),
        areSegmentsDisplayed: summary?.settings?.bolus?.targetGlucoseAndCorrectAbove?.areSegmentsDisplayed,
        units: common?.measurementUnits.glucose,
    },
    insulinCorrectionFactorSettings: {
        data: summarySettingDataStructure(
            summary?.settings?.bolus ? summary.settings.bolus.correctionFactor : { segments: [] },
            createCorrectionFactorStructure
        ),
        areSegmentsDisplayed: summary?.settings?.bolus?.correctionFactor?.areSegmentsDisplayed,
        units: common?.measurementUnits.insulinCorrectionFactor,
    },
    insulinToCarbRatioSettings: {
        data: summarySettingDataStructure(
            summary?.settings?.bolus ? summary.settings.bolus.insulinToCarbRatio : { segments: [] },
            createInsulinToCarbRatioStructure
        ),
        areSegmentsDisplayed: summary?.settings?.bolus?.insulinToCarbRatio?.areSegmentsDisplayed,
        units: common?.measurementUnits.insulinToCarbRatio,
    },
    summarySettingsDevice: {
        type: summary?.settings?.device?.type,
    },
});

export const insulinStatisticsStructure = (summary: ISummary, common: ICommon) => ({
    total: summary.insulinStatistics && summary.insulinStatistics.total.averageDaily.volume,
    basal: {
        percent: summary.insulinStatistics?.basal.averageDaily.percent,
        volume: summary.insulinStatistics?.basal.averageDaily.volume,
        basalTimeInMode: {
            automated: summary.basalTimeInMode?.automated.percent ?? null,
            hypoProtect: summary.basalTimeInMode?.hypoProtect.percent ?? null,
            limited: summary.basalTimeInMode?.limited.percent ?? null,
            manual: summary.basalTimeInMode?.manual.percent ?? null,
        },
    },
    carbs: {
        value: summary.carbsStatistics?.averageDaily?.value ?? null,
        count: summary.carbsStatistics?.averageDaily?.count ?? null,
        areCarbsPresetsUsed: summary.carbsStatistics?.areCarbsPresetsUsed,
        units: common?.measurementUnits.carbs,
    },
    bolus: summary.insulinStatistics && {
        volume: summary.insulinStatistics.bolus.averageDaily.volume,
        percent: summary.insulinStatistics.bolus.averageDaily.percent,
        count: summary.insulinStatistics.bolus.averageDaily.count,
        bolusOverrideStatistics: summary.bolusOverrideStatistics && {
            percent: summary.bolusOverrideStatistics.override.percent,
            count: summary.bolusOverrideStatistics.override.count,
            days: summary.bolusOverrideStatistics.days,
        },
        mealOnlyAndMealAndCorrection: summary.bolusCauseTypeStatistics && {
            percent: summary.bolusCauseTypeStatistics.mealOnlyAndMealAndCorrection.averageDaily.percent,
            volume: summary.bolusCauseTypeStatistics.mealOnlyAndMealAndCorrection.averageDaily.volume,
        },
        correctionOnly: summary.bolusCauseTypeStatistics && {
            percent: summary.bolusCauseTypeStatistics.correctionOnly.averageDaily.percent,
            volume: summary.bolusCauseTypeStatistics.correctionOnly.averageDaily.volume,
        },
        manual: summary.bolusCauseTypeStatistics && {
            percent: summary.bolusCauseTypeStatistics.manual.averageDaily.percent,
            volume: summary.bolusCauseTypeStatistics.manual.averageDaily.volume,
        },
        units: {
            percent: '%',
            bolus: 'U',
        },
    },
});
