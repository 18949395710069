import clsx from 'clsx';

interface IRadioProps {
    activeId?: string;
    id: string;
    value?: string;
    group: string;
    className?: string;
    label?: string;
    dataTestId?: string;
    onChange: (id: string) => void;
}

const Radio = ({ activeId, id, value, group, className, label, dataTestId, onChange }: IRadioProps) => {
    const handleChange = (radioId: string) => {
        onChange(radioId);
    };

    return (
        <div className={clsx('radio', dataTestId, className)}>
            <input
                id={id}
                type="radio"
                value={value}
                name={group}
                onChange={() => handleChange(id)}
                data-testid={dataTestId}
            />
            <label htmlFor={id} className={activeId === id ? 'active' : ''}>
                {label}
            </label>
        </div>
    );
};

Radio.defaultProps = {
    activeId: '',
    value: '',
    className: '',
    label: '',
    dataTestId: '',
};

export default Radio;
