import { useController, UseControllerProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';

import { IPatientInviteFormValues } from './patientInviteForm';

import { IProvider } from '../../models/app.models';
import TextFieldSelect from '../textFieldSelect/TextFieldSelect';

export interface IPatientInviteFormPhysicianValue {
    patientsPhysician: string;
}

interface IPatientsPhysicianSelectProps extends UseControllerProps<IPatientInviteFormValues> {
    providers: IProvider[] | null;
}

function PatientsPhysicianSelect({ providers, ...otherProps }: IPatientsPhysicianSelectProps) {
    const { t } = useTranslation();
    const { field } = useController(otherProps);

    return (
        <TextFieldSelect
            fullWidth
            inputRef={field.ref}
            inputProps={{
                'data-testid': 'patientsPhysician',
            }}
            label={t('patientInvite.selectPhysician')}
            name={field.name}
            onBlur={field.onBlur}
            onChange={field.onChange}
            placeholder={t('patientInvite.selectPhysician')}
            ref={field.ref}
            value={field.value ?? ''}
        >
            {providers?.map((provider) => (
                <MenuItem key={uuidv4()} value={provider.id}>
                    {t('patientInvite.physicianName', {
                        first: `${provider.name.first}`,
                        last: `${provider.name.last}`,
                    })}
                </MenuItem>
            ))}
        </TextFieldSelect>
    );
}

export default PatientsPhysicianSelect;
