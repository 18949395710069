import { CurrentUserRole, IUser, UserRole } from '../models/app.models';

// TODO: Remove after refactoring remaining components to use redux's currentUser.role instead of activeRole.
export function getActiveRole(user: IUser) {
    switch (user?.role) {
        case CurrentUserRole.Physician:
            return UserRole.Doctor;
        case CurrentUserRole.ClinicAdmin:
            return UserRole.Admin;
        case CurrentUserRole.SuperAdmin:
            return UserRole.SuperAdmin;
        default:
            return '';
    }
}
