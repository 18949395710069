import { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import Link from '@mui/material/Link';

import PatientsFirstNameController from './PatientsFirstNameController';
import PatientsLastNameController from './PatientsLastNameController';
import PatientsEmailController from './PatientsEmailController';
import PatientsDateOfBirthController from './PatientsDateOfBirthController';
import PatientsSelectInsulinDeviceController from './PatientsSelectInsulinDeviceController';
import PatientsInviteButton from './PatientsInviteButton';
import { IRedirectToPatientsDirectory, patientInviteBemBlockName } from './PatientInvite';
import { IPatientInviteFormValues } from './patientInviteForm';

import { ErrorPages } from '../../models/table.models';
import { PdmDeviceClasses, IUpsertPatientInvitation, NotificationSeverity } from '../../models/app.models';
import {
    createPatientInvitation,
    getPatientInvitation,
    updatePatientInvitation,
    removePatientInvitation,
} from '../../helpers/services/Patients';
import Callout from '../callout/Callout';
import { getRuntimeConfig } from '../../helpers/envReader';
import { variables } from '../../models/consts/variables';
import { setActivePage } from '../../stores/appStore/appActions';
import { PatientExistErrorCodes, IPatientInvitation } from '../../models/invitePatient.models';
import Loading from '../loading/Loading';
import Header from '../header/Header';
import AlertModal from '../alertModal/alertModal';
import PromptModal from '../promptModal/promptModal';

interface IPatientInvitePauseItOffProps {
    redirectToPatientsDirectory: (args: IRedirectToPatientsDirectory) => void;
    reinvitePatientId: string | null;
}

interface IIdentificationInformationValidationErrorModal {
    title: string;
    description: string;
    isOpen: boolean;
}

const PatientInvitePauseItOff = ({ redirectToPatientsDirectory, reinvitePatientId }: IPatientInvitePauseItOffProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const invitationExpirationDays = Number(getRuntimeConfig(variables.PATIENT_INVITATION_EXPIRATION_DAYS));
    const [existingPatientInvitation, setExistingPatientInvitation] = useState<IPatientInvitation | null>(null);
    const [showRemoveInvitationConfirmationModal, setShowRemoveInvitationConfirmationModal] = useState(false);
    const [identificationInformationValidationErrorModal, setIdentificationInformationValidationErrorModal] =
        useState<IIdentificationInformationValidationErrorModal>({
            title: '',
            description: '',
            isOpen: false,
        });

    const {
        control,
        formState: { isValid, isSubmitted, isSubmitting },
        handleSubmit,
        reset,
    } = useForm<IPatientInviteFormValues>({
        mode: 'onChange',
    });

    const [loading, setLoading] = useState(!!reinvitePatientId);

    const handleCloseIdentificationInformationValidationErrorModal = () => {
        setIdentificationInformationValidationErrorModal({
            title: '',
            description: '',
            isOpen: false,
        });
    };

    const loadReinvitePatientInvitation = useCallback(
        async (patientInvitationId) => {
            const patientInvitationData = await getPatientInvitation(patientInvitationId, t);

            if (!patientInvitationData) {
                setLoading(false);
                return;
            }

            setExistingPatientInvitation(patientInvitationData);
            reset({
                patientsDateOfBirth: moment(patientInvitationData.dob),
                patientsEmail: patientInvitationData.email,
                patientsFirstName: patientInvitationData.firstName,
                patientsLastName: patientInvitationData.lastName,
                patientsPdm: patientInvitationData.deviceClass,
            });
            setLoading(false);
        },
        [reset, t]
    );

    useEffect(() => {
        if (reinvitePatientId) {
            loadReinvitePatientInvitation(reinvitePatientId);
        }
    }, [loadReinvitePatientInvitation, reinvitePatientId]);

    const handleUpsertPatientInvitationError = (errCode: string) => {
        setLoading(false);

        if (errCode === PatientExistErrorCodes.PatientApprovedInSameClinic) {
            setIdentificationInformationValidationErrorModal({
                title: t('patientInvite.approvedInSameClinicErrorTitle'),
                description: t('patientInvite.approvedInSameClinicErrorDescription'),
                isOpen: true,
            });
        } else if (errCode === PatientExistErrorCodes.PatientPendingInSameClinic) {
            setIdentificationInformationValidationErrorModal({
                title: t('patientInvite.pendingInSameClinicErrorTitle'),
                description: t('patientInvite.pendingInSameClinicErrorDescription'),
                isOpen: true,
            });
        } else if (errCode === PatientExistErrorCodes.PatientInvitedToSameClinic) {
            setIdentificationInformationValidationErrorModal({
                title: t('patientInvite.invitedToSameClinicErrorTitle'),
                description: t('patientInvite.invitedToSameClinicErrorDescription'),
                isOpen: true,
            });
        } else if (errCode === PatientExistErrorCodes.PatientDeniedInSameClinic) {
            setIdentificationInformationValidationErrorModal({
                title: t('patientInvite.deniedInSameClinicErrorTitle'),
                description: '',
                isOpen: true,
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const createUpsertPatientInvitationData = (data: IPatientInviteFormValues): IUpsertPatientInvitation => {
        return {
            birthdate: moment(data.patientsDateOfBirth).format('YYYY-MM-DD'),
            deviceClass: data.patientsPdm as PdmDeviceClasses,
            email: data.patientsEmail,
            name: {
                first: data.patientsFirstName,
                last: data.patientsLastName,
            },
        };
    };

    const handleClickRemovePatientInvitation = () => setShowRemoveInvitationConfirmationModal(true);
    const handleCloseRemovePatientInvitationModal = () => setShowRemoveInvitationConfirmationModal(false);

    const handleConfirmRemovePatientInvitation = async () => {
        if (!existingPatientInvitation) return;

        setLoading(true);

        await removePatientInvitation(existingPatientInvitation.id);

        redirectToPatientsDirectory({
            newNotification: {
                message: t('patientInvite.removedInvitationNotification', {
                    first: existingPatientInvitation.firstName,
                    last: existingPatientInvitation.lastName,
                }),
                severity: NotificationSeverity.Success,
            },
        });
    };

    const doHandleSubmit = async (data: IPatientInviteFormValues) => {
        setLoading(true);

        const upsertPatientInvitationData = createUpsertPatientInvitationData(data);

        let patient;

        try {
            if (reinvitePatientId) {
                patient = await updatePatientInvitation(upsertPatientInvitationData, reinvitePatientId, t);
            } else {
                patient = await createPatientInvitation(upsertPatientInvitationData, t);
            }
        } catch (err: any) {
            handleUpsertPatientInvitationError(err.response?.data?.error?.code);
            return;
        }

        if (patient) {
            redirectToPatientsDirectory({
                newNotification: {
                    message: t('patientInvite.invitedToYourClinicNotification', {
                        first: patient.firstName,
                        last: patient.lastName,
                    }),
                    severity: NotificationSeverity.Success,
                },
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const isSubmitDisabled = !isValid || isSubmitting;

    if (loading) {
        return <Loading />;
    }

    return (
        <div className={patientInviteBemBlockName} data-testid={patientInviteBemBlockName}>
            <Header
                title={reinvitePatientId ? t('patientInvite.reinvitePatient') : t('patientInvite.invitePatient')}
                showBackButton
            />
            <div
                className={clsx(
                    'main-content',
                    `${patientInviteBemBlockName}__container`,
                    `${patientInviteBemBlockName}__container--pause-it-off`
                )}
            >
                <form
                    autoComplete="off"
                    className={`${patientInviteBemBlockName}__form`}
                    noValidate
                    onSubmit={handleSubmit(doHandleSubmit)}
                >
                    <Grid
                        container
                        item
                        spacing={2}
                        className={`${patientInviteBemBlockName}__invitation-expiration-callout`}
                    >
                        <Grid item xs={12}>
                            <Callout severity={NotificationSeverity.Info}>
                                {t(`patientInvite.invitationExpirationNotification`, {
                                    count: invitationExpirationDays,
                                })}
                            </Callout>
                        </Grid>
                    </Grid>

                    <Grid container spacing={5}>
                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <PatientsFirstNameController control={control} isSubmitted={isSubmitted} />
                            </Grid>

                            <Grid item xs={6}>
                                <PatientsLastNameController control={control} isSubmitted={isSubmitted} />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={6}>
                                <div>
                                    <PatientsDateOfBirthController control={control} />
                                </div>
                            </Grid>

                            <Grid item xs={6}>
                                <PatientsEmailController control={control} isSubmitted={isSubmitted} />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <PatientsSelectInsulinDeviceController
                                    control={control}
                                    pdmDeviceClassess={[PdmDeviceClasses.Omnipod5, PdmDeviceClasses.DASH]}
                                />
                            </Grid>
                        </Grid>

                        <Grid container item spacing={2}>
                            <Grid item xs={12}>
                                <div className={`${patientInviteBemBlockName}__text-centered`}>
                                    <PatientsInviteButton
                                        isSubmitDisabled={isSubmitDisabled}
                                        isReinvite={!!reinvitePatientId}
                                    />
                                </div>
                            </Grid>
                            {existingPatientInvitation && (
                                <Grid item xs={12}>
                                    <div className={`${patientInviteBemBlockName}__text-centered`}>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <Link
                                            className="Link"
                                            onClick={handleClickRemovePatientInvitation}
                                            data-testid="removePatientInvitationLink"
                                            color="inherit"
                                        >
                                            {t('patientInvite.removeInvitation')}
                                        </Link>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>

            <AlertModal
                title={identificationInformationValidationErrorModal.title}
                description={identificationInformationValidationErrorModal.description}
                isOpen={identificationInformationValidationErrorModal.isOpen}
                onClose={handleCloseIdentificationInformationValidationErrorModal}
                okButtonLabel={t('ok')}
            />

            {existingPatientInvitation && (
                <PromptModal
                    title={t('patientInvite.removeInvitationConfirmationTitle', {
                        first: existingPatientInvitation.firstName,
                        last: existingPatientInvitation.lastName,
                    })}
                    description={t('patientInvite.removeInvitationConfirmationDescription')}
                    isOpen={showRemoveInvitationConfirmationModal}
                    onClose={handleCloseRemovePatientInvitationModal}
                    onOkClick={handleConfirmRemovePatientInvitation}
                    okButtonLabel={t('shortExpressions.yes')}
                />
            )}
        </div>
    );
};

export default PatientInvitePauseItOff;
