import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { IInviteClinicalWorker, IUpdateClinicalWorker, IUpdateSuperAdminPatient } from 'src/models/app.models';

export async function addClinicalWorkerData(data: IInviteClinicalWorker) {
    const res = await axios.post(createApiPath(`super-admins/clinical-workers`), data);

    return res?.data;
}

export async function deactivateSuperAdminData(id: string) {
    const res = await axios.post(createApiPath(`/super-admins/${id}/deactivate`));

    return res?.data;
}

export async function getClinicAdminsByClinicIdData(clinicId: string) {
    const res = await axios.get(createApiPath(`super-admins/clinics/${clinicId}/admins`));

    return res?.data;
}

export async function getPatientsByClinicIdData(clinicId: string) {
    const res = await axios.get(createApiPath(`/super-admins/clinics/${clinicId}/patients`));

    return res?.data;
}

export async function getProvidersByClinicIdData(clinicId: string) {
    const res = await axios.get(createApiPath(`super-admins/clinics/${clinicId}/providers`));

    return res?.data;
}

export async function getSuperAdminsData() {
    const res = await axios.get(createApiPath(`/super-admins`));

    return res?.data;
}

export async function reactivateSuperAdminData(id: string) {
    const res = await axios.post(createApiPath(`/super-admins/${id}/reactivate`));

    return res?.data;
}

export async function reinviteClinicalWorkerData(id: string) {
    const res = await axios.post(createApiPath(`/super-admins/clinical-workers/${id}/reinvite`));
    return res?.data;
}

export async function updateClinicalWorkerByIdData(workerId: string, data: IUpdateClinicalWorker) {
    const res = await axios.put(createApiPath(`super-admins/clinical-workers/${workerId}`), data);

    return res?.data;
}

export async function updatePatientByIdData(
    patientId: string,
    forceSaveCustomerId: boolean,
    data: IUpdateSuperAdminPatient
) {
    const updatePatientByIdAxiosInstance = axios.create();

    const res = await updatePatientByIdAxiosInstance.put(
        createApiPath(`super-admins/patients/${patientId}?forceSaveCustomerId=${forceSaveCustomerId}`),
        data
    );

    return res?.data;
}
