import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useHistory, useLocation } from 'react-router';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import i18n from 'i18next';

import { analyticsEventNames } from 'src/models/consts/analytics';
import { RoutePath } from 'src/models/table.models';
import { getCurrentUser } from 'src/helpers/services/CurrentUser';
import { getPostAuthenticationRedirectUrl } from 'src/helpers/routing';
import { isBrowserSupported } from 'src/helpers/isBrowserSupported';
import { RootStateType } from 'src/stores/store';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { setAgreementStatus, setCurrentUser, setRole } from 'src/stores/appStore/appActions';
import { AgreementStatus, IUser, CurrentUserRole } from 'src/models/app.models';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import { getActiveRole } from 'src/helpers/getActiveRole';
import { getAppMarketTranslation } from 'src/helpers/getAppMarketTranslation';
import About from 'src/components/about/About';
import Agreements from 'src/components/agreements/Agreements';
import Clinic from 'src/components/clinic/Clinic';
import ClinicalWorkerInvite from 'src/components/clinicalWorkerInvite/ClinicalWorkerInvite';
import ErrorHandler from 'src/components/errorHandler/ErrorHandler';
import Footer from 'src/components/footer/Footer';
import Help from 'src/components/help/Help';
import HomeHeader from 'src/components/homeHeader/HomeHeader';
import Loading from 'src/components/loading/Loading';
import PatientDirectory from 'src/components/patientDirectory/PatientDirectory';
import PatientsInvite from 'src/components/patientInvite/PatientInvite';
import PdmUploader from 'src/components/pdmUploader/PdmUploader';
import PdmUploaderDateChange from 'src/components/pdmUploaderDateChange/PdmUploaderDateChange';
import PendingPatients from 'src/components/pendingPatients/PendingPatients';
import PendingProviders from 'src/components/pendingProviders/PendingProviders';
import Providers from 'src/components/providers/Providers';
import Sidebar from 'src/components/sidebar/Sidebar';
import SuperAdmins from 'src/components/superAdmins/SuperAdmins';
import UserProtectedRoute from 'src/components/userProtectedRoute/UserProtectedRoute';

function isValidCurrentUserRole(value: string): value is CurrentUserRole {
    return Object.values<string>(CurrentUserRole).includes(value);
}

export const bemBlockName = 'home';

// TODO Refactor this component, as implemented, it has too many responsibilities.
const Home = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [loading, setLoading] = useState(true);

    const oktaData = useSelector((state: RootStateType) => state.appState.oktaData);

    // TODO: Create component or helper.
    const showHeaderAndSideBar =
        location.pathname !== RoutePath.pdmUploader &&
        location.pathname !== RoutePath.adminPatientsInvite &&
        location.pathname !== RoutePath.pdmUploaderDateChange &&
        location.pathname !== RoutePath.adminClinicalWorkersInvite &&
        location.pathname !== RoutePath.patientsInvite;

    const redirectAfterAuthentication = useCallback(
        (user: IUser) => {
            const postLoadRedirectPath = getPostAuthenticationRedirectUrl(history.location, user.role);

            history.replace(postLoadRedirectPath);
        },
        [history]
    );

    const handleLoadedCurrentUser = useCallback(
        (user: IUser) => {
            const isCurrentUserRoleSuperAdmin = user?.role === CurrentUserRole.SuperAdmin;

            dispatch(setCurrentUser(user));

            if (isValidCurrentUserRole(user?.role)) {
                sendAzureEvent(analyticsEventNames.SET_USER_TYPE);
            }

            // TODO: create test cases to cover dispatching setAgreementStatus to both confirmed and eula
            if (user?.isLatestEulaVersionAccepted && !isCurrentUserRoleSuperAdmin) {
                dispatch(setAgreementStatus(AgreementStatus.CONFIRMED));

                return;
            }

            if (!user?.isLatestEulaVersionAccepted && !isCurrentUserRoleSuperAdmin) {
                dispatch(setAgreementStatus(AgreementStatus.EULA));

                return;
            }

            const newActiveRole = getActiveRole(user);

            dispatch(setRole(newActiveRole));
            redirectAfterAuthentication(user);
        },
        [dispatch, redirectAfterAuthentication]
    );

    useEffect(() => {
        if (loading && oktaData) {
            // TODO: Create function for async method instead of using IIFE.
            (async () => {
                const user = await getCurrentUser();

                handleLoadedCurrentUser(user);

                setLoading(false);
            })();
        }
    }, [dispatch, handleLoadedCurrentUser, history.location.pathname, loading, oktaData]);

    useEffect(() => {
        i18n.changeLanguage(getAppMarketTranslation());
    }, []);

    const supportedBrowser = useMemo(() => isBrowserSupported(), []);

    return (
        <div className={clsx('home', { 'home_unsupported-browser': !supportedBrowser })}>
            {!supportedBrowser && (
                <div className={`${bemBlockName}__banner`}>
                    <div>{t('browserNotSupported')}</div>
                </div>
            )}

            {showHeaderAndSideBar && <HomeHeader />}

            <Grid
                container
                data-testid="main-content-container"
                className={clsx(`${bemBlockName}__main-content`, {
                    [`${bemBlockName}__no-sidebar`]: !showHeaderAndSideBar,
                })}
                wrap="nowrap"
            >
                {showHeaderAndSideBar && (
                    <Grid item xs="auto">
                        <Sidebar />
                    </Grid>
                )}

                <Grid item xs>
                    {oktaData && (
                        <ErrorHandler>
                            <Agreements>
                                {loading ? (
                                    <Loading />
                                ) : (
                                    <>
                                        <Route exact path={RoutePath.about} component={About} />
                                        <Route exact path={RoutePath.adminAbout} component={About} />
                                        <Route exact path={RoutePath.help} component={Help} />
                                        <Route exact path={RoutePath.adminHelp} component={Help} />

                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.adminPatients}
                                            component={PatientDirectory}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.patients}
                                            component={PatientDirectory}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.patientsInvite}
                                            component={PatientsInvite}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.adminPatientsInvite}
                                            component={PatientsInvite}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.pdmUploader}
                                            component={PdmUploader}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.pdmUploaderDateChange}
                                            component={PdmUploaderDateChange}
                                        />
                                        <UserProtectedRoute
                                            exact
                                            path={RoutePath.adminClinicalWorkersInvite}
                                            component={ClinicalWorkerInvite}
                                        />

                                        {isPauseItEnabled() ? (
                                            <>
                                                <Route exact path={RoutePath.superAdmins} component={SuperAdmins} />
                                                <Route path={RoutePath.clinics} component={Clinic} />
                                            </>
                                        ) : (
                                            <>
                                                <UserProtectedRoute
                                                    exact
                                                    path={RoutePath.adminProviders}
                                                    component={Providers}
                                                />
                                                <UserProtectedRoute
                                                    exact
                                                    path={RoutePath.adminPendingPatients}
                                                    component={PendingPatients}
                                                />
                                                <UserProtectedRoute
                                                    exact
                                                    path={RoutePath.adminPendingProviders}
                                                    component={PendingProviders}
                                                />
                                            </>
                                        )}
                                    </>
                                )}
                            </Agreements>
                        </ErrorHandler>
                    )}
                </Grid>
            </Grid>

            <div className={`${bemBlockName}__footer`}>
                <Footer />
            </div>
        </div>
    );
};

export default Home;
