export const isEqual = (
    object:
        | Record<string | number | symbol, any>
        | (string | number | Record<string | number | symbol, any>)[]
        | string
        | number,
    other:
        | Record<string | number | symbol, any>
        | (string | number | Record<string | number | symbol, any>)[]
        | string
        | number
) => JSON.stringify(object) === JSON.stringify(other);

export const isEmpty = (value?: Record<string, any> | string | [] | null) => {
    if (value == null) {
        return true;
    }

    if (Array.isArray(value) || typeof value === 'string') {
        return !value.length;
    }

    if (typeof value === 'object') {
        return isEqual(value, {});
    }

    return true;
};
