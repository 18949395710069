import {
    requestSavingUploadedFileData,
    requestCorrectingUploadedFileDataDates,
    getUploadMetadataData,
} from 'src/web-services/Upload.service';

export async function saveUploadedFileData(fileId: string) {
    await requestSavingUploadedFileData(fileId);
}

export async function correctUploadedFileDataDates(
    fileId: string,
    currentDate: string,
    enteredCurrentDeviceDate: string
) {
    await requestCorrectingUploadedFileDataDates(fileId, currentDate, enteredCurrentDeviceDate);
}

export async function getUploadMetadata(uploadId: string) {
    const res = await getUploadMetadataData(uploadId);
    return res?.data?.item ?? null;
}
