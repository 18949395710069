import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { ErrorPages, IApprovedClinicalWorkersTableRows, RoutePath } from 'src/models/table.models';
import {
    updateClinicalWorkerRole,
    deactivateClinicalWorker,
    getApprovedClinicalWorkers,
    reactivateClinicalWorker,
} from 'src/helpers/services/Providers';
import { ClinicalWorkerRole, ClinicalWorkerStatus, INotification, NotificationSeverity } from 'src/models/app.models';
import { setActivePage } from 'src/stores/appStore/appActions';

import Button from '../button/Button';
import Loading from '../loading/Loading';
import MainContentHeader from '../mainContentHeader/MainContentHeader';
import ProvidersTable from '../providersTable/ProvidersTable';
import Notification from '../notification/Notification';
import PromptModal from '../promptModal/promptModal';

const bemBlockName = 'clinical-workers';

const tableHead = [
    {
        name: 'table.providerName',
        size: 'large',
    },
    {
        name: 'table.email',
        size: 'large',
    },
    {
        name: 'table.permissions',
        className: `${bemBlockName}__permissions-table-column`,
    },
    {
        name: 'table.status',
        className: `${bemBlockName}__status-table-column`,
    },
    {
        name: 'table.credentials',
        size: 'large',
    },
    {
        name: 'table.npi',
        size: 'small',
    },
];

interface IClinicalWorkerToChangePermissionsData {
    id: string;
    role: ClinicalWorkerRole;
}

/**
 * TODO: Rename Providers to ClinicalWorkers
 */
const Providers = () => {
    const { t } = useTranslation();
    const history = useHistory();
    /**
     * TODO: Discuss approaches in future on how we should make API calls.
     * Maybe create custom useGetClinicalWorkers (or some similar name) hook to remove responsibility from this component of managing state.
     * At time of working through this issue, we may want to address our ./helpers/services wrappers. IMO, there isn't a need to rewrite response data.
     */
    const [clinicalWorkers, setClinicalWorkers] = useState([] as IApprovedClinicalWorkersTableRows[]);
    const [loading, setLoading] = useState(true);
    const [showDeactivateConfirmationModal, setShowDeactivateConfirmationModal] = useState(false);
    const [showPermissionsChangeConfirmationModal, setShowPermissionsChangeConfirmationModal] = useState(false);
    const [clinicalWorkerIdToChangeStatus, setClinicalWorkerIdToChangeStatus] = useState<string | null>(null);
    const [clinicalWorkerToChangePermissionsData, setClinicalWorkerToChangePermissionsData] =
        useState<IClinicalWorkerToChangePermissionsData | null>(null);
    const [notification, setNotification] = useState<INotification | undefined>();
    const dispatch = useDispatch();

    const loadClinicalWorkers = async () => {
        const approvedClinicalWorkersData = await getApprovedClinicalWorkers();
        setClinicalWorkers(approvedClinicalWorkersData.items);

        setLoading(false);

        return approvedClinicalWorkersData.items;
    };

    useEffect(() => {
        loadClinicalWorkers();
    }, []);

    const directToClinicalWorkerInvite = (invitationId?: string) => {
        const searchParams = new URLSearchParams();

        if (invitationId) {
            searchParams.set('invitationId', invitationId);
        }

        history.push({
            pathname: RoutePath.adminClinicalWorkersInvite,
            ...(searchParams.keys().next().value && { search: searchParams.toString() }),
        });
    };

    const handleCloseClinicalWorkerDeactivateConfirmationModal = () => {
        setShowDeactivateConfirmationModal(false);
    };

    const handleClinicalWorkerStatusChangeSuccess = async (clinicalWorkerId: string) => {
        const newClinicalWorkers: IApprovedClinicalWorkersTableRows[] = await loadClinicalWorkers();

        setClinicalWorkerIdToChangeStatus(null);

        const updatedClinicalWorker = newClinicalWorkers.find(
            (clinicalWorker) => clinicalWorker.id === clinicalWorkerId
        );

        if (updatedClinicalWorker) {
            setNotification({
                message: t('clinicalWorkers.statusChangedNotification', {
                    first: updatedClinicalWorker.firstName,
                    last: updatedClinicalWorker.lastName,
                }),
                severity: NotificationSeverity.Success,
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const handleConfirmDeactivateClinicalWorker = async () => {
        handleCloseClinicalWorkerDeactivateConfirmationModal();

        setLoading(true);

        if (clinicalWorkerIdToChangeStatus) {
            await deactivateClinicalWorker(clinicalWorkerIdToChangeStatus);
            handleClinicalWorkerStatusChangeSuccess(clinicalWorkerIdToChangeStatus);
        }
    };

    const handleReactivateClinicalWorker = async (clinicalWorkerId: string) => {
        setLoading(true);

        await reactivateClinicalWorker(clinicalWorkerId);

        handleClinicalWorkerStatusChangeSuccess(clinicalWorkerId);
    };

    const handleClinicalWorkerStatusChange = (clinicalWorkerId: string, status: ClinicalWorkerStatus) => {
        setClinicalWorkerIdToChangeStatus(clinicalWorkerId);

        if (status === ClinicalWorkerStatus.Inactive) {
            setShowDeactivateConfirmationModal(true);
        } else if (status === ClinicalWorkerStatus.Active) {
            handleReactivateClinicalWorker(clinicalWorkerId);
        }
    };

    const handleCloseClinicalWorkerPermissionsChangeConfirmationModal = () => {
        setShowPermissionsChangeConfirmationModal(false);
    };

    const handleClinicalWorkerPermissionsChange = (clinicalWorkerId: string, role: ClinicalWorkerRole) => {
        setShowPermissionsChangeConfirmationModal(true);
        setClinicalWorkerToChangePermissionsData({
            id: clinicalWorkerId,
            role,
        });
    };

    const handleClinicalWorkerPermissionsChangeSuccess = async () => {
        if (!clinicalWorkerToChangePermissionsData) {
            return;
        }

        const newClinicalWorkers: IApprovedClinicalWorkersTableRows[] = await loadClinicalWorkers();

        setClinicalWorkerToChangePermissionsData(null);

        const updatedClinicalWorker = newClinicalWorkers.find(
            (clinicalWorker) => clinicalWorker.id === clinicalWorkerToChangePermissionsData.id
        );

        if (updatedClinicalWorker) {
            setNotification({
                message: t('clinicalWorkers.permissionsChangedNotification', {
                    first: updatedClinicalWorker.firstName,
                    last: updatedClinicalWorker.lastName,
                }),
                severity: NotificationSeverity.Success,
            });
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const handleConfirmClinicalWorkerPermissionsChange = async () => {
        if (!clinicalWorkerToChangePermissionsData) {
            return;
        }

        handleCloseClinicalWorkerPermissionsChangeConfirmationModal();

        setLoading(true);

        await updateClinicalWorkerRole(
            clinicalWorkerToChangePermissionsData.id,
            clinicalWorkerToChangePermissionsData.role
        );

        handleClinicalWorkerPermissionsChangeSuccess();
    };

    const handleClickReinviteLink = (invitationId: string) => {
        directToClinicalWorkerInvite(invitationId);
    };

    const handleClickInviteButton = () => {
        directToClinicalWorkerInvite();
    };

    /**
     * TODO: Should not use the class name "patientDirectory." Need to make common rules in order for "Clinical Workers" to
     * take advantage of what is in "patientDirectory" SCSS.
     */
    return (
        <div className={clsx('main-content', 'patientDirectory', bemBlockName)}>
            <MainContentHeader
                actions={
                    <Button
                        className={clsx('btn', `${bemBlockName}__invite_clinical_worker_button`)}
                        dataTestId={`${bemBlockName}__invite_clinical_worker_button`}
                        onClick={handleClickInviteButton}
                        text={t('clinicalWorkers.inviteClinicalWorker')}
                    />
                }
                description={t('clinicalWorkers.description')}
                title={t('clinicalWorkers.title')}
            />

            {loading ? (
                <Loading />
            ) : (
                <>
                    {/* TODO: Look for a better way to make ProvidersTable more reusable for other features to utilize. */}
                    {/* We have two different component approaches here: a data grid and a basic table. Do one or the other. */}
                    {/* May want to look into Super Admin Portal's Data Grids or maybe using MUI X's Data Grid. */}
                    <ProvidersTable
                        columns={tableHead}
                        rows={clinicalWorkers}
                        onStatusChange={handleClinicalWorkerStatusChange}
                        onPermissionsChange={handleClinicalWorkerPermissionsChange}
                        onReinviteLinkClick={handleClickReinviteLink}
                    />

                    <PromptModal
                        description={t('clinicalWorkers.deactivateModalDescription')}
                        title={t('clinicalWorkers.deactivateModalTitle')}
                        isOpen={showDeactivateConfirmationModal}
                        okButtonLabel={t('clinicalWorkers.changePermissionsModalOkButtonLabel')}
                        onClose={handleCloseClinicalWorkerDeactivateConfirmationModal}
                        onOkClick={handleConfirmDeactivateClinicalWorker}
                    />

                    <PromptModal
                        title={t('clinicalWorkers.changePermissionsModalTitle')}
                        description={
                            clinicalWorkerToChangePermissionsData?.role === ClinicalWorkerRole.Provider
                                ? t('clinicalWorkers.changePermissionsToStandardModalDescription')
                                : t('clinicalWorkers.changePermissionsToAdminModalDescription')
                        }
                        isOpen={showPermissionsChangeConfirmationModal}
                        okButtonLabel={t('clinicalWorkers.changePermissionsModalOkButtonLabel')}
                        onClose={handleCloseClinicalWorkerPermissionsChangeConfirmationModal}
                        onOkClick={handleConfirmClinicalWorkerPermissionsChange}
                    />
                </>
            )}

            <Notification applyHomeHeaderOffset applySidebarOffset data={notification} />
        </div>
    );
};

export default Providers;
