import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';

import { IPatientInviteFormValues } from './patientInviteForm';

import { getDateOfBirthAfterCurrentDateError } from '../../helpers/formFieldValidation';
import DatePicker from '../datePicker/DatePicker';

export interface IPatientInviteFormDateOfBirthValue {
    patientsDateOfBirth: Moment | null;
}

interface IPatientsDateOfBirthControllerProps {
    control: Control<IPatientInviteFormValues>;
}

const patientsDateOfBirthName = 'patientsDateOfBirth';

const PatientsDateOfBirthController = ({ control }: IPatientsDateOfBirthControllerProps) => {
    const { t } = useTranslation();

    // constant patientDateOfBirthDefaultValue was moved inside the component due to testing conflicts
    const patientDateOfBirthDefaultValue = null;
    const maxDate = moment();

    return (
        <Controller
            control={control}
            defaultValue={patientDateOfBirthDefaultValue}
            name={patientsDateOfBirthName}
            render={({ field, fieldState, formState }) => (
                <DatePicker
                    error={!!formState.errors.patientsDateOfBirth}
                    fullWidth
                    helperText={fieldState.error?.message}
                    label={t('patientInvite.patientsDateOfBirth')}
                    maxDate={maxDate}
                    onChange={field.onChange}
                    placeholder={t('patientInvite.patientsDateOfBirth')}
                    value={field.value}
                />
            )}
            rules={{
                required: t<string>('patientInvite.enterPatientsDateOfBirth'),
                validate: (value) =>
                    getDateOfBirthAfterCurrentDateError(
                        value,
                        t('patientInvite.dateOfBirthCannotBeAFutureDate'),
                        maxDate
                    ),
            }}
        />
    );
};

export default PatientsDateOfBirthController;
