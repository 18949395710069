import { AppStateType } from 'src/models/app.models';
import { INITIAL_ROWS_PER_PAGE } from 'src/components/pagination/Pagination';

import {
    saveOktaData,
    setActivePage,
    setAgreementStatus,
    setClinicId,
    setCurrentUser,
    setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval,
    setPdmUploadFailCount,
    setRole,
    setRowsPerPage,
    setSignInUrl,
} from './appActions';

import ActionTypes from '../actionTypes';

function setDefaultAction() {
    return {
        type: ActionTypes.DEFAULT_TYPE,
        payload: '',
    } as const;
}

const defaultState: AppStateType = {
    activePage: '',
    agreementStatus: null,
    clinicId: null,
    currentUser: null,
    oktaData: null,
    pdmUploadFailCount: 0,
    role: '',
    rowsPerPage: INITIAL_ROWS_PER_PAGE,
    signInUrl: '',
    shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval: false,
};

export type AppActionType =
    | ReturnType<typeof setActivePage>
    | ReturnType<typeof setClinicId>
    | ReturnType<typeof setCurrentUser>
    | ReturnType<typeof setRole>
    | ReturnType<typeof saveOktaData>
    | ReturnType<typeof setAgreementStatus>
    | ReturnType<typeof setPdmUploadFailCount>
    | ReturnType<typeof setDefaultAction>
    | ReturnType<typeof setRowsPerPage>
    | ReturnType<typeof setSignInUrl>
    | ReturnType<typeof setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval>;

export function appReducer(state = defaultState, action: AppActionType = setDefaultAction()) {
    switch (action?.type) {
        case ActionTypes.SET_ACTIVE_PAGE:
            return {
                ...state,
                activePage: action.payload,
            };
        case ActionTypes.SET_CLINIC_ID:
            return {
                ...state,
                clinicId: action.payload,
            };
        case ActionTypes.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: action.payload,
            };
        // TODO: remove role and use currentUser.
        case ActionTypes.SET_ROLE:
            return {
                ...state,
                role: action.payload,
            };
        case ActionTypes.SAVE_OKTA_DATA:
            return {
                ...state,
                oktaData: action.payload,
            };
        case ActionTypes.SET_AGREEMENT_STATUS:
            return {
                ...state,
                agreementStatus: action.payload,
            };
        case ActionTypes.INCREMENT_PDM_UPLOAD_FAIL_COUNT:
            return {
                ...state,
                pdmUploadFailCount: action.payload,
            };
        case ActionTypes.ROWS_PER_PAGE:
            return {
                ...state,
                rowsPerPage: action.payload,
            };
        case ActionTypes.SET_SIGN_IN_URL:
            return {
                ...state,
                signInUrl: action.payload,
            };
        case ActionTypes.SET_SHOULD_RESET_PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS_TIMER_INTERVAL:
            return {
                ...state,
                shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval: action.payload,
            };
        default:
            return state;
    }
}
