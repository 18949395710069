import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import { IPatientInviteFormValues } from './patientInviteForm';

import { getInvalidNameFieldError, getNameFieldWhitespaceError } from '../../helpers/formFieldValidation';

export interface IPatientInviteFormLastNameValue {
    patientsLastName: string;
}

interface IPatientsLastNameControllerProps {
    control: Control<IPatientInviteFormValues>;
    isSubmitted: boolean;
}

const patientsLastNameDefaultValue = '';
const patientsLastNameMaxLength = 80;
const patientsLastNameName = 'patientsLastName';

const PatientsLastNameController = ({ control, isSubmitted }: IPatientsLastNameControllerProps) => {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            defaultValue={patientsLastNameDefaultValue}
            name={patientsLastNameName}
            render={({ field, fieldState, formState }) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="TextField"
                    error={!!formState.errors.patientsLastName}
                    fullWidth
                    helperText={fieldState.error?.message}
                    inputProps={{
                        'data-testid': patientsLastNameName,
                        maxLength: patientsLastNameMaxLength,
                    }}
                    label={t('patientInvite.patientsLastName')}
                    variant="outlined"
                />
            )}
            rules={{
                required: t<string>('patientInvite.enterPatientsLastName'),
                validate: {
                    default: (value) =>
                        getInvalidNameFieldError(value, t('patientInvite.validCharactersAre'), isSubmitted, false),
                    whitespace: (value) => getNameFieldWhitespaceError(value, t('patientInvite.enterPatientsLastName')),
                },
            }}
        />
    );
};

export default PatientsLastNameController;
