import { PaletteOptions } from '@mui/material/styles/createPalette';

// These values are defined in ./src/styles/_variables.scss
// When updating, please do in parallel.
const palette: PaletteOptions = {
    action: {
        disabledBackground: '#E8E8E8',
    },
    error: {
        main: '#f75e4c',
    },
    primary: {
        dark: '#602CA5',
        main: '#8250C3',
    },
    secondary: {
        main: '#ffa700',
    },
    text: {
        disabled: '#949494',
        primary: '#000000',
        secondary: '#343434',
    },
};

export default palette;
