import { ClinicalWorkerRole, IPersonName } from 'src/models/app.models';

interface ISuperAdminsClinicalWorkerData {
    email: string;
    name: IPersonName;
    id: string;
    isActive: boolean;
    type?: ClinicalWorkerRole;
}

export function createSuperAdminsClinicalWorkerStructure(item: ISuperAdminsClinicalWorkerData) {
    return {
        email: item.email,
        firstName: item.name.first,
        lastName: item.name.last,
        id: item.id,
        isActive: item.isActive,
        type: item.type,
    };
}

export function createSuperAdminsClinicalWorkersStructure(items: ISuperAdminsClinicalWorkerData[]) {
    return items.map(createSuperAdminsClinicalWorkerStructure);
}
