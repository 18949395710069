import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { Column } from '@material-table/core';
import clsx from 'clsx';
import parsePhoneNumber from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { deviceClass } from '../../helpers/deviceClass';
import { IMaterialTableRowData, ISuperAdminsPatient } from '../../models/app.models';
import { getPatientsByClinicId, updatePatientById } from '../../helpers/services/SuperAdmins';
import {
    emailTableColumn,
    firstNameTableColumn,
    lastNameTableColumn,
    statusTableColumn,
    tableColumnCellStyle,
} from '../../helpers/superAdminManageAccountsBasicTableColumns';
import { setActivePage } from '../../stores/appStore/appActions';
import { ErrorPages } from '../../models/table.models';
import { SHORT_MONTH_DATE_FORMAT, formatDate } from '../../helpers/formatDate';
import { UpdatePatientErrorCodes } from '../../models/updatePatientErrorCodes.models';
import { isEmpty, isEqual } from '../../helpers/objectVerification';
import AlertModal from '../alertModal/alertModal';
import Loading from '../loading/Loading';
import MaterialTable from '../materialTable/MaterialTable';
import PromptModal from '../promptModal/promptModal';

const bemBlockName = 'clinic-patients';

type SuperAdminsPatientRowData = ISuperAdminsPatient & IMaterialTableRowData;

interface IPatientsProps {
    clinicId: string;
}

const Patients = ({ clinicId }: IPatientsProps) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [customerIdDifferentPatient, setCustomerIdDifferentPatient] = useState<ISuperAdminsPatient | null>(null);
    const [patients, setPatients] = useState<SuperAdminsPatientRowData[]>([]);
    const [showCustomerIdAssignedToAnotherPatientAlertModal, setShowCustomerIdAssignedToAnotherPatientAlertModal] =
        useState(false);
    const [showCustomerIdDoesNotMatchPromptModal, setShowCustomerIdDoesNotMatchPromptModal] = useState(false);
    const [showMobileNumberCanOnlyBeUnsetAlertModal, setShowMobileNumberCanOnlyBeUnsetAlertModal] = useState(false);

    const loadPatients = async (id: string) => {
        const data = await getPatientsByClinicId(id);

        setPatients(data.items);
        setLoading(false);
    };

    useEffect(() => {
        loadPatients(clinicId);
    }, [clinicId]);

    const columns: Column<ISuperAdminsPatient>[] = [
        {
            ...(lastNameTableColumn(t, theme) as Column<ISuperAdminsPatient>),
            minWidth: 180,
            editable: 'onUpdate',
            field: 'lastName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            ...(firstNameTableColumn(t, theme) as Column<ISuperAdminsPatient>),
            minWidth: 180,
            editable: 'onUpdate',
            field: 'firstName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            cellStyle: {
                ...tableColumnCellStyle(theme),
            },
            minWidth: 140,
            render: (rowData: ISuperAdminsPatient) =>
                formatDate(rowData.birthdate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
            field: 'birthdate',
            title: t('superAdminPatients.dateOfBirth'),
            width: '5%',
            editable: 'onUpdate',
        },
        {
            ...(emailTableColumn(t, theme) as Column<ISuperAdminsPatient>),
            minWidth: 240,
            editable: 'onUpdate',
        },
        {
            cellStyle: {
                ...tableColumnCellStyle(theme),
            },
            editable: (columnDef: Column<SuperAdminsPatientRowData>, rowData: SuperAdminsPatientRowData) =>
                !!rowData.smsNumber,
            field: 'smsNumber',
            minWidth: 150,
            render: (rowData) => {
                const smsNumber = parsePhoneNumber(rowData.smsNumber ?? '');

                return smsNumber?.formatNational();
            },
            title: t('superAdminPatients.mobileNumber'),
            width: '5%',
        },
        {
            cellStyle: {
                ...tableColumnCellStyle(theme),
            },
            editable: 'onUpdate',
            field: 'deviceClass',
            lookup: {
                dash: t('PDMProduct.dash'),
                eros: t('PDMProduct.eros'),
                omnipod5: t('PDMProduct.omnipod5'),
            },
            minWidth: 180,
            render: (rowData) => {
                return deviceClass(t, rowData.deviceClass);
            },
            title: t('superAdminPatients.device'),
            width: '5%',
        },
        {
            ...(statusTableColumn(t, theme) as Column<ISuperAdminsPatient>),
            editable: 'onUpdate',
            minWidth: 105,
            width: '5%',
        },
        {
            cellStyle: {
                ...tableColumnCellStyle(theme),
            },
            editable: (columnDef: Column<SuperAdminsPatientRowData>, rowData: SuperAdminsPatientRowData) =>
                !rowData.customerId,
            field: 'customerId',
            minWidth: 140,
            title: t('superAdminPatients.customerId'),
        },
    ];

    const handleUpdatePatientByIdErrors = (
        errCode: string,
        forceSaveCustomerId: boolean,
        updatedPatient: ISuperAdminsPatient | null
    ) => {
        if (
            !forceSaveCustomerId &&
            errCode === UpdatePatientErrorCodes.PatientCustomerIdNotMatchingEnteredByAnotherSuperAdmin
        ) {
            setCustomerIdDifferentPatient(updatedPatient);
            setShowCustomerIdDoesNotMatchPromptModal(true);
        } else if (errCode === UpdatePatientErrorCodes.PatientCustomerIdAssignedToAnotherPatient) {
            setShowCustomerIdAssignedToAnotherPatientAlertModal(true);
        } else if (errCode === UpdatePatientErrorCodes.PatientSmsNumberCanOnlyBeUnset) {
            setShowMobileNumberCanOnlyBeUnsetAlertModal(true);
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const updatePatient = async (updatedPatient: ISuperAdminsPatient, forceSaveCustomerId: boolean) => {
        setLoading(true);

        const newUpdatedPatient = {
            birthdate: updatedPatient.birthdate,
            customerId: updatedPatient.customerId,
            deviceClass: updatedPatient.deviceClass,
            email: updatedPatient.email,
            isActive: updatedPatient.isActive,
            name: {
                first: updatedPatient.firstName,
                last: updatedPatient.lastName,
            },
            smsNumber: updatedPatient.smsNumber,
        };

        try {
            const savedUpdatedPatient = await updatePatientById(
                updatedPatient.id,
                forceSaveCustomerId,
                newUpdatedPatient
            );

            const newPatients = [...patients];
            const updatePatientIndex = patients.findIndex((p) => updatedPatient.id === p.id);

            if (savedUpdatedPatient) {
                newPatients[updatePatientIndex] = savedUpdatedPatient;
                setPatients(newPatients);
            }
        } catch (err: any) {
            handleUpdatePatientByIdErrors(err.response?.data?.error?.code, forceSaveCustomerId, updatedPatient);
        } finally {
            setLoading(false);
        }
    };

    const handleOnRowUpdate = async (
        patient: SuperAdminsPatientRowData,
        currentPatient?: SuperAdminsPatientRowData
    ) => {
        if (!currentPatient) {
            return;
        }

        const newPatient = {
            ...patient,
        };
        const newCurrentPatient = {
            ...currentPatient,
        };

        delete newPatient.tableData;
        delete newCurrentPatient.tableData;

        newPatient.isActive = newPatient.isActive.toString() === 'true';
        newPatient.smsNumber = isEmpty(newPatient.smsNumber) ? undefined : newPatient.smsNumber;
        newCurrentPatient.isActive = newCurrentPatient.isActive.toString() === 'true';

        const wasPatientUpdated = !isEqual(newPatient, newCurrentPatient);

        if (wasPatientUpdated) {
            await updatePatient(newPatient, false);
        }
    };

    const handleCustomerIdDifferentOnClose = () => {
        setShowCustomerIdDoesNotMatchPromptModal(false);
        setCustomerIdDifferentPatient(null);
    };

    const handleCustomerIdDifferentOnOkClick = async () => {
        if (!customerIdDifferentPatient) {
            return;
        }

        await updatePatient(customerIdDifferentPatient, true);

        setShowCustomerIdDoesNotMatchPromptModal(false);
        setCustomerIdDifferentPatient(null);
    };

    const handleCustomerIdAssignedToAnotherPatientOnClose = () => {
        setShowCustomerIdAssignedToAnotherPatientAlertModal(false);
    };

    const handlePhoneNumberWillNotBeSavedOnClose = () => {
        setShowMobileNumberCanOnlyBeUnsetAlertModal(false);
    };

    return (
        <>
            {loading && <Loading />}

            <div className={clsx(bemBlockName, { hidden: loading })} data-testid={bemBlockName}>
                <MaterialTable
                    columns={columns}
                    data={patients}
                    editable={{
                        onRowUpdate: handleOnRowUpdate,
                    }}
                />

                <PromptModal
                    className={`${bemBlockName}__customer-id-different-prompt-modal`}
                    description={`${t('common.patientName', {
                        last: customerIdDifferentPatient?.lastName,
                        first: customerIdDifferentPatient?.firstName,
                    })}, ${formatDate(
                        customerIdDifferentPatient?.birthdate,
                        SHORT_MONTH_DATE_FORMAT,
                        t('common.noValue')
                    )}`}
                    displayIcon={false}
                    isOpen={showCustomerIdDoesNotMatchPromptModal}
                    okButtonLabel={t('superAdminPatients.confirm')}
                    onClose={handleCustomerIdDifferentOnClose}
                    onOkClick={handleCustomerIdDifferentOnOkClick}
                    title={t('superAdminPatients.customerIdDoesNotMatch')}
                >
                    <div className={`${bemBlockName}__customer-id`}>{customerIdDifferentPatient?.customerId}</div>
                </PromptModal>

                <AlertModal
                    className={`${bemBlockName}__customer-id-already-exists-alert-modal`}
                    description={t('superAdminPatients.pleaseTryAgain')}
                    displayIcon={false}
                    isOpen={showCustomerIdAssignedToAnotherPatientAlertModal}
                    okButtonLabel={t('superAdminPatients.tryAgain')}
                    onClose={handleCustomerIdAssignedToAnotherPatientOnClose}
                    title={t('superAdminPatients.customerIdAlreadyExists')}
                />

                <AlertModal
                    className={`${bemBlockName}__phone-number-will-not-be-saved-alert-modal`}
                    description={t('superAdminPatients.pwdWillBePromptedToVerify')}
                    displayIcon={false}
                    isOpen={showMobileNumberCanOnlyBeUnsetAlertModal}
                    okButtonLabel={t('ok')}
                    onClose={handlePhoneNumberWillNotBeSavedOnClose}
                    title={t('superAdminPatients.phoneNumberWillNotBeSaved')}
                />
            </div>
        </>
    );
};

export default Patients;
