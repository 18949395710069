import { useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import clsx from 'clsx';

import { useOnClickOutside } from 'src/helpers/hooks/clickOutside';

export interface ISelectItem {
    text: string;
    value: string | number;
    isEnabled: boolean;
}

interface ISelectProps {
    items: ISelectItem[];
    inputClassName?: string;
    dropdownClassName?: string;
    variant?: 'default' | 'small';
    onSelectionChange: (item: ISelectItem, index: number) => void;
    initialSelection?: ISelectItem;
    dataTestId?: string;
}

// NOTE: Do not use this component anymore. MUI Select should be used instead
const Select = ({
    inputClassName,
    dropdownClassName,
    items,
    variant,
    onSelectionChange,
    initialSelection,
    dataTestId,
}: ISelectProps) => {
    const [selectedItem, setSelectedItem] = useState<ISelectItem>();
    const [isOpen, setOpen] = useState(false);

    const wrapperRef = useRef(null);

    useEffect(() => {
        setSelectedItem(initialSelection);
    }, [initialSelection]);

    useEffect(() => {
        const currentSelectedItem = items.find((item) => item.text === selectedItem?.text);
        if (!selectedItem || !currentSelectedItem?.isEnabled) {
            const firstEnabledItem = items.find((item) => item.isEnabled);
            setSelectedItem(firstEnabledItem || items[0]);
        }
    }, [items, selectedItem]);

    const clickOutside = () => {
        setOpen(false);
    };

    useOnClickOutside(wrapperRef, clickOutside);

    const handleSelect = (option: ISelectItem, index: number) => {
        if (!option.isEnabled) return;
        setSelectedItem(option);
        onSelectionChange(option, index);
        setOpen(false);
    };

    const handleChange = () => {
        setOpen(!isOpen);
    };

    return (
        <div className={`select-wrapper ${variant}`} ref={wrapperRef}>
            <div
                className={`select ${inputClassName}`}
                onClick={handleChange}
                data-testid={dataTestId}
                aria-hidden="true"
            >
                {selectedItem?.text ?? ''}
                <div className="select__icon">
                    <KeyboardArrowDownIcon fontSize="large" />
                </div>
            </div>
            <div
                className={clsx(`select__dropdown ${dropdownClassName}`, {
                    open: isOpen,
                })}
            >
                {items.map((item, i) => (
                    <div
                        key={uuidv4()}
                        className={clsx('select__list-item', {
                            active: items[i] === selectedItem && item.isEnabled,
                            disabled: !item.isEnabled,
                        })}
                        onClick={() => handleSelect(item, i)}
                        data-testid={`option-${i}`}
                        aria-hidden="true"
                    >
                        {item.text}
                    </div>
                ))}
            </div>
        </div>
    );
};

Select.defaultProps = {
    inputClassName: '',
    dropdownClassName: '',
    variant: 'default',
    initialSelection: null,
    dataTestId: 'select',
};

export default Select;
