import { useTranslation } from 'react-i18next';

import noPatientsImage from '../../assets/images/webImages/noProviders.png';

const NoPatientsMessage = () => {
    const { t } = useTranslation();

    return (
        <div className="tableEmptyContent">
            <img src={noPatientsImage} alt={t('common.altNoPatients')} />
            <h2 className="tableEmptyContent__title">{t('patientDirectory.emptyTitle')}</h2>
            <p className="tableEmptyContent__descr">{t('patientDirectory.emptyDescr')}</p>
        </div>
    );
};
export default NoPatientsMessage;
