import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';

import { IInvitePatient, IMovePatientToClinic, IUpsertPatientInvitation } from '../models/app.models';

export async function findPatientByEmailData(email: string) {
    const res = await axios.get(createApiPath(`/clinics/patients/findByEmail/${encodeURIComponent(email)}`));

    return res?.data;
}

export async function getPatientsData() {
    const res = await axios.get(createApiPath('patients'), {});

    return res?.data;
}

export async function getPatientDataConfig(patientId: string) {
    const res = await axios.get(createApiPath(`patients/${patientId}`));

    return res?.data;
}
export async function getPatientInvitationData(patientId: string) {
    const res = await axios.get(createApiPath(`patients/invitations/${patientId}`), {});

    return res?.data;
}

export async function invitePatientData(data: IInvitePatient) {
    const invitePatientAxiosInstance = axios.create();

    const res = await invitePatientAxiosInstance.post(createApiPath('patients'), data);

    return res?.data;
}

export async function createPatientInvitationData(data: IUpsertPatientInvitation) {
    const patientInvitationAxiosInstance = axios.create();
    const res = await patientInvitationAxiosInstance.post(createApiPath('patients/invitations'), data, {});

    return res?.data;
}

export async function movePatientData(patientId: string, data: IMovePatientToClinic) {
    const res = await axios.put(createApiPath(`patients/${patientId}/clinic`), data);

    return res?.data;
}

export async function reinvitePatientData(data: IInvitePatient, patientId: string) {
    const invitePatientAxiosInstance = axios.create();

    const res = await invitePatientAxiosInstance.put(createApiPath(`patients/${patientId}/reinvite`), data);

    return res?.data;
}

export async function getPatientsDeviceStatisticsData(ids: string) {
    const res = await axios.get(createApiPath('patients/device-statistics', [{ key: 'patientIds', value: ids }]), {});
    return res?.data;
}

export async function updatePatientInvitationData(data: IUpsertPatientInvitation, patientId: string) {
    const patientInvitationAxiosInstance = axios.create();

    const res = await patientInvitationAxiosInstance.put(createApiPath(`patients/invitations/${patientId}`), data, {});

    return res?.data;
}

export async function removePatientInvitationData(patientId: string) {
    await axios.delete(createApiPath(`patients/invitations/${patientId}`), {});
}
