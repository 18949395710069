import React, { useState, useRef } from 'react';
import { ButtonGroup as MUIButtonGroup } from '@mui/material';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import MenuList from '@mui/material/MenuList';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';

import Button from '../button/Button';

const bemBlockName = 'split-button';

interface ISplitButtonProps {
    disabled: boolean;
    text: string;
    menuItems: MenuItemProps[];
    onClick: () => void;
}

const SplitButton = ({ disabled, text, menuItems, onClick }: ISplitButtonProps) => {
    const anchorRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);

    const handleIsOpenChange = () => {
        setIsOpen(!isOpen);
    };

    const handleOnClickAway = () => {
        if (isOpen) {
            setIsOpen(false);
        }
    };

    const menuItemOnClickHandler = (
        menuItemOnClick: ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) | undefined
    ): ((event: React.MouseEvent<HTMLLIElement, MouseEvent>) => void) => {
        return (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
            setIsOpen(false);

            if (menuItemOnClick) {
                menuItemOnClick(event);
            }
        };
    };

    return (
        <div className={bemBlockName} aria-hidden="true" data-testid="split-button">
            <MUIButtonGroup variant="contained" ref={anchorRef} disableElevation>
                <Button
                    className={clsx('btn', `${bemBlockName}__main-button`)}
                    disabled={disabled}
                    onClick={onClick}
                    text={text}
                />
                <Button
                    className={clsx('btn', `${bemBlockName}__dropdown-button`)}
                    disabled={disabled}
                    dataTestId="dropdown-button"
                    onClick={handleIsOpenChange}
                    startIcon={<KeyboardArrowDownOutlinedIcon />}
                />
            </MUIButtonGroup>
            <Popper
                open={isOpen}
                anchorEl={anchorRef.current}
                transition
                disablePortal
                data-testid="split-button-popper"
            >
                {({ TransitionProps }) => (
                    <Grow
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...TransitionProps}
                        className={`${bemBlockName}__grow_center-top`}
                    >
                        <Paper elevation={6}>
                            <ClickAwayListener onClickAway={handleOnClickAway}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {menuItems.map((item) => (
                                        <MenuItem
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...item}
                                            onClick={menuItemOnClickHandler(item.onClick)}
                                            key={uuidv4()}
                                        />
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
};

export default SplitButton;
