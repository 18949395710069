import { useSelector } from 'react-redux';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { RootStateType } from '../../stores/store';
import TruncateText from '../truncateText/TruncateText';

const maximumClinicNameWidth = 850;

const ClinicName = () => {
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const clinicName = currentUser?.clinic?.name;

    if (!clinicName) {
        return null;
    }

    return (
        <>
            <CorporateFareIcon color="primary" />

            <TruncateText text={clinicName} maximumTextWidth={maximumClinicNameWidth} />
        </>
    );
};

export default ClinicName;
