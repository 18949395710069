import { getPendingPatientsAndClinicalWorkersCountsData } from '../../web-services/Admin.service';

export async function getPendingPatientsAndClinicalWorkersCounts() {
    const res = await getPendingPatientsAndClinicalWorkersCountsData();

    return {
        pendingPatientsCount: res?.data?.item?.pendingPatientsCount,
        pendingClinicalWorkersCount: res?.data?.item?.pendingClinicalWorkersCount,
    };
}
