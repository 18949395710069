import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { ClinicalWorkerRole } from '../../models/app.models';

function isValidClinicalWorkerRole(value: string): value is ClinicalWorkerRole {
    return Object.values<string>(ClinicalWorkerRole).includes(value);
}

const defaultProps = {
    disabled: false,
};

interface ClinicalWorkerPermissionsSelectProps {
    value: ClinicalWorkerRole;
    disabled?: boolean;
    onPermissionsChange: (permissions: ClinicalWorkerRole) => void;
}

const ClinicalWorkerPermissionsSelect = ({
    value,
    disabled,
    onPermissionsChange,
}: ClinicalWorkerPermissionsSelectProps) => {
    const { t } = useTranslation();

    const handleChange: (event: SelectChangeEvent) => void = (event) => {
        const role: string = event.target.value;

        if (isValidClinicalWorkerRole(role)) {
            onPermissionsChange(role);
        }
    };

    return (
        <FormControl>
            <Select
                className={clsx('Select', 'clinical-worker-permissions-select')}
                disableUnderline
                value={value}
                variant="standard"
                disabled={disabled}
                onChange={handleChange}
                MenuProps={{
                    classes: {
                        root: 'SelectPopover',
                    },
                }}
            >
                <MenuItem value={ClinicalWorkerRole.ClinicAdmin}>{t('clinicalWorkerPermissions.admin')}</MenuItem>

                <MenuItem value={ClinicalWorkerRole.Provider}>{t('clinicalWorkerPermissions.standard')}</MenuItem>
            </Select>
        </FormControl>
    );
};

ClinicalWorkerPermissionsSelect.defaultProps = defaultProps;

export default ClinicalWorkerPermissionsSelect;
