import { IUser } from 'src/models/app.models';

export function getCurrentUserInitials(currentUser?: IUser | null): string {
    const firistName = currentUser?.name?.first;
    const lastName = currentUser?.name?.last;

    if (firistName && lastName) {
        return firistName.substring(0, 1) + lastName.substring(0, 1);
    }
    if (lastName) {
        return lastName.substring(0, 1);
    }
    if (firistName) {
        return firistName.substring(0, 1);
    }
    return '';
}
