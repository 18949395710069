import { ISummaryChartSettings } from 'src/models/summaryChart.model';

import { changeTimeInNumber } from './changeTimeInNumber';
import { combineEqualValues } from './combineEqualValues';

import { IInsulinToCarbRatioSettings } from '../models/report.models';

export function createInsulinToCarbRatioStructure(insulinToCarbRatio: IInsulinToCarbRatioSettings[]) {
    const data: ISummaryChartSettings[] = [];
    combineEqualValues(insulinToCarbRatio, (key) => key.ratio).forEach((item) => {
        data.push({
            x: changeTimeInNumber(item.startTime),
            duration: changeTimeInNumber(item.endTime, true) - changeTimeInNumber(item.startTime),
            value: `${item.ratio}`,
        });
    });
    return data;
}

export function createEmptyValue() {
    return [
        {
            x: 0,
            duration: 24,
            value: '',
        },
    ];
}
