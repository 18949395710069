import { urls } from 'src/models/consts/urls';

import createPath from '../createPath';
import createUrl from '../createURL';
import { getUserTimezone } from '../getUserTimezone';
import { getRuntimeConfig } from '../envReader';
import { variables } from '../../models/consts/variables';
import { reportFileNameElementId } from '../../pages/PatientDetails';

export enum AccessPdfAction {
    Print = 'print',
    View = 'view',
    Download = 'download',
}

export default function createPdfUrl(id: string, countDays: number, action: AccessPdfAction, token?: string) {
    const webUrl = String(window.location.origin + getRuntimeConfig(variables.PUBLIC_URL));
    const timeZone: string = getUserTimezone();
    // const webUrl = 'https://dev.hcp.discover.gibraltaraz.com/'; // test for localhost
    // createUrl(url.pdf) = 'https://app-wus-be-pdf-hcp-gibraltar-d.azurewebsites.net/'; // test for localhost

    const encodeUrlOnReport = encodeURIComponent(
        `/report?patients=${id}&days=${countDays}&displayOnPrintOnly=true&timeZone=${timeZone}`
    );
    const base64url = Buffer.from(`${webUrl}?path=${encodeUrlOnReport}`).toString('base64');
    let path = '';

    switch (action) {
        case AccessPdfAction.Print: {
            path = createPath(createUrl(urls.PDF), 'generate', [
                { key: 'url', value: base64url },
                { key: 'token', value: token || '' },
                { key: 'loadingIndicatorSelector', value: '.loading-content' },
                { key: 'openInNewWindowWithPrintDialog', value: 'true' },
                { key: 'newWindowName', value: '' },
                { key: 'newWindowFeatures', value: 'height=700,width=750' },
                { key: 'fileNameSelector', value: `#${reportFileNameElementId}` },
            ]);
            break;
        }
        case AccessPdfAction.View: {
            path = createPath(createUrl(urls.PDF), 'generate', [
                { key: 'url', value: base64url },
                { key: 'token', value: token || '' },
                { key: 'loadingIndicatorSelector', value: '.loading-content' },
                { key: 'fileNameSelector', value: `#${reportFileNameElementId}` },
            ]);
            break;
        }
        case AccessPdfAction.Download: {
            path = createPath(createUrl(urls.PDF), 'generate', [
                { key: 'url', value: base64url },
                { key: 'token', value: token || '' },
                { key: 'loadingIndicatorSelector', value: '.loading-content' },
                { key: 'download', value: 'true' },
                { key: 'fileNameSelector', value: `#${reportFileNameElementId}` },
            ]);
            break;
        }
        default: {
            break;
        }
    }

    return path;
}
