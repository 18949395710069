import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import { IClinicalWorkerInviteFormValues } from './clinicalWorkerInviteFormValues';

import { getInvalidEmailError } from '../../helpers/formFieldValidation';

export interface IClinicalWorkerInviteFormEmailValue {
    clinicalWorkersEmail: string;
}

interface IClinicalWorkersEmailControllerProps {
    control: Control<IClinicalWorkerInviteFormValues>;
    isSubmitted: boolean;
}

const clinicalWorkersEmailDefaultValue = '';
const clinicalWorkersEmailMaxLength = 80;
const clinicalWorkersEmailName = 'clinicalWorkersEmail';

const ClinicalWorkersEmailController = ({ control, isSubmitted }: IClinicalWorkersEmailControllerProps) => {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            defaultValue={clinicalWorkersEmailDefaultValue}
            name={clinicalWorkersEmailName}
            render={({ field, fieldState, formState }) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="TextField"
                    error={!!formState.errors.clinicalWorkersEmail}
                    fullWidth
                    helperText={fieldState.error?.message}
                    inputProps={{
                        'data-testid': clinicalWorkersEmailName,
                        maxLength: clinicalWorkersEmailMaxLength,
                    }}
                    label={t('clinicalWorkerInvite.emailLabel')}
                    variant="outlined"
                />
            )}
            rules={{
                required: t<string>('clinicalWorkerInvite.requiredEmailError'),
                validate: (value) =>
                    getInvalidEmailError(value, t('clinicalWorkerInvite.invalidEmailError'), isSubmitted, false),
            }}
        />
    );
};

export default ClinicalWorkersEmailController;
