import { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';

function LoginInsuletIDCallback(): null {
    const { oktaAuth } = useOktaAuth();
    useEffect(() => {
        oktaAuth.signInWithRedirect({ originalUri: '/patients', prompt: 'none' }).catch(() => {
            window.location.assign('/patients');
        });
        // eslint-disable-next-line
    }, []);
    return null;
}

export default LoginInsuletIDCallback;
