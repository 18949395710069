import { OktaAuthOptions } from '@okta/okta-auth-js';

import { getRuntimeConfig } from './envReader';
import { isPauseItEnabled } from './featureToggles';

import { variables } from '../models/consts/variables';

const oktaConfig = () => {
    const config: OktaAuthOptions = {
        clientId: getRuntimeConfig(variables.OKTA_CLIENT_ID),
        issuer: getRuntimeConfig(variables.OKTA_ISSUER),
        redirectUri: `${window.location.origin}${getRuntimeConfig(variables.PUBLIC_URL)}/login/callback`,
    };

    if (isPauseItEnabled()) {
        return {
            ...config,
            pkce: true,
            scopes: ['openid', 'profile', 'email'],
        };
    }

    return {
        ...config,
        tokenManager: {
            autoRenew: false,
        },
    };
};

export default oktaConfig;
