import { urls } from 'src/models/consts/urls';
import { ISearchParam } from 'src/models/searchParam';

import createUrl from './createURL';

export default function createPath(mainUrl: string, base: string, searchParams?: ISearchParam[], hash?: string) {
    const newUrl = new URL(base, mainUrl);

    if (searchParams && searchParams.length) {
        searchParams.forEach((p) => {
            newUrl.searchParams.append(p.key, p.value);
        });
    }

    if (hash) newUrl.hash = hash;

    return newUrl.href;
}

export function createApiPath(base: string, searchParams?: ISearchParam[]) {
    return createPath(createUrl(urls.API), base, searchParams);
}

export function createPwdPublicPagesPath(base: string, searchParams?: ISearchParam[]) {
    return createPath(createUrl(urls.PWD_PUBLIC_PAGES), base, searchParams);
}

export function createHcpPublicPagesPath(base: string, searchParams?: ISearchParam[]) {
    return createPath(createUrl(urls.HCP_PUBLIC_PAGES), base, searchParams);
}
