import clsx from 'clsx';
import { Trans, useTranslation } from 'react-i18next';

import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';

import userNotAssignedToAppImage from '../../assets/images/webImages/recall.png';

const bemBlockName = 'user-not-assigned-to-application-error';

function UserNotAssignedToApplicationError() {
    const { t } = useTranslation();

    const patientUrl = getRuntimeConfig(variables.PWD_APP_URL);
    const providerUrl = getRuntimeConfig(variables.HCP_APP_URL);

    return (
        <div className={bemBlockName}>
            <div className={`${bemBlockName}__image`}>
                <img src={userNotAssignedToAppImage} alt={t('notFound.alt')} />
            </div>
            <div className={`${bemBlockName}__title`}>{t('userNotAssignedToApplicationError.title')}</div>
            <div className={`${bemBlockName}__description`}>
                <Trans
                    t={t}
                    i18nKey="userNotAssignedToApplicationError.description"
                    values={{
                        userType: t('userNotAssignedToApplicationError.patient'),
                        appUrl: patientUrl,
                    }}
                    components={{
                        url: (
                            <div
                                className={clsx(`${bemBlockName}__description`, `${bemBlockName}__link`)}
                                data-testid="pwd-url"
                            />
                        ),
                    }}
                />
            </div>
            <div className={`${bemBlockName}__description`}>
                <Trans
                    t={t}
                    i18nKey="userNotAssignedToApplicationError.description"
                    values={{
                        userType: t('userNotAssignedToApplicationError.healthCareProvider'),
                        appUrl: providerUrl,
                    }}
                    components={{
                        url: (
                            <div
                                className={clsx(`${bemBlockName}__description`, `${bemBlockName}__link`)}
                                data-testid="hcp-url"
                            />
                        ),
                    }}
                />
            </div>
        </div>
    );
}

export default UserNotAssignedToApplicationError;
