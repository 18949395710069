import MuiAvatar from '@mui/material/Avatar';

interface IAvatarProps {
    text: string;
}

const Avatar = ({ text }: IAvatarProps) => {
    return <MuiAvatar className="Avatar">{text}</MuiAvatar>;
};

export default Avatar;
