import { useTranslation } from 'react-i18next';
import { SvgIcon, Tooltip } from '@mui/material';
import clsx from 'clsx';

import { TOOLTIP_ENTER_DELAY } from 'src/models/consts/tooltip';
import { ReactComponent as WarningIcon } from 'src/assets/images/warningIcon.svg';

import { bemBlockName } from './Sidebar';

const defaultProps = {
    error: false,
};

interface IPendingUsersCountProps {
    count: number | null;
    error?: boolean;
}

const PendingUsersCount = ({ count, error }: IPendingUsersCountProps) => {
    const { t } = useTranslation();

    if (error) {
        return (
            <Tooltip
                arrow
                className="Tooltip Tooltip_single-line"
                enterDelay={TOOLTIP_ENTER_DELAY}
                PopperProps={{ disablePortal: true }}
                title={t('sidebar.pendingUsersError')}
            >
                <span
                    className={clsx(
                        `${bemBlockName}__pending-users-count`,
                        `${bemBlockName}__pending-users-count_error`
                    )}
                    data-testid={`${bemBlockName}__pending-users-count-error`}
                >
                    <SvgIcon component={WarningIcon} />
                </span>
            </Tooltip>
        );
    }

    if (count == null || count < 1) {
        return null;
    }

    return (
        <span className={`${bemBlockName}__pending-users-count`} data-testid={`${bemBlockName}__pending-users-count`}>
            {t('sidebar.pendingUsersCount', { count })}
        </span>
    );
};

PendingUsersCount.defaultProps = defaultProps;

export default PendingUsersCount;
