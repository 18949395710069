import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import MaterialTableCore, {
    Column,
    MaterialTableProps as MaterialTableCoreProps,
    MTableBodyRow,
    MTableCell,
    MTableEditField,
} from '@material-table/core';
import React from 'react';
import Radio from '@mui/material/Radio';

interface IMaterialTableProps {
    singleRowSelection?: boolean;
    selectedRowId?: string | null;
    onSingleRowSelected?: (id: string) => void;
}

function MaterialTable<T extends Record<string, any>>({
    singleRowSelection,
    selectedRowId,
    onSingleRowSelected,
    ...otherProps
}: MaterialTableCoreProps<T> & IMaterialTableProps) {
    return (
        <MaterialTableCore
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
            columns={[
                /*
                    TODO Instead of defining disabled, checked, onChange in this component, expecting rowData 
                    to have id and isActive properties; set them by using values in selectionProps property, 
                    have parent components define disabled, checked, onChange through selectionProps.
                */
                ...(singleRowSelection && onSingleRowSelected
                    ? [
                          {
                              cellStyle: {
                                  minWidth: 20,
                                  padding: 7,
                                  paddingRight: 0,
                                  paddingLeft: 0,
                              },
                              width: '5%',
                              render: (rowData) => (
                                  <Radio
                                      disabled={!rowData.isActive}
                                      checked={rowData.id === selectedRowId}
                                      onChange={() => {
                                          if (rowData?.isActive) {
                                              onSingleRowSelected(rowData.id);
                                          }
                                      }}
                                  />
                              ),
                          } as Column<T>,
                      ]
                    : []),
                ...otherProps.columns,
            ]}
            components={{
                // eslint-disable-next-line react/jsx-props-no-spreading
                Cell: (props) => <MTableCell {...props} className="TableCell" size="medium" />,
                Container: ({ children }: { children: React.ReactNode }) => (
                    <div className="material-table">{children}</div>
                ),
                EditField: (props) => {
                    const { columnDef } = props;

                    return (
                        <MTableEditField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...props}
                            className="TableEditField"
                            data-testid={columnDef.field}
                            variant="standard"
                        />
                    );
                },
                // eslint-disable-next-line react/jsx-props-no-spreading
                Row: (props) => <MTableBodyRow {...props} className="TableRow" />,
                ...otherProps.components,
            }}
            icons={{
                Edit: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
                    <EditIcon sx={{ fontSize: 18 }} ref={ref} />
                )),
                Add: React.forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
                    <AddBoxIcon sx={{ fontSize: 18 }} ref={ref} />
                )),
                ...otherProps.icons,
            }}
            localization={{
                header: {
                    actions: null,
                },
                ...otherProps.localization,
            }}
            options={{
                actionsColumnIndex: otherProps.columns.length + (singleRowSelection ? 1 : 0),
                draggable: false,
                maxColumnSort: 0,
                paging: false,
                showTitle: false,
                overflowY: 'visible',
                search: false,
                toolbar: false,
                toolbarButtonAlignment: 'right',
                ...otherProps.options,
            }}
        />
    );
}

MaterialTable.defaultProps = {
    singleRowSelection: false,
    selectedRowId: undefined,
    onSingleRowSelected: undefined,
};

export default MaterialTable;
