import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { memo } from 'react';

import ClinicName from './ClinicName';
import UserOptions from './UserOptions';

import { ReactComponent as OmnipodDiscover } from '../../assets/images/Discover-Logo-Vertical.svg';
import { bemBlockName } from '../../pages/Home';

const HomeHeader = memo(function HomeHeader() {
    const dividerClassName = `${bemBlockName}__header-divider`;

    return (
        <header className={`${bemBlockName}__header`}>
            <Grid alignItems="center" columnSpacing={3} container rowSpacing={0} wrap="nowrap">
                <Grid className={`${bemBlockName}__logo-column`} item>
                    <OmnipodDiscover />
                </Grid>

                <Divider className={dividerClassName} flexItem orientation="vertical" />

                <Grid className={`${bemBlockName}__clinic-name-column`} item container xs>
                    <ClinicName />
                </Grid>

                <Divider className={dividerClassName} flexItem orientation="vertical" />

                <Grid className={`${bemBlockName}__user-options-column`} item xs="auto">
                    <UserOptions />
                </Grid>
            </Grid>
        </header>
    );
});

export default HomeHeader;
