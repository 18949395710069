import { ReactChild } from 'react';

interface IModalProps {
    children: ReactChild;
    className?: string;
}

export const bemBlockName = 'modal';

const Modal = ({ children, className }: IModalProps) => {
    return (
        <div className={`${bemBlockName}-wrapper`}>
            <div className={`${bemBlockName} ${className}`}>{children}</div>
        </div>
    );
};

Modal.defaultProps = {
    className: '',
};

export default Modal;
