import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Checkbox from '@mui/material/Checkbox';
import clsx from 'clsx';

import { acceptEULAAgreement } from 'src/helpers/services/eulaAgreement';
import Modal from 'src/components/modal/Modal';
import Button from 'src/components/button/Button';
import { RootStateType } from 'src/stores/store';
import { AgreementStatus } from 'src/models/app.models';
import { agreements } from 'src/models/consts/agreements';
import { createPwdPublicPagesPath } from 'src/helpers/createPath';
import { analyticsEventNames } from 'src/models/consts/analytics';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { setAgreementStatus, setCurrentUser, setRole } from 'src/stores/appStore/appActions';
import { getCurrentUser } from 'src/helpers/services/CurrentUser';
import { getActiveRole } from 'src/helpers/getActiveRole';

interface IAgreements {
    children: React.ReactNode | React.ReactNode[];
}

const Agreements = ({ children }: IAgreements) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [agree, setAgree] = useState(false);
    const agreementStatus = useSelector((state: RootStateType) => state.appState.agreementStatus);
    const clinicName = useSelector((state: RootStateType) => state.appState.currentUser?.clinic?.name);
    const userType = useSelector((state: RootStateType) => state.appState.currentUser?.role);

    const handleContinue = () => {
        (async () => {
            sendAzureEvent(analyticsEventNames.CLICK_CONTINUE_AGREE_TO_EULA, { userType, clinicName });

            try {
                await acceptEULAAgreement(agreementStatus);

                const user = await getCurrentUser();
                const newActiveRole = getActiveRole(user);

                dispatch(setAgreementStatus(AgreementStatus.CONFIRMED));
                dispatch(setCurrentUser(user));
                dispatch(setRole(newActiveRole));

                setAgree(false);
            } catch {
                setAgree(false);
            }
        })();
    };

    const handleAgree = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setAgree(checked);
        sendAzureEvent(analyticsEventNames.CHANGE_AGREE_TO_EULA, {
            userType,
            clinicName,
            checked: checked.toString(),
        });
    };

    useEffect(() => {
        if (agreementStatus === AgreementStatus.EULA) {
            sendAzureEvent(analyticsEventNames.VIEW_AGREE_TO_EULA, {
                userType,
                clinicName,
            });
        }
    }, [userType, clinicName, agreementStatus]);

    if (agreementStatus !== AgreementStatus.EULA) return <>{children}</>;

    return (
        <div className="agreement-wrapper">
            <Modal className="agreement">
                <>
                    <div className="modal__title">{t(agreements.eula?.title)}</div>
                    <div className="modal__descr-wrapper agreement__description">
                        <iframe
                            className="agreement__iframe"
                            sandbox="allow-scripts"
                            src={createPwdPublicPagesPath(agreements.eula?.source, agreements.eula?.searchParams)}
                            title={t(agreements.eula?.title)}
                        />
                        <div className="agreement__gradient" />
                    </div>
                    <div className="agreement__action">
                        <div className="agreement__check-box">
                            <Checkbox
                                className="agreement__check-box-action"
                                data-testid="agreement__check-box-action"
                                color="primary"
                                onChange={handleAgree}
                                checked={agree}
                            />
                            <p>{t(agreements.eula?.checkBox)}</p>
                        </div>

                        <Button
                            text={agreements.eula?.button}
                            onClick={handleContinue}
                            className={clsx('btn agreement__action-button', agree || 'btn_disabled')}
                        />
                    </div>
                </>
            </Modal>
        </div>
    );
};

export default Agreements;
