interface ICheckBoxProps {
    activeId?: string;
    id: string;
    value?: string;
    className?: string;
    label?: string;
    onChange: (id: string) => void;
    dataTestId?: string;
}

const CheckBox = ({ activeId, id, value, className, label, dataTestId, onChange }: ICheckBoxProps) => {
    const handleChange = (radioId: string) => {
        onChange(radioId);
    };

    return (
        <div className={`checkbox ${className}`}>
            <input id={id} type="checkbox" value={value} onChange={() => handleChange(id)} data-testid={dataTestId} />
            <label htmlFor={id} className={activeId === id ? 'active' : ''}>
                {label}
            </label>
        </div>
    );
};

CheckBox.defaultProps = {
    activeId: '',
    value: '',
    className: '',
    label: '',
    dataTestId: '',
};

export default CheckBox;
