import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { SecureRoute, useOktaAuth } from '@okta/okta-react';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import Home from 'src/pages/Home';
import { parseOktaData } from 'src/helpers/parseOktaData';
import { saveOktaData } from 'src/stores/appStore/appActions';
import { isPwdUserSession } from 'src/helpers/isPwdUserSession';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';

const Landing = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const redirectToPwd = () => {
        const pwdAppUrl = getRuntimeConfig(variables.PWD_APP_URL);

        if (pwdAppUrl) {
            window.location.replace(pwdAppUrl);
        }
    };

    useEffect(() => {
        const { search } = window.location;
        const path = new URLSearchParams(search).get('path') ?? '';
        if (path) {
            const reportUrl = decodeURIComponent(path);
            history.push(reportUrl);
        }
    }, [history]);

    const authState = useOktaAuth()?.authState;

    useEffect(() => {
        if (authState?.accessToken) {
            if (isPwdUserSession(authState.accessToken)) {
                redirectToPwd();
            }

            const oktaDataConfig = parseOktaData(authState);

            dispatch(saveOktaData(oktaDataConfig));
            axios.defaults.headers.common.Authorization = oktaDataConfig.access;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authState]);

    return <SecureRoute path="/" component={Home} />;
};

export default Landing;
