import { ClinicalWorkerRole, ClinicalWorkerStatus } from '../models/app.models';

interface IClinicalWorker {
    id: string;
    name?: { first?: string; last?: string };
    nationalProviderIdentifier?: string;
    credentials: string;
    email?: string;
    role: string;
    status: string;
}

interface IClinicalWorkerInvitation {
    id: string;
    name: { first: string; last: string };
    email: string;
}

function getClinicalWorkerValue(role: string): ClinicalWorkerRole {
    switch (role) {
        case ClinicalWorkerRole.ClinicAdmin:
            return ClinicalWorkerRole.ClinicAdmin;
        case ClinicalWorkerRole.Provider:
            return ClinicalWorkerRole.Provider;
        default:
            throw new Error('Unexpected clinical worker role.');
    }
}

function getClinicalWorkerStatusValue(status: string): ClinicalWorkerStatus {
    switch (status) {
        case ClinicalWorkerStatus.Active:
            return ClinicalWorkerStatus.Active;
        case ClinicalWorkerStatus.Inactive:
            return ClinicalWorkerStatus.Inactive;
        case ClinicalWorkerStatus.Invited:
            return ClinicalWorkerStatus.Invited;
        default:
            throw new Error('Unexpected clinical worker status.');
    }
}

export function createClinicalWorkerStructure(item: IClinicalWorker) {
    return {
        id: item.id,
        firstName: item.name?.first ?? '',
        lastName: item.name?.last ?? '',
        credentials: item.credentials ?? '',
        npi: item.nationalProviderIdentifier ? String(item.nationalProviderIdentifier) : '',
        email: item.email ?? '',
        role: getClinicalWorkerValue(item.role),
        status: getClinicalWorkerStatusValue(item.status),
    };
}

export function createClinicalWorkersStructure(items: IClinicalWorker[]) {
    return items.map(createClinicalWorkerStructure);
}

export function createClinicalWorkerInvitationStructure(item: IClinicalWorkerInvitation) {
    return {
        id: item.id,
        firstName: item.name?.first ?? '',
        lastName: item.name?.last ?? '',
        email: item.email ?? '',
    };
}
