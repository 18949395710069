import { Resource, ResourceAction, IUser } from '../../models/app.models';
import {
    performResourceAction,
    getCurrentClinicalWorkerData,
    getCurrentUserData,
} from '../../web-services/CurrentUser.service';
import { isPauseItEnabled } from '../featureToggles';

export function didUserPerformResourceAction(user: IUser, resource: Resource, action: ResourceAction) {
    const actions = user.resources.find(({ name }) => name === resource)?.actions;

    return actions?.includes(action) ?? false;
}

export async function getCurrentUser() {
    const res = isPauseItEnabled() ? await getCurrentUserData() : await getCurrentClinicalWorkerData();

    const { item } = res?.data || { item: null };

    return item;
}

export async function userPerformResourceAction(resource: Resource, action: ResourceAction) {
    await performResourceAction(resource, action);
    const user = await getCurrentUser();

    if (!user?.id) {
        throw Error();
    }

    return user;
}
