import { Location } from 'history';

import { RoutePath, ErrorPages } from '../models/table.models';
import { CurrentUserRole } from '../models/app.models';

export const urlIncludePage = (url: string, pagesEnum: { [s: number]: string }) =>
    Object.values(pagesEnum).includes(url);

// TODO: clean up. Ideally want to have some data structure that defines route access depending on role, etc.
export const isAllUsersPath = (url: string) => {
    return urlIncludePage(url, [RoutePath.help, RoutePath.about]);
};
export const isErrorPage = (url: string) => urlIncludePage(url, ErrorPages);
export const isUploaderPath = (url: string) =>
    urlIncludePage(url, [RoutePath.pdmUploader, RoutePath.pdmUploaderDateChange]);

export const getPostAuthenticationRedirectUrl = (location: Location, currentUserRole: CurrentUserRole) => {
    const { pathname: path } = location;

    if (isAllUsersPath(path) || isUploaderPath(path)) {
        return location;
    }

    if (currentUserRole === CurrentUserRole.ClinicAdmin && !path?.includes('/admin')) {
        return RoutePath.adminPatients;
    }

    if (
        currentUserRole === CurrentUserRole.Physician &&
        (path?.includes('/admin') || path?.includes('/super-admin') || path === '/' || !path)
    ) {
        return RoutePath.patients;
    }

    if (currentUserRole === CurrentUserRole.SuperAdmin && !path?.includes('/super-admin')) {
        return RoutePath.superAdmins;
    }

    return location;
};
