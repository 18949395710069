import { useTranslation } from 'react-i18next';

interface IPatientInfoProps {
    firstName: string;
    lastName: string;
    dob: string;
}

const PatientInfo = ({ firstName, lastName, dob }: IPatientInfoProps) => {
    const { t } = useTranslation();

    return (
        <div className="patient-info">
            <p className="patient-info__title">{t('pdmUploader.patientInfoTitle')}</p>
            <p className="patient-info__name">{t('common.patientName', { last: lastName, first: firstName })}</p>
            <p className="patient-info__dob">{t('pdmUploader.patientDateOfBirthInfo', { date: dob })}</p>
        </div>
    );
};

export default PatientInfo;
