import TextField, { OutlinedTextFieldProps } from '@mui/material/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React from 'react';
import clsx from 'clsx';

const TextFieldSelect = React.forwardRef(function TextFieldSelect(
    props: Omit<OutlinedTextFieldProps, 'variant'>,
    ref: React.Ref<any>
) {
    return (
        <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            className={clsx('TextField', props.className)}
            ref={ref}
            SelectProps={{
                IconComponent: KeyboardArrowDownIcon,
                MenuProps: {
                    classes: {
                        paper: 'Select',
                    },
                },
            }}
            select
            variant="outlined"
        >
            {props.children}
        </TextField>
    );
});

export default TextFieldSelect;
