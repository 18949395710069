export function changeTimeInNumber(stringTime: string, endTime?: boolean) {
    let hours = parseInt(stringTime.split(':')[0], 10);
    const minutes = parseInt(stringTime.split(':')[1], 10);
    const correctMinutes = (minutes / 60) * 100;
    if (endTime && hours === 0 && minutes === 0) {
        hours = 24;
    }
    const time = (hours + correctMinutes / 100).toFixed(1);
    return +time;
}
