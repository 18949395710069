import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { parseZone, locale } from 'moment';

import { SHORT_MONTH_DATE_FORMAT, FULL_MONTH_DATE_FORMAT } from 'src/helpers/formatDate';

import { resources } from './resources';

i18n.use(initReactI18next).init({
    resources,
    lng: 'en-US',
    interpolation: {
        escapeValue: false,
        format: (value, format, lang) => {
            locale(lang);
            if (format?.includes('date')) {
                if (format === 'date-usual') return parseZone(value).format(SHORT_MONTH_DATE_FORMAT);
                if (format === 'date-fullMonth') return parseZone(value).format(FULL_MONTH_DATE_FORMAT);
                if (format === 'date-dayName') return parseZone(value).format('dddd');
                if (format === 'date-range') {
                    const dateTimeFormat: any = new Intl.DateTimeFormat(lang, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    });
                    return dateTimeFormat.formatRange(value.startDate, value.endDate);
                }
            }

            return value;
        },
    },
});

export default i18n;
