import { useTranslation } from 'react-i18next';
import { Control, Controller } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { IPatientInviteFormValues } from './patientInviteForm';

import { PdmDeviceClasses } from '../../models/app.models';

export interface IPatientInviteFormPdmValue {
    patientsPdm: PdmDeviceClasses | null;
}

interface IPatientsSelectInsulinDeviceControllerProps {
    control: Control<IPatientInviteFormValues>;
    pdmDeviceClassess: PdmDeviceClasses[];
}

const patientsSelectPdmName = 'patientsPdm';
const patientsSelectPdmDefaultValue = null;

const PatientsSelectInsulinDeviceController = ({
    control,
    pdmDeviceClassess,
}: IPatientsSelectInsulinDeviceControllerProps) => {
    const { t } = useTranslation();

    return (
        <FormControl component="fieldset">
            <FormLabel className="FormLabel" component="legend">
                {t('patientInvite.selectInsulinDevice')}
            </FormLabel>

            <Controller
                control={control}
                name={patientsSelectPdmName}
                defaultValue={patientsSelectPdmDefaultValue}
                render={({ field }) => (
                    <RadioGroup
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        className="FormGroup"
                    >
                        {pdmDeviceClassess.map((pdmDeviceClass) => (
                            <FormControlLabel
                                key={pdmDeviceClass}
                                value={pdmDeviceClass}
                                control={<Radio color="primary" size="small" />}
                                label={t(`patientInvitePDMProduct.${pdmDeviceClass}`)}
                            />
                        ))}
                    </RadioGroup>
                )}
                rules={{
                    required: true,
                }}
            />
        </FormControl>
    );
};

export default PatientsSelectInsulinDeviceController;
