import { useEffect } from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';

import { handleError } from 'src/helpers/handleError';
import { appInsights } from 'src/helpers/appInsights';
import { SeverityLevelType } from 'src/models/consts/severityLevelType';
import { AxiosRequestConfigWithRetry } from 'src/models/app.models';

import ErrorHandlerNavigation from './ErrorHandlerNavigation';

interface IErrorHandler {
    children?: React.ReactNode | React.ReactNode[];
}

const ErrorHandler = ({ children }: IErrorHandler) => {
    const dispatch = useDispatch();

    useEffect(() => {
        axios.interceptors.response.use(undefined, (error: AxiosError<any>) => {
            if (!error.config) {
                return Promise.reject(error);
            }

            const config = error.config as AxiosRequestConfigWithRetry;
            appInsights.trackException({
                exception: new Error(`${config.method} ${config.url}: ${error.message}`),
                severityLevel: SeverityLevelType.ERROR,
                properties: {
                    errorCode: error.response?.data?.error?.code ?? 'No Response Received',
                    method: config.method,
                    error,
                },
            });

            if (config.retryErrorHandler && Number(config.retry) >= 0) {
                config.retryErrorHandler(
                    error.response as AxiosResponse,
                    config.retry !== 0
                        ? async () => {
                              const newConfig = {
                                  ...config,
                                  retry: Number(config.retry) - 1,
                              } as AxiosRequestConfig;

                              await axios.request(newConfig);
                              return axios(newConfig);
                          }
                        : undefined
                );
            }

            if (!config.retryErrorHandler) handleError(dispatch, error);

            return Promise.reject(error);
        });
    }, [dispatch]);

    return <ErrorHandlerNavigation>{children}</ErrorHandlerNavigation>;
};

ErrorHandler.defaultProps = {
    children: undefined,
};

export default ErrorHandler;
