import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { useHistory, useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useEffect, useRef, useMemo, useState } from 'react';

import { ApprovalStatus, CurrentUserRole, IClinic } from 'src/models/app.models';
import { sendAzureEvent } from 'src/helpers/appInsights';
import {
    setActivePage,
    setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval,
} from 'src/stores/appStore/appActions';
import { RootStateType } from 'src/stores/store';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import { getClinics } from 'src/helpers/services/Clinics';
import { getPendingPatientsAndClinicalWorkersCounts } from 'src/helpers/services/PendingPatientsAndClinicalWorkersCounts';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { variables } from 'src/models/consts/variables';
import { getSidebarItems } from 'src/helpers/sidebarItems';
import { RoutePath } from 'src/models/table.models';

import PendingUsersCount from './PendingUsersCount';

interface ISidebarList {
    title: string;
    type: string;
    analyticsEventName: string;
    disabled?: boolean;
    link?: string;
    className?: string;
    activeClassName?: string;
}

interface ISidebarItem {
    title: string;
    items: ISidebarList[];
}

interface IPendingPatientsAndClinicalWorkersCounts {
    pendingClinicalWorkersCount: number | null;
    pendingPatientsCount: number | null;
}

const isProduction = getRuntimeConfig('REACT_APP_IS_PRODUCTION') === 'true';
const defaultPendingPatientsAndClinicalWorkersCountsTimerIntervalMilliseconds = 15000;

const getPendingPatientsAndClinicalWorkersCountsTimerIntervalDelay = (): number => {
    let pendingPatientsAndClinicalWorkersCountsTimerInterval = Number(
        getRuntimeConfig(variables.PENDING_PATIENTS_AND_CLINICAL_WORKERS_COUNTS_TIMER_INTERVAL_MILLISECONDS)
    );

    if (
        Number.isNaN(pendingPatientsAndClinicalWorkersCountsTimerInterval) ||
        pendingPatientsAndClinicalWorkersCountsTimerInterval < 1
    ) {
        pendingPatientsAndClinicalWorkersCountsTimerInterval =
            defaultPendingPatientsAndClinicalWorkersCountsTimerIntervalMilliseconds;
    }

    return pendingPatientsAndClinicalWorkersCountsTimerInterval;
};

const pendingPatientsAndClinicalWorkersCountsTimerIntervalDelay =
    getPendingPatientsAndClinicalWorkersCountsTimerIntervalDelay();

export const bemBlockName = 'sidebar';

const Sidebar = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const location = useLocation();

    const activePathname = location.pathname;

    const [clinics, setClinics] = useState<IClinic[] | null>(null);
    const [pendingPatientsAndClinicalWorkersCounts, setPendingPatientsAndClinicalWorkersCounts] =
        useState<IPendingPatientsAndClinicalWorkersCounts>({
            pendingClinicalWorkersCount: null,
            pendingPatientsCount: null,
        });
    const [isPendingPatientsAndClinicalWorkersCountsWithError, setIsPendingPatientsAndClinicalWorkersCountsWithError] =
        useState(false);
    const pendingPatientsAndClinicalWorkersCountsInterval = useRef<ReturnType<typeof setInterval> | null>(null);

    const { currentUser, shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval } = useSelector(
        (state: RootStateType) => ({
            currentUser: state.appState.currentUser,
            shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval:
                state.appState.shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval,
        })
    );

    const isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin =
        !isPauseItEnabled() &&
        currentUser?.role === CurrentUserRole.ClinicAdmin &&
        currentUser?.approvalStatus === ApprovalStatus.Approved;

    const loadClinics = async () => {
        const res = await getClinics();

        setClinics(res);
    };

    const loadPendingPatientsAndClinicalWorkersCounts = async () => {
        let newPendingPatientsAndClinicalWorkersCounts;
        try {
            newPendingPatientsAndClinicalWorkersCounts = await getPendingPatientsAndClinicalWorkersCounts();
        } catch {
            setIsPendingPatientsAndClinicalWorkersCountsWithError(true);
            return;
        }

        setIsPendingPatientsAndClinicalWorkersCountsWithError(false);
        setPendingPatientsAndClinicalWorkersCounts({
            pendingPatientsCount: newPendingPatientsAndClinicalWorkersCounts?.pendingPatientsCount,
            pendingClinicalWorkersCount: newPendingPatientsAndClinicalWorkersCounts?.pendingClinicalWorkersCount,
        });
    };

    useEffect(() => {
        if (!isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin) {
            return;
        }

        if (!pendingPatientsAndClinicalWorkersCountsInterval.current) {
            loadPendingPatientsAndClinicalWorkersCounts();
            pendingPatientsAndClinicalWorkersCountsInterval.current = setInterval(
                loadPendingPatientsAndClinicalWorkersCounts,
                pendingPatientsAndClinicalWorkersCountsTimerIntervalDelay
            );

            return;
        }

        if (shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval) {
            clearInterval(pendingPatientsAndClinicalWorkersCountsInterval.current);
            loadPendingPatientsAndClinicalWorkersCounts();
            dispatch(setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval(false));
            pendingPatientsAndClinicalWorkersCountsInterval.current = setInterval(
                loadPendingPatientsAndClinicalWorkersCounts,
                pendingPatientsAndClinicalWorkersCountsTimerIntervalDelay
            );
        }
    }, [
        currentUser,
        dispatch,
        isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin,
        shouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval,
    ]);

    useEffect(() => {
        return () => {
            if (pendingPatientsAndClinicalWorkersCountsInterval.current) {
                clearInterval(pendingPatientsAndClinicalWorkersCountsInterval.current);
            }
        };
    }, [pendingPatientsAndClinicalWorkersCountsInterval]);

    useEffect(() => {
        if (isPauseItEnabled() && currentUser?.role === CurrentUserRole.SuperAdmin) {
            loadClinics();
        }
    }, [currentUser?.role]);

    const items: ISidebarItem[] = useMemo(() => getSidebarItems(currentUser, clinics), [currentUser, clinics]);

    const changeActivePage = (pageType: string, analyticsEventName: string, link?: string) => {
        if (link) {
            sendAzureEvent(analyticsEventName, { link: `${link}` });
            window.open(link);

            return;
        }

        if (
            (isPauseItDisabledAndCurrentUserIsApprovedClinicAdmin && pageType === RoutePath.adminPendingPatients) ||
            pageType === RoutePath.adminPendingProviders
        ) {
            dispatch(setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval(true));
        }

        sendAzureEvent(analyticsEventName);
        dispatch(setActivePage(''));
        history.push(pageType);
    };

    return (
        <div className={bemBlockName}>
            {!isProduction && (
                <div className={`${bemBlockName}__non-production-label`}>
                    <div>{t('sidebar.nonProductionUse')}</div>
                </div>
            )}

            {items.map((item, index) => (
                <ul
                    key={uuidv4()}
                    className={clsx(`${bemBlockName}__list`, { first: index === 0 })}
                    data-testid={`sidebarSection-${index}`}
                >
                    <div className={`${bemBlockName}__list-title`} data-testid={`sidebarTitle-${index}`}>
                        {t(item.title)}
                    </div>

                    {item.items.map((listItem, i) => (
                        <li
                            key={uuidv4()}
                            role="menuitem"
                            onClick={() => changeActivePage(listItem.type, listItem.analyticsEventName, listItem?.link)}
                            data-testid={`sidebarItem-${i}`}
                            className={clsx(`${bemBlockName}__list-item`, listItem.className, {
                                disabled: listItem.disabled,
                                active: activePathname === listItem.type,
                                [`${listItem.activeClassName}`]: activePathname === listItem.type,
                            })}
                            aria-hidden="true"
                        >
                            {t(listItem.title)}

                            {listItem.type === RoutePath.adminPendingPatients && (
                                <PendingUsersCount
                                    count={pendingPatientsAndClinicalWorkersCounts.pendingPatientsCount}
                                    error={isPendingPatientsAndClinicalWorkersCountsWithError}
                                />
                            )}

                            {listItem.type === RoutePath.adminPendingProviders && (
                                <PendingUsersCount
                                    count={pendingPatientsAndClinicalWorkersCounts.pendingClinicalWorkersCount}
                                    error={isPendingPatientsAndClinicalWorkersCountsWithError}
                                />
                            )}
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    );
};

export default Sidebar;
