import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import { IPatientInviteFormValues } from './patientInviteForm';

import { getInvalidNameFieldError, getNameFieldWhitespaceError } from '../../helpers/formFieldValidation';

export interface IPatientInviteFormFirstNameValue {
    patientsFirstName: string;
}

interface IPatientsFirstNameControllerProps {
    control: Control<IPatientInviteFormValues>;
    isSubmitted: boolean;
}

const patientFirstNameDefaultValue = '';
const patientFirstNameMaxLength = 40;
const patientsFirstNameName = 'patientsFirstName';

const PatientsFirstNameController = ({ control, isSubmitted }: IPatientsFirstNameControllerProps) => {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            defaultValue={patientFirstNameDefaultValue}
            name={patientsFirstNameName}
            render={({ field, fieldState, formState }) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="TextField"
                    error={!!formState.errors.patientsFirstName}
                    fullWidth
                    helperText={fieldState.error?.message}
                    inputProps={{
                        'data-testid': patientsFirstNameName,
                        maxLength: patientFirstNameMaxLength,
                    }}
                    label={t('patientInvite.patientsFirstName')}
                    variant="outlined"
                />
            )}
            rules={{
                required: t<string>('patientInvite.enterPatientsFirstName'),
                validate: {
                    default: (value) =>
                        getInvalidNameFieldError(value, t('patientInvite.validCharactersAre'), isSubmitted, false),
                    whitespace: (value) =>
                        getNameFieldWhitespaceError(value, t('patientInvite.enterPatientsFirstName')),
                },
            }}
        />
    );
};

export default PatientsFirstNameController;
