import Alert from '@mui/material/Alert';
import clsx from 'clsx';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { NotificationSeverity } from '../../models/app.models';

const defaultProps = {
    severity: undefined,
};

interface ICalloutProps {
    children: React.ReactNode;
    severity?: NotificationSeverity;
}

const alertIconViewBox = '0 0 24 24';

const Callout = ({ children, severity }: ICalloutProps) => {
    return (
        <Alert
            className={clsx('callout', 'Alert')}
            iconMapping={{
                info: <SvgIcon component={InfoIcon} viewBox={alertIconViewBox} />,
            }}
            severity={severity}
            variant="outlined"
        >
            {children}
        </Alert>
    );
};

Callout.defaultProps = defaultProps;

export default Callout;
