import { Trans, useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import SvgIcon from '@mui/material/SvgIcon';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import SlideDownTransition from './SlideDownTransition';

import { ReactComponent as ErrorIcon } from '../../assets/images/errorIcon.svg';
import { ReactComponent as InfoIcon } from '../../assets/images/infoIcon.svg';
import { ReactComponent as SuccessIcon } from '../../assets/images/successIcon.svg';
import { ReactComponent as WarningIcon } from '../../assets/images/warningIcon.svg';
import { NotificationSeverity } from '../../models/app.models';

const defaultProps = {
    applyHeaderOffset: false,
    applyHomeHeaderOffset: false,
    applySidebarOffset: false,
    applyMaxWidth: false,
    message: undefined,
    onTransitionExited: undefined,
    severity: undefined,
    title: undefined,
};

interface IAlertToastProps {
    applyHeaderOffset?: boolean;
    applyHomeHeaderOffset?: boolean;
    applyMaxWidth?: boolean;
    applySidebarOffset?: boolean;
    message?: string;
    onClose: () => void;
    onTransitionExited?: () => void;
    open: boolean;
    severity?: NotificationSeverity;
    title?: string;
}

const AlertToast = ({
    applyHeaderOffset,
    applyHomeHeaderOffset,
    applyMaxWidth,
    applySidebarOffset,
    message,
    onClose,
    onTransitionExited,
    open,
    severity,
    title,
    ...otherProps
}: IAlertToastProps) => {
    const { t } = useTranslation();

    const [exited, setExited] = useState(false);

    useEffect(() => {
        if (open) {
            setExited(false);
        }
    }, [open]);

    const handleSnackbarOnClose = (event: React.SyntheticEvent<any> | Event, reason: SnackbarCloseReason) => {
        if (reason === 'clickaway') {
            return;
        }

        onClose();
    };

    const handleAlertOnClose = onClose;

    const handleSnackbarOnExited = () => {
        setExited(true);

        if (typeof onTransitionExited === 'function') {
            onTransitionExited();
        }
    };

    const alertIconViewBox = '0 0 24 24';

    if (!open && exited) {
        return null;
    }

    return (
        <Snackbar
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...otherProps}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            className={clsx('AlertToast', {
                'header-offset': applyHeaderOffset,
                'home-header-offset': applyHomeHeaderOffset,
                'max-width': applyMaxWidth,
                'sidebar-offset': applySidebarOffset,
            })}
            onClose={handleSnackbarOnClose}
            open={open}
            TransitionComponent={SlideDownTransition}
            TransitionProps={{
                onExited: handleSnackbarOnExited,
            }}
        >
            <Alert
                iconMapping={{
                    error: <SvgIcon component={ErrorIcon} viewBox={alertIconViewBox} />,
                    info: <SvgIcon component={InfoIcon} viewBox={alertIconViewBox} />,
                    success: <SvgIcon component={SuccessIcon} viewBox={alertIconViewBox} />,
                    warning: <SvgIcon component={WarningIcon} viewBox={alertIconViewBox} />,
                }}
                closeText={t('alertToast.close')}
                onClose={handleAlertOnClose}
                severity={severity}
            >
                {title && <AlertTitle>{title}</AlertTitle>}

                <div>
                    <Trans components={{ bold: <b />, italic: <i /> }}>{message}</Trans>
                </div>
            </Alert>
        </Snackbar>
    );
};

AlertToast.defaultProps = defaultProps;

export default AlertToast;
