import moment from 'moment';

export const SHORT_MONTH_DATE_FORMAT = 'MMM D, YYYY';
export const FULL_MONTH_DATE_FORMAT = 'MMMM D, YYYY';

const getEmptyValue = (emptyValue?: string): string => {
    return emptyValue ?? '';
};

export const formatDate = (date: string | null | undefined, format: string, emptyValue?: string): string => {
    if (!date) {
        return getEmptyValue(emptyValue);
    }

    const momentDate = moment(date);
    return momentDate.isValid() ? momentDate.format(format) : getEmptyValue(emptyValue);
};

export const formatDateWithTimezone = (
    date: string | null | undefined,
    format: string,
    emptyValue?: string
): string => {
    if (!date) {
        return getEmptyValue(emptyValue);
    }

    const momentDate = moment.parseZone(date);
    return momentDate.isValid() ? momentDate.format(format) : getEmptyValue(emptyValue);
};
