export function checkUrlPatientDetails(search: string) {
    const patientId = new URLSearchParams(search).get('patients') ?? '';
    const daysCount = Number(new URLSearchParams(search).get('days') ?? '');
    const displayOnPrintString = new URLSearchParams(search).get('displayOnPrintOnly');
    const timeZone = new URLSearchParams(search).get('timeZone') ?? '';
    const displayOnPrint = displayOnPrintString === 'true';

    return {
        patientId,
        daysCount,
        displayOnPrint,
        timeZone,
    };
}
