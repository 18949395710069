import { useTranslation } from 'react-i18next';

import { IPendingProvidersTableRows } from '../../models/table.models';
import PromptModal from '../promptModal/promptModal';

interface IDenyClinicalWorkersPromptModalProps {
    clinicalWorkers: IPendingProvidersTableRows[];
    onClose: () => void;
    onOkClick: () => void;
    isOpen: boolean;
    selectedClinicalWorkerIds: string[];
}

const DenyClinicalWorkersPromptModal = ({
    clinicalWorkers,
    onClose,
    onOkClick,
    isOpen,
    selectedClinicalWorkerIds,
}: IDenyClinicalWorkersPromptModalProps) => {
    const { t } = useTranslation();

    const isSingleClinicalWorkerSelected = selectedClinicalWorkerIds.length === 1;

    const displayIcon = true;
    const okButtonLabel = t('denyModal.deny');

    if (isSingleClinicalWorkerSelected) {
        const selectedClinicalWorker = clinicalWorkers.find(
            (clinicalWorker) => clinicalWorker.id === selectedClinicalWorkerIds[0]
        );

        return (
            <PromptModal
                displayIcon={displayIcon}
                description={t('denyModal.singleClinicalWorkerDescription')}
                isOpen={isOpen}
                okButtonLabel={okButtonLabel}
                onClose={onClose}
                onOkClick={onOkClick}
                subtitle={t('denyModal.singleClinicalWorkerSubtitle', {
                    npi: selectedClinicalWorker?.npi || t('common.notAvailable'),
                })}
                title={t('denyModal.singleClinicalWorkerTitle', {
                    first: selectedClinicalWorker?.firstName,
                    last: selectedClinicalWorker?.lastName,
                })}
            />
        );
    }

    return (
        <PromptModal
            displayIcon={displayIcon}
            description={t('denyModal.multipleClinicalWorkersDescription')}
            isOpen={isOpen}
            okButtonLabel={okButtonLabel}
            onClose={onClose}
            onOkClick={onOkClick}
            title={t('denyModal.multipleClinicalWorkersTitle')}
        />
    );
};

export default DenyClinicalWorkersPromptModal;
