import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import PatientInvitePauseItOff from './PatientInvitePauseItOff';
import PatientInvitePauseItOn from './PatientInvitePauseItOn';

import { isPauseItEnabled } from '../../helpers/featureToggles';
import { CurrentUserRole, INotification } from '../../models/app.models';
import { RoutePath } from '../../models/table.models';
import { RootStateType } from '../../stores/store';
import { SELECTED_PATIENT_ID_PARAM_NAME, PAGE_PARAM_NAME } from '../../models/consts/patientDirectoryRouteParams';

export interface IRedirectToPatientsDirectory {
    newNotification?: INotification;
    patientId?: string;
}
// TODO: add patientSource?: string property to allow identifying if selected item in patient directory is patient or patient invitation

export const patientInviteBemBlockName = 'patient-invite';

const PatientInvite = () => {
    const history = useHistory();
    const location = useLocation<Location>();
    const urlSearchParams = new URLSearchParams(location.search);
    const searchParamsPatientId: string | null = urlSearchParams.get('patientId');
    const searchParamsPage: string | null = urlSearchParams.get('directoryPage');

    const [isReinvite, setIsReinvite] = useState<boolean | null>(null);

    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    useEffect(() => {
        const newIsReinvite = !!searchParamsPatientId;

        setIsReinvite(newIsReinvite);
    }, [searchParamsPatientId]);

    // TODO: extract redirect to patient directory to a separate component
    const redirectToPatientsDirectory = useCallback(
        ({ patientId, newNotification }: IRedirectToPatientsDirectory) => {
            let newLocation = RoutePath.patients;

            if (currentUser?.role === CurrentUserRole.ClinicAdmin) {
                newLocation = RoutePath.adminPatients;
            }

            const searchParams = new URLSearchParams();

            if (searchParamsPage) {
                searchParams.set(PAGE_PARAM_NAME, searchParamsPage);
            }

            if (patientId) {
                searchParams.set(SELECTED_PATIENT_ID_PARAM_NAME, patientId);
            }

            history.push({
                pathname: newLocation,
                ...(searchParams.keys().next().value && { search: searchParams.toString() }),
                ...(newNotification && { state: { notification: newNotification } }),
            });
        },
        [currentUser?.role, history, searchParamsPage]
    );

    if (isPauseItEnabled()) {
        return (
            <PatientInvitePauseItOn
                redirectToPatientsDirectory={redirectToPatientsDirectory}
                isReinvite={isReinvite}
                searchParamsPatientId={searchParamsPatientId}
            />
        );
    }

    return (
        <PatientInvitePauseItOff
            redirectToPatientsDirectory={redirectToPatientsDirectory}
            reinvitePatientId={searchParamsPatientId}
        />
    );
};

export default PatientInvite;
