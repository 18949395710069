import { detect, OperatingSystem } from 'detect-browser';

import { BrowserOperatingSystem } from '../models/app.models';

const WINDOWS_DESKTOP_OPERATING_SYSTEMS: OperatingSystem[] = [
    'Windows 3.11',
    'Windows 95',
    'Windows 98',
    'Windows 2000',
    'Windows XP',
    'Windows Server 2003',
    'Windows Vista',
    'Windows 7',
    'Windows 8',
    'Windows 8.1',
    'Windows 10',
    'Windows ME',
];

const MAC_DESKTOP_OPERATING_SYSTEMS: OperatingSystem[] = ['Mac OS'];

export const getOperatingSystem = () => {
    const browser = detect();
    const operatingSystem = browser?.os as OperatingSystem;

    if (WINDOWS_DESKTOP_OPERATING_SYSTEMS.includes(operatingSystem)) return BrowserOperatingSystem.PC;
    if (MAC_DESKTOP_OPERATING_SYSTEMS.includes(operatingSystem)) return BrowserOperatingSystem.Mac;

    return null;
};
