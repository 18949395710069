import { useTranslation } from 'react-i18next';

import UserNotAssignedToApplicationError from './UserNotAssignedToApplicationError';

export const USER_NOT_ASSIGNED_TO_APPLICATION_MESSAGE = 'User is not assigned to the client application.';

interface LoginErrorProps {
    error: Error;
}

function LoginError({ error }: LoginErrorProps) {
    const { t } = useTranslation();

    if (error.message === USER_NOT_ASSIGNED_TO_APPLICATION_MESSAGE) {
        return <UserNotAssignedToApplicationError />;
    }

    return (
        <div data-testid="login-error">
            {error.name && error.message
                ? t('loginError.errorMessage', { name: error.name, message: error.message })
                : t('loginError.errorMessage', { name: t('loginError.error'), message: error })}
        </div>
    );
}

export default LoginError;
