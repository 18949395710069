export const UPLOAD_FILE_MAX_SIZE = 50 * 1024 * 1024;
export const IBF_FILE_SIGNATURE = '001E000000010007000000010007496E73756C6574004F6D6E69506F640005AA001100';
export const IBF_FILE_SIGNATURE_OFFSET = 0;
export const HEX_CHARS_IN_BYTES = 2;
export const HEX_BASE = 16;
export const HEX_PADDING_CHARACTER = '0';
export const SHOW_DATE_CHANGE_THRESHOLD_DIFFERENCE_IN_HOURS = 24;
export const BYTES_IN_KILOBYTES = 1024;

export const isValidSignature = async (file: ArrayBuffer): Promise<boolean> => {
    const fileSignature = new Uint8Array(
        file.slice(IBF_FILE_SIGNATURE_OFFSET, IBF_FILE_SIGNATURE.length / HEX_CHARS_IN_BYTES)
    );
    const fileSignatureString = [...fileSignature]
        .map((x) => x.toString(HEX_BASE).padStart(HEX_CHARS_IN_BYTES, HEX_PADDING_CHARACTER))
        .join('');
    return IBF_FILE_SIGNATURE.toLowerCase() === fileSignatureString;
};
