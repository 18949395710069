import { PdmDeviceClasses } from 'src/models/app.models';

import { getPatientInfoConfig } from '../../web-services/PatientDetails.service';
import { createEmptyPartsOfDay, createPartsOfDay } from '../createPartsOfDay';
import {
    generateContinuousGlucoseStatistics,
    generateDiscreteGlucoseStatistics,
    generateDiscreteGlucoseCountStatistics,
} from '../glucoseStatisticsStructure';
import createDeviceUseStructure from '../createDeviceUseStructure';
import { createTimeInRangeStructure } from '../timeInRangeStructure';
import { summarySettingsStructure, patientInfoStructure, insulinStatisticsStructure } from '../reportServiceStructure';
import createDeviceUseStructureForDashEros from '../createDeviceUseStructureForDashEros';

export async function getPatientInfo(patientId: string, periodDays: number, timeZone: string) {
    const res = await getPatientInfoConfig(patientId, periodDays, timeZone);

    if (!res.data) return { statusCode: res.status, patientInfoData: null };

    const { patient, device, period, createdDate, common, daily, summary, dataCharacteristics, settings } =
        res.data.data.item;

    const commonPatientData = {
        ...patientInfoStructure(patient),
        createdDate,
        device,

        daily,
        days: period?.days,
        startDate: period?.startDate,
        endDate: period?.endDate,

        insulinStatistics: summary ? { ...insulinStatisticsStructure(summary, common) } : null,

        partsOfDay: summary?.bolusStatisticalDay
            ? createPartsOfDay(summary.bolusStatisticalDay)
            : createEmptyPartsOfDay(),

        ...summarySettingsStructure(summary, common),

        glucoseUnits: common?.measurementUnits?.glucose,
        common,
    };

    if (device.pdmDeviceClass !== PdmDeviceClasses.Omnipod5)
        return {
            patientInfoData: {
                ...commonPatientData,
                summaryBasalProfile: {
                    areSegmentsDisplayed: summary?.settings?.basal?.activeProgram.areSegmentsDisplayed,
                    name: summary?.settings?.basal?.activeProgram.name,
                    total: summary?.settings?.basal?.activeProgram.totalRate,
                    dayUnits: common.measurementUnits?.dailyBasalRate,
                    hourUnits: common.measurementUnits?.basalRate,
                    data: summary?.settings?.basal ? summary.settings.basal?.activeProgram.segments : [],
                },

                glucoseStatistics: generateDiscreteGlucoseStatistics(
                    summary?.glucoseStatistics,
                    common.measurementUnits.glucose
                ),
                deviceUse: createDeviceUseStructureForDashEros(summary || {}),
                dataCharacteristics: {
                    noRecentDays: dataCharacteristics?.recencyCheckPeriodDays,
                    noRecentData: !(dataCharacteristics?.isRecent ?? true),
                    summaryIsEmpty: !summary,
                },

                glucoseCountStatistics: generateDiscreteGlucoseCountStatistics(summary?.glucoseCountStatistics),
                settings: settings
                    ? {
                          basal: {
                              ...settings?.basal,
                              units: common.measurementUnits.basalRate,
                          },
                          bloodGlucose: {
                              ...settings?.bloodGlucose,
                              units: common.measurementUnits.glucose,
                          },
                          bolus: {
                              ...settings?.bolus,
                              carbsUnits: common.measurementUnits.carbs,
                          },
                          device: {
                              serialNumber: settings?.device.serialNumber,
                          },
                      }
                    : null,
            },
            statusCode: res.status,
        };

    const patientData = {
        ...commonPatientData,
        modeHypoProtectTargetGlucose: common?.insulinDeliveryModeHypoProtectTargetGlucose,
        timeInRange: summary?.glucoseTimeInRange?.actual
            ? createTimeInRangeStructure(
                  summary.glucoseTimeInRange.actual,
                  summary.glucoseTimeInRange.target,
                  common.glucoseRanges,
                  common.measurementUnits.glucose
              )
            : null,
        glucoseStatistics: generateContinuousGlucoseStatistics(
            summary?.glucoseStatistics,
            common.measurementUnits.glucose
        ),

        deviceUse: createDeviceUseStructure(summary || {}),

        glucose: {
            ranges: common.glucoseRanges,
            displayRange: common.glucoseDisplayRange,
            statistics: summary?.glucoseStatisticalDay?.data,
            duration: summary?.settings?.bolus ? summary.settings.bolus.insulinActionDuration : null,
        },
        glucoseRanges: common.glucoseRanges,

        dataCharacteristics: {
            noRecentDays: dataCharacteristics?.recencyCheckPeriodDays,
            noRecentData: !(dataCharacteristics?.isRecent ?? true),
            noSufficientForSummary: !dataCharacteristics?.isSufficientForSummary,
            sufficiencyForSummaryCheckPeriodDays: dataCharacteristics?.sufficiencyForSummaryCheckPeriodDays,
            summaryIsEmpty: !summary,
        },

        settings: settings
            ? {
                  basal: {
                      ...settings?.basal,
                      units: common.measurementUnits.basalRate,
                  },
                  bolus: settings?.bolus,
                  device: settings?.device,
                  libre2: settings?.libre2,
              }
            : null,
    };
    return { patientInfoData: patientData, statusCode: res.status };
}
