import { ISummaryChartSettings } from 'src/models/summaryChart.model';

import { changeTimeInNumber } from './changeTimeInNumber';
import { combineEqualValues } from './combineEqualValues';

import { IInsulinCorrectionFactorSettings } from '../models/report.models';

export function createCorrectionFactorStructure(correctionFactor: IInsulinCorrectionFactorSettings[]) {
    const data: ISummaryChartSettings[] = [];
    combineEqualValues(correctionFactor, (key) => key.factor).forEach((item) => {
        data.push({
            x: changeTimeInNumber(item.startTime),
            duration: changeTimeInNumber(item.endTime, true) - changeTimeInNumber(item.startTime),
            value: `${item.factor}`,
        });
    });
    return data;
}
