import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { sendAzureEvent } from 'src/helpers/appInsights';
import createPath from 'src/helpers/createPath';
import createUrl from 'src/helpers/createURL';
import { isPauseItEnabled } from 'src/helpers/featureToggles';
import { analyticsEventNames } from 'src/models/consts/analytics';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { urls } from 'src/models/consts/urls';
import { variables } from 'src/models/consts/variables';

const Help = () => {
    const { t } = useTranslation();

    return (
        <div className="main-content resources">
            <h1 className="main-content__title">{t('help.title')}</h1>
            {/* TODO: refactor this component, it is turning into spaghetti code. */}

            <div className="resources__wrapper">
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <h3 className="resources__title">{t('help.care')}</h3>
                                    <div className="resources__margin-top" />
                                    {isPauseItEnabled() ? (
                                        <>
                                            <p className={clsx('resources__descr_large')}>
                                                <a
                                                    href={`mailto:${t('customerSupportEmail')}`}
                                                    className="resources__black"
                                                    data-testid="customerSupportEmail"
                                                    onClick={() => {
                                                        sendAzureEvent(analyticsEventNames.CLICK_SUPPORT_EMAIL);
                                                    }}
                                                >
                                                    {t('customerSupportEmail')}
                                                </a>
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p data-testid="customerCarePhone" className="resources__black">
                                                {t('help.phone')}
                                            </p>
                                            <div className="resources__margin-top" />
                                            <a
                                                className="resources__link"
                                                target="_blank"
                                                href="https://www.omnipod.com/contact-us"
                                                rel="noopener noreferrer"
                                                data-testid="contactUs"
                                                onClick={() => sendAzureEvent(analyticsEventNames.VIEW_CONTACT_US)}
                                            >
                                                {t('help.contactUs')}
                                            </a>
                                        </>
                                    )}
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <h3 className="resources__title">{t('help.hcpAccount')}</h3>
                                    <div className="resources__margin-top" />
                                    <p className="resources__black">{t('help.hcpInstruction')}</p>
                                    <div className="resources__margin-top" />
                                    <a
                                        className="resources__link"
                                        target="_blank"
                                        href={createUrl(variables.OMNIPOD_CONNECT_HOME_URL)}
                                        rel="noopener noreferrer"
                                        data-testid="hcpLink"
                                        onClick={() => sendAzureEvent(analyticsEventNames.VIEW_HCP_ACCOUNT)}
                                    >
                                        {getRuntimeConfig(variables.OMNIPOD_CONNECT_HOME_URL)}
                                    </a>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <h3 className="resources__title">{t('help.instruction')}</h3>
                                    <a
                                        className="resources__link"
                                        target="_blank"
                                        href={createPath(
                                            createUrl(urls.HCP_PUBLIC_PAGES),
                                            'docs/omnipod-discover-user-guide-hcp.pdf'
                                        )}
                                        rel="noopener noreferrer"
                                        data-testid="omnipodPortal"
                                        onClick={() => sendAzureEvent(analyticsEventNames.VIEW_PORTAL_INSTRUCTIONS)}
                                    >
                                        {t('help.omnipodPortal')}
                                    </a>
                                    <div className="resources__margin-top" />
                                    <a
                                        className="resources__link"
                                        target="_blank"
                                        href={createPath(
                                            createUrl(urls.HCP_PUBLIC_PAGES),
                                            'docs/omnipod-discover-user-guide.pdf'
                                        )}
                                        rel="noopener noreferrer"
                                        data-testid="omnipodApp"
                                        onClick={() =>
                                            sendAzureEvent(analyticsEventNames.VIEW_PATIENT_APP_INSTRUCTIONS)
                                        }
                                    >
                                        {t('help.omnipodApp')}
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default Help;
