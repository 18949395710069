import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';
import { Moment } from 'moment';

import { SHORT_MONTH_DATE_FORMAT } from 'src/helpers/formatDate';
import { ReactComponent as CalendarIcon } from 'src/assets/images/calendarIcon.svg';

const defaultProps = {
    error: false,
    fullWidth: false,
    helperText: undefined,
    maxDate: undefined,
    margin: 'none',
    placeholder: null,
};

interface IDatePickerProps {
    error?: boolean;
    fullWidth?: boolean;
    helperText?: string;
    onChange: (value: Moment | null) => void;
    label: string;
    maxDate?: Moment;
    margin?: 'dense' | 'normal' | 'none';
    placeholder?: string;
    value: Moment | null;
}

const DatePicker = ({
    error,
    fullWidth,
    helperText,
    onChange,
    label,
    maxDate,
    margin,
    placeholder,
    value,
}: IDatePickerProps) => {
    const { t } = useTranslation();

    return (
        <DesktopDatePicker
            className="TextField"
            format={SHORT_MONTH_DATE_FORMAT}
            localeText={{
                fieldMonthPlaceholder: (params) =>
                    params.contentType === 'letter' ? t('datePicker.shortMonthName') : t('datePicker.monthNumber'),
            }}
            label={value ? label : ''}
            maxDate={maxDate}
            onChange={onChange}
            slots={{
                openPickerIcon: CalendarIcon,
            }}
            slotProps={{
                desktopPaper: {
                    className: 'DatePicker',
                },
                openPickerButton: {
                    'aria-label': t('datePicker.changeDate'),
                },
                textField: {
                    error,
                    fullWidth,
                    helperText,
                    id: 'date-picker',
                    placeholder: placeholder ?? label,
                    margin,
                },
            }}
            value={value}
        />
    );
};

DatePicker.defaultProps = defaultProps;

export default DatePicker;
