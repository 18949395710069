export function getRuntimeConfig(key: string): string | undefined {
    let envValue: string | undefined = process.env[key];

    if (envValue == null) {
        const windowRunConfig = (window as unknown as { runConfig: { [key: string]: string } }).runConfig;

        if (windowRunConfig) {
            envValue = windowRunConfig[key];
        }
    }

    return envValue;
}
