import { useSelector } from 'react-redux';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { useState } from 'react';

import UserOptionsDropdownMenu from './UserOptionsDropdownMenu';
import Avatar from './Avatar';

import { getCurrentUserInitials } from '../../helpers/getCurrentUserInitials';
import { bemBlockName } from '../../pages/Home';
import { RootStateType } from '../../stores/store';
import TruncateText from '../truncateText/TruncateText';

const maximumEmailWidth = 190;

const UserOptions = () => {
    const [anchorElement, setAnchorElement] = useState<Element | null>(null);
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const handleOnClick = (event: React.MouseEvent<Element>) => {
        setAnchorElement(event.currentTarget);
    };

    // TODO: Currently, we do not handle this kind of accessibility in the app.
    const handleOnKeyDown = () => null;

    const handleOnDropdownMenuClose = () => {
        setAnchorElement(null);
    };

    const isDropdownMenuOpen = !!anchorElement;
    const userName = currentUser?.email || currentUser?.name?.first;
    const currentUserInitials = getCurrentUserInitials(currentUser);

    return (
        <>
            <div
                className={`${bemBlockName}__user-options`}
                onClick={handleOnClick}
                onKeyDown={handleOnKeyDown}
                role="button"
                tabIndex={0}
            >
                {currentUserInitials && <Avatar text={currentUserInitials} />}

                {userName ? (
                    <span className={`${bemBlockName}__user-name`} data-testid={`${bemBlockName}__user-name`}>
                        <TruncateText text={userName} maximumTextWidth={maximumEmailWidth} />
                    </span>
                ) : null}

                <KeyboardArrowDownOutlinedIcon fontSize="small" />
            </div>

            <UserOptionsDropdownMenu
                anchorElement={anchorElement}
                isOpen={isDropdownMenuOpen}
                onClose={handleOnDropdownMenuClose}
            />
        </>
    );
};

export default UserOptions;
