import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { Column } from '@material-table/core';

import { isEqual } from 'src/helpers/objectVerification';

import {
    lastNameTableColumn,
    firstNameTableColumn,
    emailTableColumn,
    statusTableColumn,
} from '../../helpers/superAdminManageAccountsBasicTableColumns';
import {
    ClinicalWorkerRole,
    IInviteClinicalWorker,
    IMaterialTableRowData,
    ISuperAdminsClinicalWorker,
} from '../../models/app.models';
import {
    addClinicalWorker,
    getProvidersByClinicId,
    updateClinicalWorkerById,
} from '../../helpers/services/SuperAdmins';
import MaterialTable from '../materialTable/MaterialTable';
import Loading from '../loading/Loading';

const bemBlockName = 'clinic-providers';

type SuperAdminClinicalWorkerRowData = ISuperAdminsClinicalWorker & IMaterialTableRowData;

interface IProvidersProps {
    handleClinicalWorkerSelectionChange: (id: string | null) => void;
    selectedClinicalWorkerId: string | null;
    clinicId: string;
}

const Providers = ({ handleClinicalWorkerSelectionChange, selectedClinicalWorkerId, clinicId }: IProvidersProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [providers, setProviders] = useState<SuperAdminClinicalWorkerRowData[]>([]);

    const loadProviders = async (id: string) => {
        const data = await getProvidersByClinicId(id);

        setProviders(data.items);
        setLoading(false);
    };

    const saveNewProvider = async (data: ISuperAdminsClinicalWorker) => {
        setLoading(true);

        const newProvider: IInviteClinicalWorker = {
            email: data.email,
            name: {
                first: data.firstName,
                last: data.lastName,
            },
            type: ClinicalWorkerRole.Provider,
            clinicId,
        };

        const savedAddedProvider = await addClinicalWorker(newProvider);

        if (savedAddedProvider) {
            setProviders([...providers, savedAddedProvider]);
            setLoading(false);
        }
    };

    const updateProvider = async (updatedProvider: ISuperAdminsClinicalWorker) => {
        setLoading(true);

        const newUpdatedProvider = {
            email: updatedProvider.email,
            name: {
                first: updatedProvider.firstName,
                last: updatedProvider.lastName,
            },
            isActive: updatedProvider.isActive,
        };

        const savedUpdatedProvider = await updateClinicalWorkerById(updatedProvider.id, newUpdatedProvider);

        const updatedProviderIndex = providers.findIndex((provider) => updatedProvider.id === provider.id);
        const newProviders: ISuperAdminsClinicalWorker[] = [...providers];

        if (savedUpdatedProvider) {
            newProviders[updatedProviderIndex] = savedUpdatedProvider;
            setProviders(newProviders);
            setLoading(false);
            if (!savedUpdatedProvider.isActive) handleClinicalWorkerSelectionChange(null);
        }
    };

    useEffect(() => {
        loadProviders(clinicId);
    }, [clinicId]);

    const handleOnRowAdd = async (newData: ISuperAdminsClinicalWorker) => {
        await saveNewProvider(newData);
    };

    const handleOnRowUpdate = async (
        updatedProvider: SuperAdminClinicalWorkerRowData,
        currentProvider?: SuperAdminClinicalWorkerRowData
    ) => {
        if (!currentProvider) {
            return;
        }

        const newUpdatedProvider = {
            ...updatedProvider,
        };
        const newCurrentProvider = {
            ...currentProvider,
        };
        delete newUpdatedProvider.tableData;
        delete newCurrentProvider.tableData;

        newUpdatedProvider.isActive = newUpdatedProvider.isActive.toString() === 'true';
        newCurrentProvider.isActive = newCurrentProvider.isActive.toString() === 'true';

        const wasProviderUpdated = !isEqual(newUpdatedProvider, newCurrentProvider);

        if (wasProviderUpdated) {
            await updateProvider(newUpdatedProvider);
        }
    };

    const columns: Column<ISuperAdminsClinicalWorker>[] = [
        {
            ...(lastNameTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
            field: 'lastName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            ...(firstNameTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
            field: 'firstName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            ...(emailTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
        },
        {
            ...(statusTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'onUpdate',
        },
    ];

    // Diverging from current Loading pattern and opting to hide content to allow Material Table to continue updating its state.
    return (
        <>
            {loading && <Loading />}

            <div className={clsx({ hidden: loading })}>
                <div className={clsx(bemBlockName)} data-testid={bemBlockName}>
                    <MaterialTable
                        columns={columns}
                        data={providers}
                        options={{ toolbar: true }}
                        editable={{
                            onRowAdd: handleOnRowAdd,
                            onRowUpdate: handleOnRowUpdate,
                        }}
                        singleRowSelection
                        selectedRowId={selectedClinicalWorkerId}
                        onSingleRowSelected={handleClinicalWorkerSelectionChange}
                    />
                </div>
            </div>
        </>
    );
};

export default Providers;
