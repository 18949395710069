import { Column } from '@material-table/core';
import { Theme } from '@mui/material';
import { TFunction } from 'react-i18next';

import { ISuperAdminsClinicalWorker, ISuperAdmin, ISuperAdminsPatient } from 'src/models/app.models';

export const tableColumnCellStyle = (theme: Theme) => ({
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightMedium,
});

type SuperAdminManageAccountsTableColumn = ISuperAdminsPatient | ISuperAdminsClinicalWorker | ISuperAdmin;

export const emailTableColumn = (t: TFunction, theme: Theme): Column<SuperAdminManageAccountsTableColumn> => ({
    cellStyle: {
        ...tableColumnCellStyle(theme),
        minWidth: 240,
    },
    editable: 'never',
    field: 'email',
    title: t('superAdminManageAccountsBasicTableColumns.email'),
    width: '5%',
});

export const firstNameTableColumn = (t: TFunction, theme: Theme): Column<SuperAdminManageAccountsTableColumn> => ({
    cellStyle: {
        ...tableColumnCellStyle(theme),
        fontWeight: theme.typography.fontWeightBold,
        minWidth: 180,
    },
    editable: 'never',
    field: 'name.first',
    title: t('superAdminManageAccountsBasicTableColumns.firstName'),
    width: '5%',
});

export const lastNameTableColumn = (t: TFunction, theme: Theme): Column<SuperAdminManageAccountsTableColumn> => ({
    cellStyle: {
        ...tableColumnCellStyle(theme),
        fontWeight: theme.typography.fontWeightBold,
        minWidth: 180,
    },
    editable: 'never',
    field: 'name.last',
    title: t('superAdminManageAccountsBasicTableColumns.lastName'),
    width: '5%',
});

export const statusTableColumn = (t: TFunction, theme: Theme): Column<SuperAdminManageAccountsTableColumn> => ({
    cellStyle: {
        ...tableColumnCellStyle(theme),
    },
    editable: 'never',
    field: 'isActive',
    lookup: {
        true: t('superAdminManageAccountsBasicTableColumns.active'),
        false: t('superAdminManageAccountsBasicTableColumns.inactive'),
    },
    title: t('superAdminManageAccountsBasicTableColumns.status'),
});
