import { ClinicalWorkerRole, IUpsertClinicalWorkerInvitation } from 'src/models/app.models';

import { getProvidersData } from '../../web-services/Providers.service';
import {
    getApprovedClinicalWorkersData,
    createClinicalWorkerInvitationData,
    deactivateClinicalWorkerData,
    updateClinicalWorkerRoleData,
    reactivateClinicalWorkerData,
    getClinicalWorkerInvitationData,
    removeClinicalWorkerInvitationData,
    updateClinicalWorkerInvitationData,
} from '../../web-services/Admin.service';
import {
    createClinicalWorkerInvitationStructure,
    createClinicalWorkersStructure,
} from '../createClinicalWorkersStructure';

export async function getApprovedClinicalWorkers() {
    const res = await getApprovedClinicalWorkersData();

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createClinicalWorkersStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function getProviders() {
    const res = await getProvidersData();

    const { items, totalCount } = res?.data || { items: [], totalCount: 0 };

    return {
        items: items?.length ? items : [],
        totalCount: totalCount || 0,
    };
}

export async function createClinicalWorkerInvitation(data: IUpsertClinicalWorkerInvitation) {
    const res = await createClinicalWorkerInvitationData(data);

    const { item } = res?.data || { item: null };

    return item != null ? createClinicalWorkerInvitationStructure(item) : null;
}

export async function updateClinicalWorkerInvitation(data: IUpsertClinicalWorkerInvitation, id: string) {
    const res = await updateClinicalWorkerInvitationData(data, id);

    const { item } = res?.data || { item: null };

    return item != null ? createClinicalWorkerInvitationStructure(item) : null;
}

export async function getClinicalWorkerInvitation(id: string) {
    const res = await getClinicalWorkerInvitationData(id);

    const { item } = res?.data || { item: null };

    return item != null ? createClinicalWorkerInvitationStructure(item) : null;
}

export async function deactivateClinicalWorker(id: string) {
    await deactivateClinicalWorkerData(id);
}

export async function reactivateClinicalWorker(id: string) {
    await reactivateClinicalWorkerData(id);
}

export async function updateClinicalWorkerRole(id: string, role: ClinicalWorkerRole) {
    await updateClinicalWorkerRoleData(id, role);
}

export async function removeClinicalWorkerInvitation(id: string) {
    await removeClinicalWorkerInvitationData(id);
}
