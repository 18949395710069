import { patientInviteBemBlockName } from './PatientInvite';

interface IMovePatientPromptModalInfoItemProps {
    label: string;
    value: string;
}

const MovePatientPromptModalInfoItem = ({ label, value }: IMovePatientPromptModalInfoItemProps) => {
    return (
        <div className={`${patientInviteBemBlockName}__modal-info-item`}>
            <div className={`${patientInviteBemBlockName}__modal-label`}>{label}</div>
            <div className={`${patientInviteBemBlockName}__modal-value`}>{value}</div>
        </div>
    );
};

export default MovePatientPromptModalInfoItem;
