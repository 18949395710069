import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { ButtonColors } from 'src/models/app.models';

interface IButtonProps {
    onClick?: () => void;
    text?: string;
    className?: string;
    color?: ButtonColors;
    dataTestId?: string;
    disabled?: boolean;
    startIcon?: React.ReactNode;
    type?: 'button' | 'submit';
    variant?: 'outlined';
}

const Button = ({ className, text, dataTestId, onClick, disabled, startIcon, type, variant, color }: IButtonProps) => {
    const { t } = useTranslation();

    return (
        <button
            // TODO: Remove one of these disabled classes
            className={clsx(className, variant && `btn_${variant}`, {
                disabled,
                btn_disabled: disabled,
                [`btn-color_${color}`]: color,
            })}
            data-testid={dataTestId}
            // Disabling react/button-has-type and opting for this style in order to support any new `type` in the feature.
            // eslint-disable-next-line react/button-has-type
            type={type ?? 'button'}
            onClick={onClick}
            disabled={disabled}
        >
            {startIcon && (
                <div className="start-icon" data-testid="startIconContainer">
                    {startIcon}
                </div>
            )}
            {text ? t(text) : null}
        </button>
    );
};

Button.defaultProps = {
    text: null,
    className: 'btn',
    color: undefined,
    dataTestId: '',
    disabled: false,
    onClick: undefined,
    startIcon: null,
    type: 'button',
    variant: undefined,
};

export default Button;
