import { IInviteClinicalWorker, IUpdateClinicalWorker, IUpdateSuperAdminPatient } from '../../models/app.models';
import {
    addClinicalWorkerData,
    deactivateSuperAdminData,
    getClinicAdminsByClinicIdData,
    getPatientsByClinicIdData,
    getProvidersByClinicIdData,
    getSuperAdminsData,
    reactivateSuperAdminData,
    reinviteClinicalWorkerData,
    updateClinicalWorkerByIdData,
    updatePatientByIdData,
} from '../../web-services/SuperAdmins.service';
import {
    createSuperAdminsClinicalWorkersStructure,
    createSuperAdminsClinicalWorkerStructure,
} from '../createSuperAdminsClinicalWorkerStructure';
import {
    createSuperAdminsPatientsStructure,
    createSuperAdminsPatientStructure,
} from '../createSuperAdminsPatientStructure';

export async function addClinicalWorker(clinicalWorker: IInviteClinicalWorker) {
    const res = await addClinicalWorkerData(clinicalWorker);

    const { item } = res?.data || { item: null };

    return item != null ? createSuperAdminsClinicalWorkerStructure(item) : null;
}

export async function deactivateSuperAdmin(id: string) {
    const res = await deactivateSuperAdminData(id);

    const { item } = res?.data || { item: null };

    return item != null ? item : null;
}

export async function getClinicAdminsByClinicId(clinicId: string) {
    const res = await getClinicAdminsByClinicIdData(clinicId);

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createSuperAdminsClinicalWorkersStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function getPatientsByClinicId(clinicId: string) {
    const res = await getPatientsByClinicIdData(clinicId);

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createSuperAdminsPatientsStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function getSuperAdmins() {
    const res = await getSuperAdminsData();

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? items : [],
        totalCount: totalCount || 0,
    };
}

export async function getProvidersByClinicId(clinicId: string) {
    const res = await getProvidersByClinicIdData(clinicId);

    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createSuperAdminsClinicalWorkersStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function reactivateSuperAdmin(id: string) {
    const res = await reactivateSuperAdminData(id);

    const { item } = res?.data || { item: null };

    return item != null ? item : null;
}

// TODO: Update method, reinvite API call returns 204 with no content.
export async function reinviteClinicalWorker(id: string) {
    const res = await reinviteClinicalWorkerData(id);

    const { item } = res?.data || { item: null };

    return item;
}

export async function updateClinicalWorkerById(workerId: string, data: IUpdateClinicalWorker) {
    const res = await updateClinicalWorkerByIdData(workerId, data);

    const { item } = res?.data || { item: null };

    return item != null ? createSuperAdminsClinicalWorkerStructure(item) : null;
}

export async function updatePatientById(
    patientId: string,
    forceSaveCustomerId: boolean,
    data: IUpdateSuperAdminPatient
) {
    const res = await updatePatientByIdData(patientId, forceSaveCustomerId, data);

    const { item } = res?.data || { item: null };

    return item != null ? createSuperAdminsPatientStructure(item) : null;
}
