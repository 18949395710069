import axios from 'axios';

import { createApiPath } from 'src/helpers/createPath';
import { Resource, ResourceAction } from 'src/models/app.models';

export async function getCurrentClinicalWorkerData() {
    const res = await axios.get(createApiPath('clinical-workers/current'));
    return res.data;
}

export async function getCurrentUserData() {
    const res = await axios.get(createApiPath('users/current'));
    return res.data;
}

export async function performResourceAction(resource: Resource, action: ResourceAction) {
    await axios.post(createApiPath(`clinical-workers/current/resources/${resource}/${action}`));
}
