import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Column } from '@material-table/core';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import {
    emailTableColumn,
    firstNameTableColumn,
    lastNameTableColumn,
    statusTableColumn,
} from '../../helpers/superAdminManageAccountsBasicTableColumns';
import { IMaterialTableRowData, ISuperAdmin } from '../../models/app.models';
import { deactivateSuperAdmin, getSuperAdmins, reactivateSuperAdmin } from '../../helpers/services/SuperAdmins';
import Loading from '../loading/Loading';
import MaterialTable from '../materialTable/MaterialTable';

const bemBlockName = 'super-admins';

type SuperAdminRowData = ISuperAdmin & IMaterialTableRowData;

function SuperAdmins() {
    const { t } = useTranslation();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [superAdmins, setSuperAdmins] = useState<SuperAdminRowData[]>([]);

    const columns: Column<ISuperAdmin>[] = [
        {
            ...(lastNameTableColumn(t, theme) as Column<ISuperAdmin>),
        },
        {
            ...(firstNameTableColumn(t, theme) as Column<ISuperAdmin>),
        },
        {
            ...(emailTableColumn(t, theme) as Column<ISuperAdmin>),
        },
        {
            ...(statusTableColumn(t, theme) as Column<ISuperAdmin>),
            editable: 'onUpdate',
        },
    ];

    const loadSuperAdmins = async () => {
        const data = await getSuperAdmins();

        setSuperAdmins(data.items);
        setLoading(false);
    };

    useEffect(() => {
        loadSuperAdmins();
    }, []);

    const toggleNewSuperAdminIsActive = async (updatedSuperAdmin: ISuperAdmin) => {
        setLoading(true);

        const updatedSuperAdminIndex = superAdmins.findIndex((superAdmin) => updatedSuperAdmin.id === superAdmin.id);
        const newSuperAdmins: ISuperAdmin[] = [...superAdmins];
        const newIsActive = updatedSuperAdmin.isActive.toString() === 'true';

        newSuperAdmins[updatedSuperAdminIndex] = {
            ...updatedSuperAdmin,
            isActive: newIsActive,
        };

        if (newIsActive) {
            await reactivateSuperAdmin(updatedSuperAdmin.id);
        } else {
            await deactivateSuperAdmin(updatedSuperAdmin.id);
        }

        setSuperAdmins(newSuperAdmins);
        setLoading(false);
    };

    const handleOnRowUpdate = async (updatedSuperAdmin: SuperAdminRowData, currentSuperAdmin?: SuperAdminRowData) => {
        const wasIsActiveUpdated = currentSuperAdmin
            ? updatedSuperAdmin.isActive !== currentSuperAdmin.isActive
            : false;

        if (wasIsActiveUpdated) {
            await toggleNewSuperAdminIsActive(updatedSuperAdmin);
        }
    };

    // Diverging from current Loading pattern and opting to hide content to allow Material Table to continue updating its state.
    return (
        <>
            {loading && <Loading />}

            <div className={clsx('main-content', bemBlockName, { hidden: loading })} data-testid={bemBlockName}>
                <h1 className={clsx('main-content__title', `${bemBlockName}__title`)}>{t('superAdmins.title')}</h1>

                <MaterialTable
                    columns={columns}
                    data={superAdmins}
                    editable={{
                        onRowUpdate: handleOnRowUpdate,
                    }}
                />
            </div>
        </>
    );
}

export default SuperAdmins;
