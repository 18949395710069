import { ComparisonOperator } from 'src/models/app.models';

interface IGlucoseStatisticsDetailsTargetValue {
    value: number;
    comparisonOperator: ComparisonOperator;
}

interface IContinuousGlucoseStatisticsData {
    actual: {
        average: number;
        glucoseManagementIndicator: number;
        variability: number;
        standardDeviation: number;
    };
    target: {
        average: IGlucoseStatisticsDetailsTargetValue;
        glucoseManagementIndicator: IGlucoseStatisticsDetailsTargetValue;
        variability: IGlucoseStatisticsDetailsTargetValue;
    };
}

export interface IDiscreteGlucoseStatisticsData {
    actual?: {
        average: number;
        range: {
            lowest: number;
            highest: number;
        };
        variability: number;
    } | null;
    target: {
        variability: IGlucoseStatisticsDetailsTargetValue;
    };
}

interface IDiscreteGlucoseCountStatisticsData {
    total: number;
    averageDaily: number;
}

export function generateContinuousGlucoseStatistics(
    metrics: IContinuousGlucoseStatisticsData | undefined,
    units: string
) {
    return [
        {
            title: 'continuousGlucoseStatistics.average',
            value: metrics?.actual?.average,
            units,
            badge: metrics?.target?.average.comparisonOperator,
        },
        {
            title: 'continuousGlucoseStatistics.managementIndicator',
            value: metrics?.actual?.glucoseManagementIndicator,
            units: '%',
            goal: metrics?.target?.glucoseManagementIndicator.value,
            badge: metrics?.target?.glucoseManagementIndicator.comparisonOperator,
            hideGoalWithNoValue: true,
            noValueTemplate: 'continuousGlucoseStatistics.noGmiData',
        },
        {
            title: 'continuousGlucoseStatistics.variability',
            value: metrics?.actual?.variability,
            units: '%',
            goal: metrics?.target?.variability.value,
            badge: metrics?.target?.variability.comparisonOperator,
            note: 'continuousGlucoseStatistics.coefficientOfVariation',
        },
        {
            title: 'continuousGlucoseStatistics.deviation',
            value: metrics?.actual?.standardDeviation,
            units,
        },
    ];
}

export function generateDiscreteGlucoseStatistics(
    statistics: IDiscreteGlucoseStatisticsData | undefined,
    units: string
) {
    return [
        {
            title: 'discreteGlucoseStatistics.average',
            subtitle: 'discreteGlucoseStatistics.averageSubtitle',
            value: statistics?.actual?.average,
            units,
        },
        {
            title: 'discreteGlucoseStatistics.lowestHighest',
            range: statistics?.actual?.range,
            units,
        },
        {
            title: 'discreteGlucoseStatistics.variability',
            subtitle: 'discreteGlucoseStatistics.variabilitySubtitle',
            value: statistics?.actual?.variability,
            goal: statistics?.target?.variability.value,
            badge: statistics?.target?.variability.comparisonOperator,
            units: '%',
        },
    ];
}

export function generateDiscreteGlucoseCountStatistics(
    countStatistics: IDiscreteGlucoseCountStatisticsData | undefined
) {
    return [
        {
            title: 'discreteGlucoseStatistics.total',
            value: countStatistics?.total,
        },
        {
            title: 'discreteGlucoseStatistics.averageDaily',
            value: countStatistics?.averageDaily,
        },
    ];
}
