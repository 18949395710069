import moment from 'moment';

// file name format: {year}-{month}-{day}-{lastName}{firstNameInitial}-{yearOfBirth}. Example: 2022-04-16-DoeJ-1990
export const generateReportFileName = (
    birthdate: string | null,
    createdDate: string | null,
    firstName: string | null,
    lastName: string | null
) => {
    const reportCreationDate = createdDate ? moment(createdDate).format('YYYY-MM-DD') : '';
    const patientYearOfBirth = birthdate ? moment(birthdate).get('year') : '';
    const firstNameInitialIndex = 0;
    const patientFirstNameInitial = firstName ? firstName.charAt(firstNameInitialIndex) : '';
    const patientLastName = lastName || '';

    return `${reportCreationDate}-${patientLastName}${patientFirstNameInitial}-${patientYearOfBirth}`;
};
