import axios from 'axios';
import pRetry, { AbortError } from 'p-retry';

import { createApiPath } from '../helpers/createPath';
import { getRuntimeConfig } from '../helpers/envReader';
import { PdmUploadState, PdmPreviousActionState } from '../models/app.models';
import { variables } from '../models/consts/variables';

export function uploadFile(data: FormData) {
    const uploadFileAxiosInstance = axios.create();

    return uploadFileAxiosInstance.post(createApiPath('/devices/files'), data, { timeout: 30000 });
}

function getRetries(previousAction: PdmPreviousActionState) {
    if (previousAction === PdmPreviousActionState.Parse)
        return Number(getRuntimeConfig(variables.DASH_EROS_UPLOADER_QUERY_PARSE_OUTCOME_RETRY_COUNT));
    if (previousAction === PdmPreviousActionState.Load)
        return Number(getRuntimeConfig(variables.DASH_EROS_UPLOADER_QUERY_LOAD_OUTCOME_RETRY_COUNT));

    throw new Error('Unexpected previous action.');
}

export async function getStateAfterAction(fileId: string, previousAction: PdmPreviousActionState) {
    const retries = getRetries(previousAction);

    const run = async (attemptNumber: number) => {
        let response;

        try {
            response = await axios.get(
                createApiPath(`/devices/files/${fileId}/state?previousAction=${previousAction}`)
            );

            const { state } = response.data.data.item;

            if (
                attemptNumber < retries &&
                ((state === PdmUploadState.Pending && previousAction === PdmPreviousActionState.Parse) ||
                    (state === PdmUploadState.Confirmed && previousAction === PdmPreviousActionState.Load))
            ) {
                throw new Error(state);
            }
        } catch (error: any) {
            if (
                (error.message !== PdmUploadState.Pending && previousAction === PdmPreviousActionState.Parse) ||
                (error.message !== PdmUploadState.Confirmed && previousAction === PdmPreviousActionState.Load)
            ) {
                throw new AbortError(error.message);
            } else {
                throw new Error(error.message);
            }
        }

        return response;
    };

    try {
        return await pRetry(run, { retries });
    } catch (error: any) {
        throw new Error(error.message);
    }
}

export async function getUploadMetadataData(uploadId: string) {
    const metadataAxiosInstance = axios.create();

    const res = await metadataAxiosInstance.get(createApiPath(`/devices/files/${uploadId}/metadata`));
    return res?.data;
}

export async function requestSavingUploadedFileData(fileId: string) {
    await axios.put(createApiPath(`/devices/files/${fileId}/confirm`));
}

export async function requestCorrectingUploadedFileDataDates(
    fileId: string,
    currentDate: string,
    enteredCurrentDeviceDate: string
) {
    await axios.put(createApiPath(`/devices/files/${fileId}/date-correction`), {
        currentDate,
        enteredCurrentDeviceDate,
    });
}
