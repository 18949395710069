import clsx from 'clsx';

import Button from '../button/Button';
import Modal, { bemBlockName } from '../modal/Modal';
import exclamationPointIcon from '../../assets/images/exclamationPointIcon.svg';
import closeIcon from '../../assets/images/closeIcon.svg';

interface IAlertModalProps {
    className?: string;
    description: string;
    displayIcon?: boolean;
    isOpen: boolean;
    okButtonLabel: string;
    onClose: () => void;
    title: string;
}

function AlertModal({ className, description, displayIcon, isOpen, okButtonLabel, onClose, title }: IAlertModalProps) {
    if (!isOpen) {
        return null;
    }

    return (
        <Modal className={clsx(`${bemBlockName}-alertModal`, className)}>
            <>
                <img
                    alt="Close"
                    aria-hidden="true"
                    className={`${bemBlockName}__close`}
                    data-testid="AlertModalClose"
                    onClick={onClose}
                    src={closeIcon}
                />

                {displayIcon && (
                    <img className={`${bemBlockName}__iconExclamationPoint`} src={exclamationPointIcon} alt="Error" />
                )}

                <div className={`${bemBlockName}__title`}>{title}</div>

                <div className={`${bemBlockName}__descr-wrapper`}>
                    <div className={clsx(`${bemBlockName}__descr`, `${bemBlockName}__description-medium`)}>
                        {description}
                    </div>
                </div>

                <div className={`${bemBlockName}__buttons`}>
                    <Button text={okButtonLabel} data-testid="modalOk" className="btn btn_warning" onClick={onClose} />
                </div>
            </>
        </Modal>
    );
}

AlertModal.defaultProps = {
    className: '',
    displayIcon: true,
};

export default AlertModal;
