import React from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Link';

interface IFindDateInstructions {
    text: string;
    placement?: 'bottom' | 'bottom-end';
    children: React.ReactElement | string;
    onOpen: () => void;
}

const FindDateInstructions = ({ text, placement, children, onOpen }: IFindDateInstructions) => {
    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        onOpen();
        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Tooltip
                arrow
                className="Tooltip"
                disableFocusListener
                disableHoverListener
                disableTouchListener
                onClose={handleTooltipClose}
                open={open}
                placement={placement}
                PopperProps={{ disablePortal: true }}
                title={children}
            >
                <Button className="Link" onClick={handleTooltipOpen}>
                    {text}
                </Button>
            </Tooltip>
        </ClickAwayListener>
    );
};

FindDateInstructions.defaultProps = {
    placement: 'bottom',
};

export default FindDateInstructions;
