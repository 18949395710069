import moment from 'moment';

export interface IOktaData {
    access: string;
    token: string;
    aud: string;
    expires: string;
    id: string;
}

const getIsoDate = (
    utcOffset: number,
    dateOld: string,
    offsetDays = 0,
    offsetMilliseconds = 0,
    allowFutureDates = false
): string | undefined => {
    const dateNew: moment.Moment = moment(dateOld)
        .utcOffset(utcOffset)
        .add(offsetDays, 'days')
        .add(offsetMilliseconds, 'milliseconds');

    return !allowFutureDates && dateNew > moment().utcOffset(utcOffset) ? undefined : dateNew.toISOString();
};

const getOktaExpirationIsoTimestamp = (oktaExpiresIn = 0): any => {
    const now = new Date();
    // "oktaExpiresIn" is in sec
    const deltaSec = oktaExpiresIn * 1000 - now.valueOf();

    return getIsoDate(0, now.toUTCString(), 0, deltaSec, true);
};

export const parseOktaData = (authState: any): IOktaData => ({
    access: `Bearer ${authState?.accessToken.accessToken}`, // Prefix 'Bearer ' is required by the BE
    token: authState?.accessToken.accessToken,
    aud: authState?.accessToken?.claims?.aud,
    expires: getOktaExpirationIsoTimestamp(authState?.accessToken?.expiresAt),
    id: authState?.idToken?.idToken,
});
