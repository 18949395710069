import { isPauseItEnabled } from './featureToggles';

import {
    clinicAdminPauseItOffSidebar,
    clinicAdminPauseItOnSidebar,
    defaultPauseItOffSidebar,
    defaultPauseItOnSidebar,
    notApprovedClinicAdminPauseItOffSidebar,
    notApprovedClinicAdminPauseItOnSidebar,
    notApprovedPhysicianPauseItOffSidebar,
    notApprovedPhysicianPauseItOnSidebar,
    physicianPauseItOffSidebar,
    physicianPauseItOnSidebar,
    superAdminSidebar,
} from '../models/consts/sidebarItems';
import { ApprovalStatus, CurrentUserRole, IClinic, IUser } from '../models/app.models';
import { analyticsEventNames } from '../models/consts/analytics';
import { RoutePath } from '../models/table.models';

const getSuperAdminExtendedSidebar = (clinics: IClinic[] | null) => {
    if (clinics) {
        const superAdminClinicItems = clinics.map((clinic: IClinic) => {
            return {
                title: clinic.name,
                type: RoutePath.clinics.replace(':id', clinic.id),
                analyticsEventName: analyticsEventNames.VIEW_CLINIC,
                className: 'clinics-item',
                activeClassName: 'active-clinic',
            };
        });

        return [
            {
                ...superAdminSidebar[0],
                items: [...superAdminClinicItems, ...superAdminSidebar[0].items],
            },
            superAdminSidebar[1],
        ];
    }

    return superAdminSidebar;
};

const getPauseItOnSidebarItems = (currentUser: Partial<IUser> | null) => {
    if (currentUser?.role === CurrentUserRole.ClinicAdmin) {
        if (currentUser.approvalStatus !== ApprovalStatus.Approved) {
            return notApprovedClinicAdminPauseItOnSidebar;
        }

        return clinicAdminPauseItOnSidebar;
    }

    if (currentUser?.role === CurrentUserRole.Physician) {
        if (currentUser.approvalStatus !== ApprovalStatus.Approved) {
            return notApprovedPhysicianPauseItOnSidebar;
        }

        return physicianPauseItOnSidebar;
    }

    return defaultPauseItOnSidebar;
};

const getPauseItOffSidebarItems = (currentUser: Partial<IUser> | null) => {
    if (currentUser?.role === CurrentUserRole.ClinicAdmin) {
        if (currentUser.approvalStatus !== ApprovalStatus.Approved) {
            return notApprovedClinicAdminPauseItOffSidebar;
        }

        return clinicAdminPauseItOffSidebar;
    }

    if (currentUser?.role === CurrentUserRole.Physician) {
        if (currentUser.approvalStatus !== ApprovalStatus.Approved) {
            return notApprovedPhysicianPauseItOffSidebar;
        }

        return physicianPauseItOffSidebar;
    }

    return defaultPauseItOffSidebar;
};

export const getSidebarItems = (currentUser: Partial<IUser> | null, clinics: IClinic[] | null) => {
    if (currentUser?.role === CurrentUserRole.SuperAdmin) {
        return getSuperAdminExtendedSidebar(clinics);
    }

    if (isPauseItEnabled()) {
        return getPauseItOnSidebarItems(currentUser);
    }

    return getPauseItOffSidebarItems(currentUser);
};
