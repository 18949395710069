import { ClinicalWorkerRole, ClinicalWorkerStatus } from './app.models';

export interface ITableColumns {
    className?: string;
    name: string;
    sort?: boolean;
    align?: string;
    size?: string;
}

export interface IPatients {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    status: PatientStatus;
    source: PatientSource;
}

export interface IClinic {
    id: string;
    name: string;
}

export interface IDetailedPatients {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    status: PatientStatus;
    clinicName: string;
    email: string;
    deviceClass: string;
}

export interface IPatientsTableRows {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    days: number;
    hasDeviceSettings: boolean;
    lastDate: string;
    lastUploaded: string;
    deviceClass: string;
    status: PatientStatus;
    source: PatientSource;
}

export interface IPendingPatientsTableRows {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    requestedDate: string;
    mrn: string;
}

export interface IPendingProvidersTableRows {
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
    email: string;
    credentials: string;
}

export interface IApprovedClinicalWorkersTableRows {
    id: string;
    firstName: string;
    lastName: string;
    npi: string;
    email: string;
    credentials: string;
    role: ClinicalWorkerRole;
    status: ClinicalWorkerStatus;
}

export enum PatientStatus {
    active = 'active',
    invited = 'invited',
}

export enum PatientSource {
    patient = 'patient',
    patientInvitation = 'patient-invitation',
}

// TODO:
// Remove admin routes that use same components as provider ones.
// Remove unused - home, prescribe, training.
export enum RoutePath {
    about = '/about',
    adminAbout = '/admin/about',
    adminClinicalWorkersInvite = '/admin/staff/invite',
    adminHelp = '/admin/help',
    adminPatients = '/admin/patients/approved',
    adminPatientsInvite = '/admin/patients/invite',
    adminPendingPatients = '/admin/patients/pending',
    adminPendingProviders = '/admin/staff/pending',
    adminProviders = '/admin/staff/approved',
    help = '/help',
    home = '/home',
    patients = '/patients',
    patientsInvite = '/patients/invite',
    pdmUploader = '/uploader',
    pdmUploaderDateChange = '/uploader/date-change',
    prescribe = '/prescribe',
    training = '/training',
    clinics = '/super-admin/clinics/:id',
    superAdmins = '/super-admin/super-admins',
}

export enum ErrorPages {
    authenticationFailure = 'authenticationFailure',
    noRole = 'noRole',
    clinicalWorkerNotApproved = 'clinicalWorkerNotApproved',
    clinicalWorkerDenied = 'clinicalWorkerDenied',
    clinicalWorkerPending = 'clinicalWorkerPending',
    clinicalWorkerNotFound = 'clinicalWorkerNotFound',
    patientsClinicAssociationStatusChangeFailed = 'PatientsClinicAssociationStatusChangeFailed',
    patientsClinicAssociationStatusChangePartiallyFailed = 'PatientsClinicAssociationStatusChangePartiallyFailed',
    recall = 'recall',
}
