import Link from '@mui/material/Link';
import { Trans, useTranslation } from 'react-i18next';

interface IInvitePatientMessage {
    onInviteLinkClick: () => void;
}

const bemBlockName = 'tableEmptyContent';

const InvitePatientMessage = ({ onInviteLinkClick }: IInvitePatientMessage) => {
    const { t } = useTranslation();

    return (
        <div className={`${bemBlockName}__invite-patient-message`}>
            <h2 className={`${bemBlockName}__invite-patient-message_title`}>
                {t('patientDirectory.emptySearchPatientsListTitle')}
            </h2>
            <p className={`${bemBlockName}__invite-patient-message_descr`}>
                <Trans
                    i18nKey="patientDirectory.emptySearchPatientsListDescr"
                    components={{
                        Link: (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <Link className="Link" onClick={onInviteLinkClick} data-testid="invitePatientLink" />
                        ),
                    }}
                />
            </p>
        </div>
    );
};
export default InvitePatientMessage;
