import { PdmDeviceClasses } from '../models/app.models';
import {
    INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS,
    LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM,
    SEVEN_REPORT_DAYS_MENU_ITEM,
} from '../models/consts/patientDirectory';
import { IReportDaysSelectMenuItem } from '../models/patientDirectory.model';
import { IPatientsTableRows, PatientStatus } from '../models/table.models';

const oneDay = 1;

export const findSelectedReportDaysByDaysMenuItemText = (
    reportDaysSelectMenuItems: IReportDaysSelectMenuItem[],
    daysMenuItemText: string
): IReportDaysSelectMenuItem | undefined => {
    const newSelectedReportDays = reportDaysSelectMenuItems.find((menuItem) => menuItem.text === daysMenuItemText);

    return newSelectedReportDays;
};

export function getNextLargerMenuItem(patientDays: number) {
    return patientDays >= 7
        ? INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS.find((menuItem) => menuItem.value >= patientDays)
        : null;
}

export const getNewReportDaysSelectMenuItems = (patient: IPatientsTableRows): IReportDaysSelectMenuItem[] => {
    const isPatientDaysLessThanSeven = patient.days < SEVEN_REPORT_DAYS_MENU_ITEM.value;

    return [
        ...(isPatientDaysLessThanSeven ? [LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM] : []),
        ...INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS,
    ].map((day) => {
        const isDayTextLessThanSevenDaysText = day.text === LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM.text;
        const nextLargerMenuItem = getNextLargerMenuItem(patient.days);
        const isDayValueGreaterThanPatientDays = day.value > patient.days;
        const isPatientDaysLessThanOne = patient.days < oneDay;
        const isDisabled =
            (isDayTextLessThanSevenDaysText && isPatientDaysLessThanOne && !patient.hasDeviceSettings) ||
            (!isDayTextLessThanSevenDaysText && isDayValueGreaterThanPatientDays && nextLargerMenuItem !== day);

        return {
            ...day,
            isDisabled,
        };
    });
};

export const getNewShowNoDataModalValue = (patient: IPatientsTableRows) => {
    const { days, deviceClass, hasDeviceSettings } = patient;
    const isOmnipod5Class = deviceClass === PdmDeviceClasses.Omnipod5;
    const isPatientActive = patient.status === PatientStatus.active;
    const isPatientDaysLessThanOne = days < oneDay;

    return isOmnipod5Class && isPatientActive && isPatientDaysLessThanOne && !hasDeviceSettings;
};
