import { useMemo } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from '@mui/icons-material/Close';

import { IId } from 'src/models/app.models';

interface IInputProps {
    placeholder?: string;
    inputValue?: string;
    width?: number;
    className?: string;
    Icon?: typeof SvgIcon;
    onChange: (value: string) => void;
    onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    onClose?: () => void;
    onChangeWithId?: (id: IId) => void;
    dataId?: string;
    dataTestId?: string;
}

const Input = ({
    className,
    placeholder,
    inputValue,
    width,
    dataId,
    dataTestId,
    Icon,
    onChange,
    onKeyPress,
    onClose,
    onChangeWithId,
}: IInputProps) => {
    const handleChange = (e: React.ChangeEvent) => {
        const { value } = e.target as HTMLInputElement;

        if (dataId && onChangeWithId) {
            onChangeWithId({
                id: dataId,
            });
        }
        onChange(value);
    };

    const showValue = useMemo(() => {
        return inputValue;
    }, [inputValue]);

    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' && e.target instanceof HTMLElement) {
            e.target.blur();
        }
        onKeyPress(e);
    };

    return (
        <div className={`input ${Icon && 'withIcon'} ${className}`} style={{ width: `${width}rem` }}>
            {Icon && (
                <div className="input__icon">
                    <Icon fontSize="large" />
                </div>
            )}
            <input
                type="text"
                placeholder={placeholder}
                onChange={(e) => handleChange(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={showValue}
                data-testid={dataTestId}
            />
            {inputValue?.length ? (
                <div className="input__close-icon" aria-hidden onClick={onClose} data-testid="searchClear">
                    <CloseIcon fontSize="large" />
                </div>
            ) : null}
        </div>
    );
};

Input.defaultProps = {
    placeholder: '',
    inputValue: '',
    width: 100,
    Icon: null,
    className: '',
    dataId: '',
    dataTestId: '',
    onChangeWithId: null,
    onClose: () => null,
};

export default Input;
