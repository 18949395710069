import { Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import ListItemIcon from '@mui/material/ListItemIcon';
import LogoutIcon from '@mui/icons-material/Logout';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Avatar from './Avatar';

import { getCurrentUserInitials } from '../../helpers/getCurrentUserInitials';
import { analyticsEventNames } from '../../models/consts/analytics';
import { bemBlockName } from '../../pages/Home';
import { getRuntimeConfig } from '../../helpers/envReader';
import { RootStateType } from '../../stores/store';
import { sendAzureEvent } from '../../helpers/appInsights';
import { variables } from '../../models/consts/variables';
import TruncateText from '../truncateText/TruncateText';

const maximumNameWidth = 345;
const maximumEmailWidth = 335;

interface UserOptionsDropdownMenuProps {
    anchorElement: Element | null;
    isOpen: boolean;
    onClose: () => void;
}

const UserOptionsDropdownMenu = ({ anchorElement, isOpen, onClose }: UserOptionsDropdownMenuProps) => {
    const { t } = useTranslation();
    const { oktaAuth } = useOktaAuth();
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    const onSignOutClick = async () => {
        sendAzureEvent(analyticsEventNames.LOG_OUT);
        onClose();

        await oktaAuth.signOut({
            postLogoutRedirectUri: `${window.location.origin}${getRuntimeConfig(variables.PUBLIC_URL)}`,
        });
    };

    let name;

    if (!currentUser?.name || (!currentUser.name.first && !currentUser.name.last)) {
        name = t('common.noValue');
    } else if (currentUser.name.first && currentUser.name.last) {
        name = t('homeHeader.userFullName', {
            first: currentUser.name.first,
            last: currentUser.name.last,
        });
    } else if (!currentUser.name.first && currentUser.name.last) {
        name = t('homeHeader.userLastName', {
            last: `${currentUser.name.last}`,
        });
    } else if (currentUser.name.first) {
        name = t('homeHeader.userFirstName', {
            first: `${currentUser.name.first}`,
        });
    }

    const email = currentUser?.email || t('common.noValue');
    const currentUserInitials = getCurrentUserInitials(currentUser);

    return (
        <Menu
            anchorEl={anchorElement}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            className={`${bemBlockName}__user-options-dropdown-menu`}
            onClose={onClose}
            open={isOpen}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
            <MenuItem className={`${bemBlockName}__user-options-dropdown-menu-full-name-and-email-item`}>
                <div className={`${bemBlockName}__user-options-dropdown-menu-full-name`}>
                    {currentUserInitials && <Avatar text={currentUserInitials} />}

                    <span
                        className={`${bemBlockName}__user-options-dropdown-menu-email`}
                        data-testid={`${bemBlockName}__user-options-dropdown-menu-email`}
                    >
                        <TruncateText text={email} maximumTextWidth={maximumEmailWidth} />
                    </span>
                </div>

                {currentUserInitials ? (
                    <div
                        className={`${bemBlockName}__user-options-dropdown-menu-name-pad`}
                        data-testid={`${bemBlockName}__user-options-dropdown-menu-name-pad`}
                    >
                        <TruncateText text={name ?? ''} maximumTextWidth={maximumNameWidth} />
                    </div>
                ) : (
                    <div
                        className={`${bemBlockName}__user-options-dropdown-menu-name`}
                        data-testid={`${bemBlockName}__user-options-dropdown-menu-name`}
                    >
                        <TruncateText text={name ?? ''} maximumTextWidth={maximumNameWidth} />
                    </div>
                )}
            </MenuItem>

            <Divider className={`${bemBlockName}__user-options-dropdown-menu-divider`} />

            <MenuItem onClick={onSignOutClick}>
                <ListItemIcon>
                    <LogoutIcon color="primary" />
                </ListItemIcon>

                {t('homeHeader.signOut')}
            </MenuItem>
        </Menu>
    );
};

export default UserOptionsDropdownMenu;
