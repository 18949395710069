import { useSelector } from 'react-redux';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Link from '@mui/material/Link';

import { IApprovedClinicalWorkersTableRows, ITableColumns } from 'src/models/table.models';
import { formatClinicalWorkerCredentials } from 'src/helpers/formatClinicalWorkerCredentials';
import { ClinicalWorkerRole, ClinicalWorkerStatus } from 'src/models/app.models';
import { RootStateType } from 'src/stores/store';

import ClinicalWorkerPermissionsSelect from '../clinicalWorkerPermissionsSelect/ClinicalWorkerPermissionsSelect';
import TruncateText from '../truncateText/TruncateText';
import ClinicalWorkerStatusSelect from '../clinicalWorkerStatus/ClinicalWorkerStatusSelect';

const maximumProviderNameWidth = 260;
const maximumProviderEmailWidth = 300;
const maximumProviderCredentialsWidth = 300;

interface IProvidersTableProps {
    columns: ITableColumns[];
    rows: IApprovedClinicalWorkersTableRows[];
    onStatusChange: (clinicalWorkerId: string, status: ClinicalWorkerStatus) => void;
    onPermissionsChange: (clinicalWorkerId: string, role: ClinicalWorkerRole) => void;
    onReinviteLinkClick: (clinicalWorkerId: string) => void;
}

const ProvidersTable = ({
    columns,
    rows,
    onStatusChange,
    onPermissionsChange,
    onReinviteLinkClick,
}: IProvidersTableProps) => {
    const { t } = useTranslation();
    const currentUserId = useSelector((state: RootStateType) => state.appState.currentUser?.id);

    const handleClickReinvite = (patientId: string) => {
        onReinviteLinkClick(patientId);
    };

    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        {columns.map((column) => (
                            <td key={uuidv4()} className={clsx(column.size, column.className)}>
                                {column.sort ? <UnfoldMoreIcon /> : null} {t(column.name)}
                            </td>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((row) => {
                        const handleStatusChange = (newStatus: ClinicalWorkerStatus) => {
                            onStatusChange(row.id, newStatus);
                        };

                        const handlePermissionsChange = (newRole: ClinicalWorkerRole) => {
                            onPermissionsChange(row.id, newRole);
                        };

                        let providerName: string;

                        if (!row.firstName && !row.lastName) {
                            providerName = t('common.noValue');
                        } else if (row.firstName && row.lastName) {
                            providerName = t('patientDirectory.nameFull', {
                                first: row.firstName,
                                last: row.lastName,
                            });
                        } else {
                            providerName = t('patientDirectory.name', {
                                first: row.firstName,
                                last: row.lastName,
                            });
                        }

                        return (
                            <tr key={uuidv4()}>
                                <td className="large">
                                    <TruncateText text={providerName} maximumTextWidth={maximumProviderNameWidth} />
                                </td>
                                <td className="large">
                                    <TruncateText text={row.email} maximumTextWidth={maximumProviderEmailWidth} />
                                </td>
                                <td>
                                    <ClinicalWorkerPermissionsSelect
                                        value={row.role}
                                        onPermissionsChange={handlePermissionsChange}
                                        disabled={
                                            row.id === currentUserId || row.status !== ClinicalWorkerStatus.Active
                                        }
                                    />
                                </td>
                                {row.status === ClinicalWorkerStatus.Invited ? (
                                    <td className="extra-small">
                                        {
                                            // TODO: [DIB-10040] - Change this to a new select
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <Link onClick={() => handleClickReinvite(row.id)} className="Link">
                                                {t('clinicalWorkers.reinvite')}
                                            </Link>
                                        }
                                    </td>
                                ) : (
                                    <td>
                                        <ClinicalWorkerStatusSelect
                                            value={row.status}
                                            onStatusChange={handleStatusChange}
                                            disabled={row.id === currentUserId}
                                        />
                                    </td>
                                )}
                                <td className="large">
                                    {row.credentials ? (
                                        <TruncateText
                                            text={formatClinicalWorkerCredentials(row.credentials)}
                                            maximumTextWidth={maximumProviderCredentialsWidth}
                                        />
                                    ) : (
                                        <>{t('common.notAvailable')}</>
                                    )}
                                </td>
                                <td className="small">{row.npi || t('common.notAvailable')}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default ProvidersTable;
