import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import { useTranslation } from 'react-i18next';

import Select, { ISelectItem } from '../select/Select';

interface IPaginationProps {
    totalCount: number;
    rowRecordName: string;
    rowsPerPage: number;
    activePage: number;
    onChangePage: (activePage: number, rowsPerPage: number) => void;
}

const PAGES_DISPLAYED_MARGIN = 2;
const RANGE_DISPLAYED_PAGE = 5;

export const ROWS_PER_PAGE_OPTIONS = [
    {
        text: '15',
        value: 15,
        isEnabled: true,
    },
    {
        text: '30',
        value: 30,
        isEnabled: true,
    },
    {
        text: '60',
        value: 60,
        isEnabled: true,
    },
    {
        text: '90',
        value: 90,
        isEnabled: true,
    },
];

export const INITIAL_ROWS_PER_PAGE = ROWS_PER_PAGE_OPTIONS[0].value;

const Pagination = ({ totalCount, rowRecordName, rowsPerPage, activePage, onChangePage }: IPaginationProps) => {
    const { t } = useTranslation();
    // TOD: To replace state with method calculating value from the property.
    const [initialRowsPerPageOption, setInitialRowsPerPageOption] = useState<ISelectItem>();

    const handleChangePage = (event: { selected: number }) => {
        const newActivePage = event.selected;
        onChangePage(newActivePage, rowsPerPage);
    };

    const handleChangeRowsCount = (option: ISelectItem) => {
        const newRowsPerPage = Number(option.value);
        const newActivePage = 0;
        onChangePage(newActivePage, newRowsPerPage);
    };

    const calculateStartItem = () => {
        return activePage * rowsPerPage + 1;
    };

    const calculateEndItem = () => {
        return Math.min(rowsPerPage * (activePage + 1), totalCount);
    };

    const calculatePageCount = () => {
        return Math.ceil(totalCount / rowsPerPage);
    };

    useEffect(() => {
        const newInitialRowsPerPageOption = ROWS_PER_PAGE_OPTIONS.find((item) => item.value === rowsPerPage);
        setInitialRowsPerPageOption(newInitialRowsPerPageOption);
    }, [rowsPerPage]);

    return (
        <div className="pagination-wrapper">
            <ReactPaginate
                previousLabel={<ArrowBackIosSharpIcon />}
                nextLabel={<ArrowForwardIosSharpIcon />}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                pageCount={calculatePageCount()}
                marginPagesDisplayed={PAGES_DISPLAYED_MARGIN}
                pageRangeDisplayed={RANGE_DISPLAYED_PAGE}
                onPageChange={handleChangePage}
                containerClassName="pagination"
                activeClassName="active"
                forcePage={activePage}
            />
            <div className="pagination__info">
                <p>
                    {t('pagination.countOfItems', {
                        rowRecordName,
                        start: calculateStartItem(),
                        end: calculateEndItem(),
                        totalCount,
                    })}{' '}
                    | {t('pagination.rowsPerPage')}
                </p>
                {/* TODO: Remove custom Select and opt for MUI Select in the future. */}
                <Select
                    items={ROWS_PER_PAGE_OPTIONS}
                    onSelectionChange={handleChangeRowsCount}
                    inputClassName="pagination__select"
                    dropdownClassName="pagination__dropdown"
                    variant="small"
                    initialSelection={initialRowsPerPageOption}
                />
            </div>
        </div>
    );
};

export default Pagination;
