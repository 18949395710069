import { combineReducers, createStore } from 'redux';

import { appReducer } from './appStore/appReducers';

const rootReducer = combineReducers({
    appState: appReducer,
});

export const store = createStore(rootReducer);

export type RootStateType = ReturnType<typeof rootReducer>;
