import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material/styles';

const MuiLink: Components<Theme>['MuiLink'] = {
    styleOverrides: {
        root: ({ ownerState, theme }) => ({
            ...(ownerState.color === 'primary' && {
                ':hover': {
                    color: theme.palette.primary.dark,
                    textDecorationColor: null,
                },
            }),
        }),
    },
};

export default MuiLink;
