import { DesktopTimePicker } from '@mui/x-date-pickers';
import { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const defaultProps = {
    error: false,
    fullWidth: false,
    helperText: undefined,
    margin: 'none',
    placeholder: null,
};

interface ITimePickerProps {
    error?: boolean;
    fullWidth?: boolean;
    onChange(time: Moment | null): void;
    helperText?: string;
    label: string;
    margin?: 'dense' | 'normal' | 'none';
    placeholder?: string;
    value: Moment | null;
}

const TimePicker = ({
    error,
    fullWidth,
    onChange,
    helperText,
    label,
    margin,
    placeholder,
    value,
}: ITimePickerProps) => {
    const { t } = useTranslation();

    return (
        <DesktopTimePicker
            className="TextField"
            format="hh:mm A"
            label={value ? label : ''}
            onChange={onChange}
            slotProps={{
                desktopPaper: {
                    className: 'TimePicker',
                },
                openPickerButton: {
                    'aria-label': t('timePicker.changeTime'),
                },
                openPickerIcon: <AccessTimeIcon className="time-picker__icon" />,
                textField: {
                    error,
                    fullWidth,
                    helperText,
                    id: 'time-picker',
                    placeholder: placeholder ?? label,
                    margin,
                },
            }}
            value={value}
        />
    );
};

TimePicker.defaultProps = defaultProps;

export default TimePicker;
