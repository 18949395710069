import { renderTimeViewClock } from '@mui/x-date-pickers';

const MuiDesktopTimePicker = {
    defaultProps: {
        viewRenderers: {
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
        },
    },
};

export default MuiDesktopTimePicker;
