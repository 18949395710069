import { detect } from 'detect-browser';

import { getRuntimeConfig } from './envReader';

import { variables } from '../models/consts/variables';

interface IBrowserType {
    name: string;
    major: number;
    minor: number;
    osList: string[];
}

export const isBrowserSupported = (): boolean => {
    const browser = {
        name: '',
        os: '',
        version: '',
        ...detect(),
    };
    const browserName = `${browser.name}`.toLowerCase();
    const browserOs = `${browser.os}`.toLowerCase();
    const browserVersions = `${browser.version}.0.0`.split('.').map((versionFld) => Number(versionFld));
    const supportedBrowserData = getRuntimeConfig(variables.BROWSER_SUPPORT) ?? '[]';
    const supportedBrowserJson: IBrowserType[] = JSON.parse(
        supportedBrowserData.length > 0 ? supportedBrowserData : '[]'
    ).map(
        (entry: IBrowserType) =>
            ({
                ...entry,
                name: entry.name.toLowerCase(),
                osList: entry.osList.map((os: string) => os.toLowerCase()),
            } as IBrowserType)
    );

    return !!supportedBrowserJson.find(
        (entry) =>
            entry.name === browserName &&
            entry.osList?.find((os) => os === browserOs) &&
            (browserVersions[0] > entry.major ||
                (browserVersions[0] === entry.major && browserVersions[1] >= entry.minor))
    );
};
