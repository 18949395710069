import { ISummaryChartSettings } from 'src/models/summaryChart.model';

import { changeTimeInNumber } from './changeTimeInNumber';
import { combineEqualValues } from './combineEqualValues';

import { ITargetGlucoseAndCorrectAboveSettings } from '../models/report.models';

export function createTargetGlucoseStructure(targetGlucoseSettings: ITargetGlucoseAndCorrectAboveSettings[]) {
    const data: ISummaryChartSettings[] = [];

    combineEqualValues(targetGlucoseSettings, (key) => key.targetGlucose).forEach((item) => {
        data.push({
            x: changeTimeInNumber(item.startTime),
            duration: changeTimeInNumber(item.endTime, true) - changeTimeInNumber(item.startTime),
            value: `${item.targetGlucose}`,
        });
    });

    return data;
}
