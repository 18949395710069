export const ClinicalWorkerExistsInDifferentClinicError = 'ClinicalWorkerExistsInDifferentClinic';

export enum ClinicalWorkerExistsInSameClinicErrorCodes {
    ClinicalWorkerApproved = 'ClinicalWorkerApprovedInSameClinic',
    ClinicalWorkerDenied = 'ClinicalWorkerDeniedInSameClinic',
    ClinicalWorkerPending = 'ClinicalWorkerPendingInSameClinic',
    ClinicalWorkerInvited = 'ClinicalWorkerInvitationAlreadyExists',
}

export interface IClinicalWorkerInvitation {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}
