import { AccessToken } from '@okta/okta-auth-js';

import { isPauseItEnabled } from './featureToggles';

export const isPwdUserSession = (token: AccessToken): boolean => {
    if (isPauseItEnabled()) {
        return !!token.claims?.pwd;
    }

    // Note: for pauseIt off we need to check if token has customer id claim (customerId), if it does then the user is a PWD
    return !!token.claims.customerId;
};
