import createTheme, { ThemeOptions } from '@mui/material/styles/createTheme';
import type {} from '@mui/x-date-pickers/themeAugmentation';

import MuiDesktopTimePicker from './components/muiDesktopTimePicker';
import MuiLink from './components/muiLink';
import palette from './palette';
import spacing from './spacing';
import typography from './typography';

const themeOptions: ThemeOptions = {
    components: {
        MuiDesktopTimePicker,
        MuiLink,
    },
    palette,
    spacing,
    typography,
};

const theme = createTheme(themeOptions);

export default theme;
