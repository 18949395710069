import { useHistory, useLocation } from 'react-router';
import { useEffect, useState } from 'react';

import { INotification } from '../../models/app.models';
import AlertToast from '../alertToast/AlertToast';

const defaultProps = {
    applyHeaderOffset: false,
    applyHomeHeaderOffset: false,
    applyMaxWidth: false,
    applySidebarOffset: false,
    data: undefined,
};

interface INotificationProps {
    applyHeaderOffset?: boolean;
    applyHomeHeaderOffset?: boolean;
    applyMaxWidth?: boolean;
    applySidebarOffset?: boolean;
    data?: INotification;
}

const Notification = ({
    applyHeaderOffset,
    applyHomeHeaderOffset,
    applyMaxWidth,
    applySidebarOffset,
    data,
}: INotificationProps) => {
    const history = useHistory();
    const location = useLocation<{ notification: INotification } | null>();

    const [isOpen, setIsOpen] = useState(false);
    const [notification, setNotification] = useState<INotification | null>(null);

    useEffect(() => {
        if (notification != null) {
            setIsOpen(true);
        }
    }, [notification]);

    useEffect(() => {
        if (data) {
            setNotification(data);
        } else if (location.state?.notification) {
            setNotification(location.state.notification);
        }
    }, [data, location.state]);

    useEffect(() => {
        if (notification && location.state?.notification) {
            history.replace({ ...location, state: { ...location.state, notification: null } });
        }
    }, [notification, location, history]);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleOnTransitionExited = () => {
        setNotification(null);
    };

    return (
        <AlertToast
            applyHeaderOffset={applyHeaderOffset}
            applyHomeHeaderOffset={applyHomeHeaderOffset}
            applyMaxWidth={applyMaxWidth}
            applySidebarOffset={applySidebarOffset}
            data-testid="notification"
            message={notification?.message}
            onClose={handleClose}
            onTransitionExited={handleOnTransitionExited}
            open={isOpen}
            severity={notification?.severity}
            title={notification?.title}
        />
    );
};

Notification.defaultProps = defaultProps;

export default Notification;
