import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useDispatch } from 'react-redux';

import { setSignInUrl } from 'src/stores/appStore/appActions';

import LoginError from './LoginError';

import { sendAzureEvent } from '../../helpers/appInsights';
import { analyticsEventNames } from '../../models/consts/analytics';

const bemBlockName = 'login-callback';

const sendLoginAnalyticsEvent = (analyticsEventName: string) =>
    sendAzureEvent(analyticsEventName, { signInUrl: document.URL });

function LoginCallback() {
    const dispatch = useDispatch();
    const { oktaAuth, authState } = useOktaAuth();
    const [callbackError, setCallbackError] = useState(null);

    useEffect(() => {
        oktaAuth
            .handleLoginRedirect()
            .then(() => {
                sendLoginAnalyticsEvent(analyticsEventNames.SUCCESSFUL_LOGIN);
                dispatch(setSignInUrl(document.URL));
            })
            .catch((e) => {
                setCallbackError(e);
                sendLoginAnalyticsEvent(analyticsEventNames.FAILED_LOGIN);
                dispatch(setSignInUrl(document.URL));
            });
    }, [oktaAuth, dispatch]);

    useEffect(() => {
        if (authState?.error) {
            sendLoginAnalyticsEvent(analyticsEventNames.FAILED_LOGIN);
            dispatch(setSignInUrl(document.URL));
        }
    }, [authState?.error, dispatch]);

    const authError = authState?.error;
    const displayError = callbackError || authError;

    if (displayError) {
        return (
            <div className={bemBlockName}>
                <LoginError error={displayError} />
            </div>
        );
    }

    return null;
}

export default LoginCallback;
