import { TFunction } from 'i18next';

import { IPendingPatientsTableRows } from 'src/models/table.models';
import { SHORT_MONTH_DATE_FORMAT, formatDate } from 'src/helpers/formatDate';

interface IPendingPatients {
    birthdate: string;
    id: string;
    name: { first: string; last: string };
    requestedDate: string;
    mrn: string;
}

export function createPendingPatientsStructure(items: IPendingPatients[], t: TFunction) {
    const newItems: IPendingPatientsTableRows[] = [];
    items.forEach((item) => {
        const patientData = {
            id: item.id,
            firstName: item.name?.first ? item.name?.first : '',
            lastName: item.name?.last ? item.name?.last : '',
            mrn: item?.mrn ? String(item?.mrn) : ' ',
            dob: formatDate(item?.birthdate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
            requestedDate: formatDate(item?.requestedDate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
        };
        newItems.push(patientData);
    });
    return newItems;
}
