import { ISummaryChartSettings } from 'src/models/summaryChart.model';
import { ITargetGlucoseAndCorrectAboveSettings } from 'src/models/report.models';

import { changeTimeInNumber } from './changeTimeInNumber';
import { combineEqualValues } from './combineEqualValues';

export function createCorrectAboveStructure(correctAbove: ITargetGlucoseAndCorrectAboveSettings[]) {
    const data: ISummaryChartSettings[] = [];
    combineEqualValues(correctAbove, (key) => key.correctAbove).forEach((item) => {
        data.push({
            x: changeTimeInNumber(item.startTime),
            duration: changeTimeInNumber(item.endTime, true) - changeTimeInNumber(item.startTime),
            value: `${item.correctAbove}`,
        });
    });
    return data;
}
