import { Route } from 'react-router';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ApprovalStatus } from '../../models/app.models';
import { RootStateType } from '../../stores/store';
import clinicalWorkerPending from '../../assets/images/webImages/approval-pending.png';
import ErrorPage from '../errorPage/ErrorPage';

interface IUserProtectedRoute {
    component: React.FunctionComponent<any>;
    exact: boolean;
    path: string;
}

const UserProtectedRoute = ({ component: Component, exact, path }: IUserProtectedRoute) => {
    const { t } = useTranslation();
    const currentUser = useSelector((state: RootStateType) => state.appState.currentUser);

    return (
        <Route
            exact={exact}
            path={path}
            render={(routeProps) => {
                if (!currentUser?.role) {
                    return <ErrorPage>{t('errorPage.noRole')}</ErrorPage>;
                }

                if (currentUser?.approvalStatus === ApprovalStatus.Pending) {
                    return (
                        <ErrorPage
                            title={t('errorPage.clinicalWorkerPendingTitle')}
                            description={t('errorPage.clinicalWorkerPendingDescription')}
                            imageSrc={clinicalWorkerPending}
                            imageAlt={t('errorPage.clinicalWorkerPendingImageAlt')}
                        />
                    );
                }

                if (currentUser?.approvalStatus !== ApprovalStatus.Approved) {
                    return (
                        <ErrorPage
                            title={t('errorPage.clinicalWorkerNotApprovedTitle')}
                            description={t('errorPage.clinicalWorkerNotApprovedDescription')}
                        />
                    );
                }

                // eslint-disable-next-line react/jsx-props-no-spreading
                return <Component {...routeProps} />;
            }}
        />
    );
};

export default UserProtectedRoute;
