import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { Column } from '@material-table/core';

import { isEqual } from 'src/helpers/objectVerification';

import {
    ClinicalWorkerRole,
    IInviteClinicalWorker,
    IMaterialTableRowData,
    ISuperAdminsClinicalWorker,
} from '../../models/app.models';
import {
    addClinicalWorker,
    getClinicAdminsByClinicId,
    updateClinicalWorkerById,
} from '../../helpers/services/SuperAdmins';
import {
    emailTableColumn,
    firstNameTableColumn,
    lastNameTableColumn,
    statusTableColumn,
} from '../../helpers/superAdminManageAccountsBasicTableColumns';
import Loading from '../loading/Loading';
import MaterialTable from '../materialTable/MaterialTable';

const bemBlockName = 'clinic-admins';

type SuperAdminClinicalWorkerRowData = ISuperAdminsClinicalWorker & IMaterialTableRowData;

interface IClinicAdminsProps {
    handleClinicalWorkerSelectionChange: (id: string | null) => void;
    selectedClinicalWorkerId: string | null;
    clinicId: string;
}

const ClinicAdmins = ({
    handleClinicalWorkerSelectionChange,
    selectedClinicalWorkerId,
    clinicId,
}: IClinicAdminsProps) => {
    const { t } = useTranslation();
    const theme = useTheme();

    const [loading, setLoading] = useState(true);
    const [clinicAdmins, setClinicAdmins] = useState<SuperAdminClinicalWorkerRowData[]>([]);

    const loadClinicAdmins = async (id: string) => {
        const data = await getClinicAdminsByClinicId(id);

        setClinicAdmins(data.items);

        setLoading(false);
    };

    const saveNewClinicAdmin = async (data: ISuperAdminsClinicalWorker) => {
        setLoading(true);

        const newClinicAdmin: IInviteClinicalWorker = {
            email: data.email,
            name: {
                first: data.firstName,
                last: data.lastName,
            },
            type: ClinicalWorkerRole.ClinicAdmin,
            clinicId,
        };

        const savedAddedClinicAdmin = await addClinicalWorker(newClinicAdmin);

        if (savedAddedClinicAdmin) {
            setClinicAdmins([...clinicAdmins, savedAddedClinicAdmin]);
            setLoading(false);
        }
    };

    const updateClinicAdmin = async (updatedClinicAdmin: ISuperAdminsClinicalWorker) => {
        setLoading(true);

        const newUpdatedClinicAdmin = {
            email: updatedClinicAdmin.email,
            name: {
                first: updatedClinicAdmin.firstName,
                last: updatedClinicAdmin.lastName,
            },
            isActive: updatedClinicAdmin.isActive,
        };

        const savedUpdatedClinicAdmin = await updateClinicalWorkerById(updatedClinicAdmin.id, newUpdatedClinicAdmin);

        const updatedClinicAdminIndex = clinicAdmins.findIndex(
            (clinicAdmin) => updatedClinicAdmin.id === clinicAdmin.id
        );
        const newClinicAdmins: ISuperAdminsClinicalWorker[] = [...clinicAdmins];

        if (savedUpdatedClinicAdmin) {
            newClinicAdmins[updatedClinicAdminIndex] = savedUpdatedClinicAdmin;
            setClinicAdmins(newClinicAdmins);
            setLoading(false);
            if (!savedUpdatedClinicAdmin.isActive) handleClinicalWorkerSelectionChange(null);
        }
    };

    useEffect(() => {
        loadClinicAdmins(clinicId);
    }, [clinicId]);

    const handleOnRowAdd = async (newData: ISuperAdminsClinicalWorker) => {
        await saveNewClinicAdmin(newData);
    };

    const handleOnRowUpdate = async (
        updatedClinicAdmin: SuperAdminClinicalWorkerRowData,
        currentClinicAdmin?: SuperAdminClinicalWorkerRowData
    ) => {
        if (!currentClinicAdmin) {
            return;
        }

        const newUpdatedClinicAdmin = {
            ...updatedClinicAdmin,
        };
        const newCurrentClinicAdmin = {
            ...currentClinicAdmin,
        };
        delete newUpdatedClinicAdmin.tableData;
        delete newCurrentClinicAdmin.tableData;

        newUpdatedClinicAdmin.isActive = newUpdatedClinicAdmin.isActive.toString() === 'true';
        newCurrentClinicAdmin.isActive = newCurrentClinicAdmin.isActive.toString() === 'true';

        const wasClinicAdminUpdated = !isEqual(newUpdatedClinicAdmin, newCurrentClinicAdmin);

        if (wasClinicAdminUpdated) {
            await updateClinicAdmin(newUpdatedClinicAdmin);
        }
    };

    const columns: Column<ISuperAdminsClinicalWorker>[] = [
        {
            ...(lastNameTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
            field: 'lastName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            ...(firstNameTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
            field: 'firstName', // TODO: define 'field' in helper once all components are updated accordingly
        },
        {
            ...(emailTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'always',
        },
        {
            ...(statusTableColumn(t, theme) as Column<ISuperAdminsClinicalWorker>),
            editable: 'onUpdate',
        },
    ];

    // Diverging from current Loading pattern and opting to hide content to allow Material Table to continue updating its state.
    return (
        <>
            {loading && <Loading />}

            <div className={clsx({ hidden: loading })}>
                <div className={clsx(bemBlockName)} data-testid={bemBlockName}>
                    <MaterialTable
                        columns={columns}
                        data={clinicAdmins}
                        options={{ toolbar: true }}
                        editable={{
                            onRowAdd: handleOnRowAdd,
                            onRowUpdate: handleOnRowUpdate,
                        }}
                        singleRowSelection
                        selectedRowId={selectedClinicalWorkerId}
                        onSingleRowSelected={handleClinicalWorkerSelectionChange}
                    />
                </div>
            </div>
        </>
    );
};

export default ClinicAdmins;
