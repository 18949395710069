import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import Link from '@mui/material/Link';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { ITableColumns, IPatientsTableRows, PatientStatus } from 'src/models/table.models';

import InvitePatientMessage from './InvitePatientMessage';
import NoPatientsMessage from './NoPatientsMessage';

import Radio from '../radio/Radio';
import Button from '../button/Button';
import { PdmDeviceClasses } from '../../models/app.models';
import cable from '../../assets/images/cable.svg';
import showDeviceClass from '../../helpers/showDeviceClass';
import TruncateText from '../truncateText/TruncateText';

interface IPatientsTableProps {
    columns: ITableColumns[];
    rows: IPatientsTableRows[];
    activeRow?: string;
    onChoose: (id: string) => void;
    onUploadData: () => void;
    searchValue: string;
    onInviteLinkClick: () => void;
    onReinviteLinkClick: (patientId: string) => void;
}
const maximumPatientNameWidth = 260;

const PatientsTable = ({
    columns,
    rows,
    activeRow,
    onChoose,
    onUploadData,
    searchValue,
    onInviteLinkClick,
    onReinviteLinkClick,
}: IPatientsTableProps) => {
    const { t } = useTranslation();

    const handleChoose = (patientId: string) => {
        onChoose(patientId);
    };

    const handleOnClickUploadData = () => {
        onUploadData();
    };

    const handleClickReinvite = (patientId: string) => {
        onReinviteLinkClick(patientId);
    };

    return (
        <div className="table" data-testid="patientsTable">
            <table>
                <thead>
                    <tr>
                        {columns.map((item) => (
                            <td key={uuidv4()} className={`${item?.size ? item.size : ''}`}>
                                {item.sort ? <UnfoldMoreIcon /> : null} {t(item.name)}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows &&
                        rows.map((row, i) => {
                            let patientName: string;

                            if (!row.firstName && !row.lastName) {
                                patientName = t('common.noValue');
                            } else if (row.firstName && row.lastName) {
                                patientName = t('patientDirectory.nameFull', {
                                    first: row.firstName,
                                    last: row.lastName,
                                });
                            } else {
                                patientName = t('patientDirectory.name', {
                                    first: row.firstName,
                                    last: row.lastName,
                                });
                            }

                            return (
                                <tr key={uuidv4()} className={activeRow === row.id ? 'active' : ''}>
                                    <td className="large">
                                        <Radio
                                            activeId={activeRow}
                                            id={row.id}
                                            value={row.id}
                                            group="patients"
                                            dataTestId={`radio-${i}`}
                                            onChange={handleChoose}
                                        />

                                        <TruncateText text={patientName} maximumTextWidth={maximumPatientNameWidth} />
                                    </td>

                                    <td className="small">{row.dob}</td>
                                    <td className="small">
                                        {row.deviceClass ? `${t(showDeviceClass(row.deviceClass))}` : ''}
                                    </td>
                                    <td className="small">
                                        {row.status === PatientStatus.invited ? (
                                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                            <Link onClick={() => handleClickReinvite(row.id)} className="Link">
                                                {t('patientDirectory.reinvite')}
                                            </Link>
                                        ) : (
                                            row.lastDate || t('common.noValue')
                                        )}
                                    </td>
                                    {activeRow === row.id &&
                                        (row.deviceClass === PdmDeviceClasses.DASH ||
                                            row.deviceClass === PdmDeviceClasses.Eros) &&
                                        row.status === PatientStatus.active && (
                                            <td className="no-padding-cell">
                                                <Button
                                                    className="btn patientDirectory__upload-button"
                                                    dataTestId="patientsTableUploadDataButton"
                                                    text={t('patientDirectory.uploadButton')}
                                                    onClick={handleOnClickUploadData}
                                                    startIcon={
                                                        <img src={cable} alt={t('patientDirectory.altCableIcon')} />
                                                    }
                                                />
                                            </td>
                                        )}
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {!rows.length && !activeRow && !searchValue.length ? <NoPatientsMessage /> : null}

            {!rows.length && !activeRow && searchValue.length ? (
                <InvitePatientMessage onInviteLinkClick={onInviteLinkClick} />
            ) : null}
        </div>
    );
};

PatientsTable.defaultProps = {
    activeRow: '',
};

export default PatientsTable;
