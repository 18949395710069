import { Moment } from 'moment';

import { isDateSameOrBeforeCurrentDate } from './isDateSameOrBeforeCurrentDate';
import { isNotWhitespaceOnly } from './isNotWhitespaceOnly';
import { isValidEmail } from './isValidEmail';
import { isValidName } from './isValidName';

const getInputFieldError = <T>(
    value: T | null,
    validator: (value: T) => boolean,
    errorMessage: string,
    isSubmitted = false,
    noErrorBeforeSubmit = true
): string | undefined =>
    (!isSubmitted && noErrorBeforeSubmit) || (value && validator(value)) ? undefined : errorMessage;

export const getDateOfBirthAfterCurrentDateError = (value: Moment | null, errorMessage: string, currentDate: Moment) =>
    getInputFieldError(value, () => isDateSameOrBeforeCurrentDate(value, currentDate), errorMessage, false, false);

export const getInvalidNameFieldError = (
    value: string,
    errorMessage: string,
    isSubmitted: boolean,
    noErrorBeforeSubmit = true
) => getInputFieldError(value, isValidName, errorMessage, isSubmitted, noErrorBeforeSubmit);

export const getInvalidEmailError = (
    value: string,
    errorMessage: string,
    isSubmitted: boolean,
    noErrorBeforeSubmit = true
) => getInputFieldError(value, isValidEmail, errorMessage, isSubmitted, noErrorBeforeSubmit);

export const getNameFieldWhitespaceError = (value: string, errorMessage: string) =>
    getInputFieldError(value, isNotWhitespaceOnly, errorMessage, false, false);
