interface IMetricsData {
    insulinPump: {
        active: {
            percent: number;
        };
        podStatistics: {
            period: {
                average: {
                    time: {
                        hours: number;
                        days: number | null;
                    };
                };
            } | null;
        };
    };
}
// TODO: generalize createDeviceUseStructure and createDeviceUseStructureForDashEros.
export default (metrics: IMetricsData) => {
    const podWearTimeHours =
        metrics.insulinPump?.podStatistics?.period?.average && metrics.insulinPump?.podStatistics?.period?.average?.time
            ? metrics.insulinPump?.podStatistics.period.average.time.hours
            : null;
    const podWearTimeDays =
        metrics.insulinPump?.podStatistics?.period?.average && metrics.insulinPump?.podStatistics?.period?.average?.time
            ? metrics.insulinPump?.podStatistics.period.average.time.days
            : null;
    const data = [
        {
            title: 'deviceUse.activePodWearTime',
            value: metrics.insulinPump?.active && metrics.insulinPump?.active?.percent,
            units: '%',
        },
        {
            title: 'deviceUse.podWearTime',
            value: podWearTimeHours !== null ? Number(podWearTimeHours) : podWearTimeHours,
            units: 'deviceUse.metricFullHoursUnit',
            alternativeValue: podWearTimeDays,
            alternativeUnits: 'deviceUse.metricDaysUnit',
            isTranslateUnit: true,
            isTranslateAlternativeUnit: true,
        },
    ];

    return {
        data,
    };
};
