import { useTranslation } from 'react-i18next';

import { patientInviteBemBlockName } from './PatientInvite';
import MovePatientPromptModalInfoItem from './MovePatientPromptModalInfoItem';

import PromptModal from '../promptModal/promptModal';
import { ButtonColors } from '../../models/app.models';
import patientFound from '../../assets/images/patientFound.png';
import { IDetailedPatients } from '../../models/table.models';

interface IMovePatientPromptModalProps {
    isOpen: boolean;
    onClose: () => void;
    patient: IDetailedPatients;
    onConfirmMove: () => void;
}

const MovePatientPromptModal = ({ isOpen, onClose, onConfirmMove, patient }: IMovePatientPromptModalProps) => {
    const { t } = useTranslation();

    return (
        <PromptModal
            displayIcon={false}
            description={t('patientInvite.doYouWantToMovePatient')}
            isOpen={isOpen}
            okButtonLabel={t('patientInvite.movePatient')}
            onClose={onClose}
            onOkClick={onConfirmMove}
            title={t('patientInvite.patientFoundTitle')}
            okButtonColor={ButtonColors.Primary}
            headerImage={<img src={patientFound} className="modal__image" alt={t('patientInvite.patientFound')} />}
            className={`${patientInviteBemBlockName}__modal`}
        >
            <div className={`${patientInviteBemBlockName}__modal-info`}>
                <MovePatientPromptModalInfoItem
                    label={t('patientInvite.searchedPatient')}
                    value={
                        patient.firstName && patient.lastName
                            ? t('common.patientName', {
                                  first: patient.firstName,
                                  last: patient.lastName,
                              })
                            : `${t('patientInvite.name', {
                                  first: patient.firstName,
                                  last: patient.lastName,
                              })}` || t('common.noValue')
                    }
                />

                <MovePatientPromptModalInfoItem label={t('patientInvite.dateOfBirth')} value={patient.dob} />

                <MovePatientPromptModalInfoItem label={t('patientInvite.email')} value={patient.email} />

                <MovePatientPromptModalInfoItem label={t('patientInvite.foundInClinic')} value={patient.clinicName} />
            </div>
        </PromptModal>
    );
};

export default MovePatientPromptModal;
