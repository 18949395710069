import { useState } from 'react';
import Tab from '@mui/material/Tab';
import MuiTabs from '@mui/material/Tabs';

export interface ITabsElement {
    id: string;
    name: string;
    component: React.ReactNode;
}

interface ITabsProps {
    elements: ITabsElement[];
    onActiveTabChange: (id: string) => void;
}

const Tabs = ({ elements, onActiveTabChange }: ITabsProps) => {
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setActiveTabIndex(newValue);
        onActiveTabChange(elements[newValue].id);
    };

    return (
        <>
            <div>
                <MuiTabs value={activeTabIndex} onChange={handleChangeTab}>
                    {elements.map((element) => (
                        <Tab label={element.name} key={element.id} />
                    ))}
                </MuiTabs>
            </div>

            {elements.map(
                (element, index) =>
                    index === activeTabIndex && (
                        <div className="tab-content" key={element.id}>
                            {element.component}
                        </div>
                    )
            )}
        </>
    );
};

export default Tabs;
