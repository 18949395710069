import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { getPendingPatients, approvePatients, denyPatients } from 'src/helpers/services/PendingPatientsData';
import { ErrorPages, IPendingPatientsTableRows, RoutePath } from 'src/models/table.models';
import { analyticsEventNames } from 'src/models/consts/analytics';
import { sendAzureEvent } from 'src/helpers/appInsights';
import {
    setActivePage,
    setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval,
} from 'src/stores/appStore/appActions';
import { INotification, NotificationSeverity } from 'src/models/app.models';
import { StatusCode } from 'src/models/consts/statusCodes';
import { PatientsErrorCodes } from 'src/models/patient.models';

import Button from '../button/Button';
import Modal from '../modal/Modal';
import PendingPatientsTable from '../pendingPatientsTable/PendingPatientsTable';
import Loading from '../loading/Loading';
import Notification from '../notification/Notification';
import exclamationPointIcon from '../../assets/images/exclamationPointIcon.svg';

const tableHead = [
    {
        name: 'table.patientName',
        size: 'large',
    },
    {
        name: 'table.dateOfBirth',
        size: 'small',
    },
];

const PendingPatients = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [patients, setPatients] = useState<IPendingPatientsTableRows[]>([]);
    const [selectedPatientIds, setSelectedPatientIds] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [showDenyModal, setShowDenyModal] = useState(false);
    const [notification, setNotification] = useState<INotification | undefined>(undefined);

    const loadPendingPatients = useCallback(async () => {
        const patientsData = await getPendingPatients(t);

        setPatients(patientsData.items);
        setLoading(false);
    }, [t]);

    useEffect(() => {
        loadPendingPatients();
    }, [loadPendingPatients]);

    const handleChoosePatient = (patientId: string) => {
        sendAzureEvent(analyticsEventNames.CHOOSE_PENDING_PATIENTS);

        setSelectedPatientIds(() => {
            const newSetOfSelectedPatientIds = [...selectedPatientIds];
            const patientIdIndex = selectedPatientIds.indexOf(patientId);
            const shouldRemovePatientId = patientIdIndex !== -1;

            if (shouldRemovePatientId) {
                newSetOfSelectedPatientIds.splice(patientIdIndex, 1);
            } else {
                newSetOfSelectedPatientIds.push(patientId);
            }

            return newSetOfSelectedPatientIds;
        });
    };

    const handleRedirect = () => {
        sendAzureEvent(analyticsEventNames.REDIRECT_TO_PATIENTS);
        history.push(RoutePath.adminPatients);
    };

    const getFirstSelectedPatient = () => {
        return patients.find((patient) => patient.id === selectedPatientIds[0]);
    };

    const handleApproveOrDenyError = async (errStatus: StatusCode, errCode: string) => {
        if (
            errStatus === StatusCode.CONFLICT &&
            errCode === PatientsErrorCodes.PatientsClinicAssociationStatusChangeFailedForSome
        ) {
            setNotification({
                message: t('pendingPatients.somethingWentWrongNotification'),
                severity: NotificationSeverity.Warning,
            });
            setSelectedPatientIds([]);
            await loadPendingPatients();
            dispatch(setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval(true));
        } else {
            dispatch(setActivePage(ErrorPages.recall));
        }
    };

    const handleApproveSuccess = async () => {
        let message;
        const isSinglePatientSelected = selectedPatientIds.length === 1;

        if (isSinglePatientSelected) {
            const approvedPatient = getFirstSelectedPatient();

            message = t('approveOrDenyPatientsNotification.approvedSinglePatient', {
                first: approvedPatient?.firstName,
                last: approvedPatient?.lastName,
            });
        } else {
            message = t('approveOrDenyPatientsNotification.approvedMultiplePatients');
        }

        setNotification({
            message,
            severity: NotificationSeverity.Success,
        });

        setSelectedPatientIds([]);
        await loadPendingPatients();
        dispatch(setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval(true));
    };

    const handleApprove = async () => {
        setLoading(true);
        sendAzureEvent(analyticsEventNames.CLICK_APPROVE_PATIENTS);

        try {
            await approvePatients(selectedPatientIds);
        } catch (err: any) {
            handleApproveOrDenyError(err.response?.status, err.response?.data?.error?.code);
            return;
        }

        handleApproveSuccess();
    };

    const handleDenySuccess = async () => {
        let message;

        const isSinglePatientSelected = selectedPatientIds.length === 1;

        if (isSinglePatientSelected) {
            const deniedPatient = getFirstSelectedPatient();

            message = t('approveOrDenyPatientsNotification.deniedSinglePatient', {
                first: deniedPatient?.firstName,
                last: deniedPatient?.lastName,
            });
        } else {
            message = t('approveOrDenyPatientsNotification.deniedMultiplePatients');
        }

        setNotification({
            message,
            severity: NotificationSeverity.Success,
        });

        setSelectedPatientIds([]);
        await loadPendingPatients();
        dispatch(setShouldResetPendingPatientsAndClinicalWorkersCountsTimerInterval(true));
    };

    const handleDeny = async () => {
        setShowDenyModal(false);
        setLoading(true);
        sendAzureEvent(analyticsEventNames.CLICK_DENY_PATIENTS);

        try {
            await denyPatients(selectedPatientIds);
        } catch (err: any) {
            handleApproveOrDenyError(err.response?.status, err.response?.data?.error?.code);
            return;
        }

        handleDenySuccess();
    };

    return (
        <>
            <div className="main-content patientDirectory">
                <h1 className="main-content__title">{t('pendingPatients.title')}</h1>
                <p className="main-content__description">{t('pendingPatients.description')}</p>

                <div className="patientDirectory__search">
                    <div className="patientDirectory__search-right">
                        <div>
                            <Button
                                className={`btn btn_white ${!selectedPatientIds.length ? 'btn_disabled' : ''}`}
                                text={t('deny')}
                                dataTestId="denyPatient"
                                onClick={() => setShowDenyModal(true)}
                            />
                        </div>
                        <div>
                            <Button
                                className={`btn ${!selectedPatientIds.length ? 'btn_disabled' : ''}`}
                                text={t('approve')}
                                dataTestId="approvePatient"
                                onClick={handleApprove}
                            />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <Loading />
                ) : (
                    <div>
                        <PendingPatientsTable
                            columns={tableHead}
                            rows={patients}
                            activeRows={selectedPatientIds}
                            onChoose={handleChoosePatient}
                            onRedirect={handleRedirect}
                        />
                    </div>
                )}
            </div>
            {showDenyModal && (
                // TODO: move to a separate component - DenyPatientsPromptModal
                <Modal>
                    <>
                        <div
                            role="button"
                            aria-hidden="true"
                            className="modal__close"
                            data-testid="PromptModalClose"
                            onClick={() => setShowDenyModal(false)}
                        />
                        <img className="modal__iconExclamationPoint" src={exclamationPointIcon} alt="Error" />
                        <div className="modal__title" data-testid="denyModalTitle">
                            {selectedPatientIds.length > 1
                                ? t('denyModal.titlePatients')
                                : t('denyModal.titlePatient', {
                                      first: `${
                                          patients.filter((patient) => patient.id === selectedPatientIds[0])[0]
                                              ?.firstName
                                      }`,
                                      last: `${
                                          patients.filter((patient) => patient.id === selectedPatientIds[0])[0]
                                              ?.lastName
                                      }`,
                                  })}
                        </div>
                        {selectedPatientIds.length === 1 && (
                            <div className="modal__subtitle">
                                {t('denyModal.subtitleDob', {
                                    dob: `${
                                        patients.filter((patient) => patient.id === selectedPatientIds[0])[0]?.dob
                                    }`,
                                })}
                            </div>
                        )}
                        <div className="modal__descr-wrapper">
                            <div className="modal__descr">
                                {selectedPatientIds.length > 1
                                    ? t('denyModal.descrPatients')
                                    : t('denyModal.descrSinglePatient')}
                            </div>
                        </div>
                        <div className="modal__buttons">
                            <Button
                                className="btn btn_white"
                                text={t('denyModal.cancel')}
                                onClick={() => setShowDenyModal(false)}
                                dataTestId="cancelModal"
                            />
                            <Button
                                className="btn btn_warning"
                                text={t('denyModal.deny')}
                                onClick={handleDeny}
                                dataTestId="denyModal"
                            />
                        </div>
                    </>
                </Modal>
            )}
            <Notification applyHomeHeaderOffset applySidebarOffset data={notification} />
        </>
    );
};

export default PendingPatients;
