import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import Button from '../button/Button';

interface IHeaderProps {
    title: string;
    showBackButton?: boolean;
}

const Header = ({ title, showBackButton }: IHeaderProps) => {
    const history = useHistory();

    const handleOnClickBackButton = () => {
        history.goBack();
    };

    return (
        <div className="header">
            {showBackButton && (
                <Button
                    className="btn header__back-button"
                    dataTestId="header-back-button"
                    onClick={handleOnClickBackButton}
                    startIcon={<ArrowBackIcon />}
                />
            )}
            {title}
        </div>
    );
};

Header.defaultProps = {
    showBackButton: false,
};

export default Header;
