import { TFunction } from 'react-i18next';

import { PdmDeviceClasses } from '../models/app.models';

export const deviceClass = (t: TFunction, device: PdmDeviceClasses) => {
    let name = '';

    switch (device) {
        case PdmDeviceClasses.Omnipod5:
            name = t('PDMProduct.omnipod5');

            break;
        case PdmDeviceClasses.DASH:
            name = t('PDMProduct.dash');

            break;
        case PdmDeviceClasses.Eros:
            name = t('PDMProduct.eros');

            break;
        default:
    }

    return name;
};
