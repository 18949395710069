import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { analyticsEventNames } from 'src/models/consts/analytics';
import { IDetailedPatients, RoutePath, IPatientsTableRows } from 'src/models/table.models';
import {
    correctUploadedFileDataDates,
    saveUploadedFileData,
    getUploadMetadata,
} from 'src/helpers/services/SaveUploadData';
import { LOCAL_ISO_DATE_FORMAT } from 'src/models/consts/appConsts';
import { setPdmUploadFailCount } from 'src/stores/appStore/appActions';
import {
    UserRole,
    PdmPreviousActionState,
    PdmUploadState,
    ModalType,
    NotificationSeverity,
    INotification,
} from 'src/models/app.models';
import { RootStateType } from 'src/stores/store';
import { getPatientData } from 'src/helpers/services/Patients';
import { getStateAfterAction } from 'src/web-services/Upload.service';
import { UploadedFileMetadata } from 'src/models/uploader.models';
import { sendAzureEvent } from 'src/helpers/appInsights';
import { SELECTED_PATIENT_ID_PARAM_NAME } from 'src/models/consts/patientDirectoryRouteParams';

import { isPauseItEnabled } from '../../helpers/featureToggles';
import Button from '../button/Button';
import DatePicker from '../datePicker/DatePicker';
import TimePicker from '../timePicker/TimePicker';
import Loading from '../loading/Loading';
import FindDateInstructionsDash from '../findDateInstructionsDash/FindDateInstructionsDash';
import PatientInfo from '../patientInfo/PatientInfo';
import Header from '../header/Header';
import AlertModal from '../alertModal/alertModal';
import PromptModal from '../promptModal/promptModal';

// TODO: Use integer as semaphor, increase when starting loading, decrease when finished
// TODO: Read file size from metadata instead

const PATIENT_ID_PARAM_NAME = 'patientId';
const FILE_ID_PARAM_NAME = 'fileId';

const PdmUploaderDateChange = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation<{
        patient: IDetailedPatients | IPatientsTableRows;
        metadata: UploadedFileMetadata;
        fileSize: string;
    }>();
    const dispatch = useDispatch();
    const fileSize = location.state.fileSize ?? null;

    const [selectedDate, setSelectedDate] = useState<Moment | null>(null);
    const [selectedTime, setSelectedTime] = useState<Moment | null>(null);
    const [patient, setPatient] = useState<IDetailedPatients | IPatientsTableRows | null>(
        location.state.patient ?? null
    );
    const [loading, setLoading] = useState(false);
    const [invalidState, setInvalidState] = useState(false);
    const [metadata, setMetadata] = useState<UploadedFileMetadata | null>(location.state?.metadata ?? null);
    const [modalProps, setModalProps] = useState<{
        description: string;
        title: string;
        type: ModalType;
    } | null>(null);

    const fileId = new URLSearchParams(location.search).get(FILE_ID_PARAM_NAME);
    const searchParamsPatientId = new URLSearchParams(location.search).get(PATIENT_ID_PARAM_NAME);

    const isDisabled = !selectedDate || !selectedTime;

    const { pdmUploadFailCount, role: activeRole } = useSelector((state: RootStateType) => state.appState);

    const loadPatient = useCallback(
        async (patientId: string) => {
            const loadedPatient = await getPatientData(patientId, t);

            if (loadedPatient) {
                setPatient(loadedPatient);
            } else {
                setInvalidState(true);
            }
        },
        [t]
    );

    const loadMetadata = async (currentFileId: string) => {
        let loadedMetadata: UploadedFileMetadata | undefined;

        try {
            loadedMetadata = await getUploadMetadata(currentFileId);
        } catch {
            // do nothing, loadedMetadata stays undefined
        }

        if (loadedMetadata) {
            setMetadata(loadedMetadata);
        } else {
            setInvalidState(true);
        }
    };

    const handleDateChange = (date: Moment | null) => setSelectedDate(date);
    const handleTimeChange = (time: Moment | null) => setSelectedTime(time);

    const sendSuccessUploadAnalyticsEvent = () => {
        if (patient && metadata)
            sendAzureEvent(analyticsEventNames.SUCCESS_DASH_PDM_DATA_UPLOAD, {
                fileSize,
                dataDays: String(metadata.data.availableDays),
                deviceType: patient.deviceClass,
            });
    };

    const redirectToPatientsDirectory = useCallback(
        (patientId?: string, notification?: INotification) => {
            const path = activeRole === UserRole.Admin ? RoutePath.adminPatients : RoutePath.patients;
            history.replace({
                pathname: path,
                ...(patientId && { search: `${SELECTED_PATIENT_ID_PARAM_NAME}=${patientId}` }),
                ...(notification && { state: { notification } }),
            });
        },
        [activeRole, history]
    );

    const onModalTryAgain = () => {
        setModalProps(null);
        if (!patient) {
            setInvalidState(true);
            return;
        }

        history.replace(
            {
                pathname: RoutePath.pdmUploader,
                search: `?patientId=${patient.id}`,
            },
            { patient }
        );
    };

    const onModalCancel = () => {
        setInvalidState(true);
    };

    const sendUnsuccessfulProcessingOutcomeAnalyticsEvent = (
        analyticsEventName: string,
        processingOutcome: PdmUploadState
    ) => {
        let cause = 'unknown';

        if (processingOutcome === PdmUploadState.Confirmed) cause = 'loading data soft failure';
        if (processingOutcome === PdmUploadState.LoadingFailed) cause = 'loading data hard failure';

        if (fileSize && patient)
            sendAzureEvent(analyticsEventName, {
                fileSize,
                deviceType: patient.deviceClass,
                cause,
            });
    };

    const checkLoadingOutcome = async (currentFileId: string) => {
        const response = await getStateAfterAction(currentFileId, PdmPreviousActionState.Load);

        const parseState = response.data?.data?.item.state;

        switch (parseState) {
            case PdmUploadState.Confirmed:
            case PdmUploadState.LoadingFailed: {
                setLoading(false);
                const newPdmUploadFailCount = pdmUploadFailCount + 1;

                dispatch(setPdmUploadFailCount(newPdmUploadFailCount));

                if (newPdmUploadFailCount > 1) {
                    sendUnsuccessfulProcessingOutcomeAnalyticsEvent(
                        analyticsEventNames.FAILURE_NOT_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_PDM_DATA_UPLOAD,
                        parseState
                    );
                    setModalProps({
                        description: t(
                            `pdmUploader.${
                                isPauseItEnabled() ? 'cannotProcessDescriptionPauseIt' : 'cannotProcessDescription'
                            }`
                        ),
                        title: t(
                            `pdmUploader.${isPauseItEnabled() ? 'cannotProcessTitlePauseIt' : 'cannotProcessTitle'}`
                        ),
                        type: ModalType.Prompt,
                    });
                } else {
                    sendUnsuccessfulProcessingOutcomeAnalyticsEvent(
                        analyticsEventNames.FAILURE_FIRST_IN_SESSION_UNSUCCESSFUL_PROCESSING_OUTCOME_DASH_PDM_DATA_UPLOAD,
                        parseState
                    );
                    setModalProps({
                        description: t('pdmUploader.firstTimeCannotProcessDescription'),
                        title: t('pdmUploader.firstTimeCannotParseTitle'),
                        type: ModalType.Alert,
                    });
                }
                break;
            }
            case PdmUploadState.Loaded: {
                if (!patient || !metadata) {
                    setInvalidState(true);
                } else {
                    sendSuccessUploadAnalyticsEvent();
                    const notification = {
                        severity: NotificationSeverity.Success,
                        message: t('pdmUploader.successfulUploadMessage', {
                            first: patient.firstName,
                            last: patient.lastName,
                            days: metadata.data.availableDays,
                        }),
                    };
                    redirectToPatientsDirectory(patient.id, notification);
                }
                break;
            }
            default:
        }
    };

    const initiateLoading = async (currentFileId: string) => {
        await saveUploadedFileData(currentFileId);
        checkLoadingOutcome(currentFileId);
    };

    const sendDateAndTimeCorrectionAnalyticsEvent = (analyticsEventName: string) => {
        if (!patient) {
            return;
        }

        sendAzureEvent(analyticsEventName, {
            deviceType: patient.deviceClass,
        });
    };

    const handleOnClickSkipButton = () => {
        setLoading(true);
        if (!fileId) {
            setInvalidState(true);
            return;
        }

        sendDateAndTimeCorrectionAnalyticsEvent(
            analyticsEventNames.CLICK_SKIP_DASH_PDM_DATA_UPLOAD_DATE_AND_TIME_CORRECTION
        );

        initiateLoading(fileId);
    };

    const handleOnClickChangeDate = async () => {
        if (selectedDate != null && selectedTime != null) {
            setLoading(true);
            const currentDate = moment().format(LOCAL_ISO_DATE_FORMAT);
            const enteredCurrentDeviceDate = selectedDate
                .hours(selectedTime.hours())
                .minutes(selectedTime.minutes())
                .format(LOCAL_ISO_DATE_FORMAT);

            if (!fileId || !patient) {
                setInvalidState(true);
                return;
            }

            sendDateAndTimeCorrectionAnalyticsEvent(
                analyticsEventNames.CLICK_UPDATE_DASH_PDM_DATA_UPLOAD_DATE_AND_TIME_CORRECTION
            );

            await correctUploadedFileDataDates(fileId, currentDate, enteredCurrentDeviceDate);

            await loadMetadata(fileId);

            initiateLoading(fileId);
        }
    };

    const handleFindDateInstructionsDashOpen = () =>
        sendDateAndTimeCorrectionAnalyticsEvent(analyticsEventNames.CLICK_VIEW_DASH_FIND_DATE_AND_TIME_INSTRUCTIONS);

    useEffect(() => {
        if (invalidState) {
            redirectToPatientsDirectory();
        }
    }, [invalidState, redirectToPatientsDirectory]);

    useEffect(() => {
        if (patient || invalidState) {
            return;
        }

        if (searchParamsPatientId) {
            loadPatient(searchParamsPatientId);
            return;
        }

        setInvalidState(true);
    }, [patient, searchParamsPatientId, loadPatient, invalidState]);

    useEffect(() => {
        if (metadata) {
            return;
        }

        if (fileId) {
            loadMetadata(fileId);
            return;
        }

        setInvalidState(true);
    }, [metadata, fileId]);

    useEffect(() => {
        if (!fileId) {
            setInvalidState(true);
        }
    }, [fileId]);

    if (loading || !patient || !metadata) return <Loading className="main-content" />;

    return (
        <div className="pdm-uploader-date-change">
            <Header title={t('pdmUploaderDateChange.header')} />
            <div className="main-content pdm-uploader-date-change__columns">
                <div className="pdm-uploader-date-change__patient-column">
                    {patient && (
                        <PatientInfo firstName={patient.firstName} lastName={patient.lastName} dob={patient.dob} />
                    )}
                </div>
                <div className="pdm-uploader-date-change__content-column">
                    <h1 className="main-content__title">{t('pdmUploaderDateChange.title')}</h1>
                    <p className="main-content__description pdm-uploader-date-change__subtitle">
                        {t('pdmUploaderDateChange.subtitle')}
                    </p>
                    <div className="pdm-uploader-date-change__date-time-container">
                        <div className="pdm-uploader-date-change__picker-container">
                            <DatePicker
                                fullWidth
                                label={t('pdmUploaderDateChange.datePickerLabel')}
                                margin="normal"
                                onChange={handleDateChange}
                                value={selectedDate}
                            />
                        </div>
                        <div className="pdm-uploader-date-change__picker-container">
                            <TimePicker
                                fullWidth
                                label={t('pdmUploaderDateChange.timePickerLabel')}
                                margin="normal"
                                onChange={handleTimeChange}
                                value={selectedTime}
                            />
                        </div>
                    </div>
                    <div className="pdm-uploader-date-change__instructions">
                        {t('pdmUploaderDateChange.instructions')}
                        <FindDateInstructionsDash onOpen={handleFindDateInstructionsDashOpen} />
                    </div>
                    <div>
                        <Button
                            className="btn btn_white pdm-uploader-date-change__skip-button"
                            text={t('pdmUploaderDateChange.skipButton')}
                            dataTestId="pdm-date-change-skip-button"
                            onClick={handleOnClickSkipButton}
                        />
                        <Button
                            className="btn pdm-uploader-date-change__correct-button"
                            text={t('pdmUploaderDateChange.correctButton')}
                            dataTestId="pdm-date-change-correct-button"
                            disabled={isDisabled}
                            onClick={handleOnClickChangeDate}
                        />
                    </div>
                </div>
            </div>
            {modalProps?.type === ModalType.Alert && (
                <AlertModal
                    description={modalProps.description}
                    isOpen
                    okButtonLabel={t('errorModal.tryAgain')}
                    onClose={onModalTryAgain}
                    title={modalProps.title}
                />
            )}

            {modalProps?.type === ModalType.Prompt && (
                <PromptModal
                    description={modalProps.description}
                    isOpen
                    okButtonLabel={t('errorModal.tryAgain')}
                    onClose={onModalCancel}
                    onOkClick={onModalTryAgain}
                    title={modalProps.title}
                />
            )}
        </div>
    );
};

export default PdmUploaderDateChange;
