import { IPartsOfDayTotal } from 'src/models/summaryChart.model';

interface IBolusStatisticalDayData {
    startTime: string;
    endTime: string;
    partOfDay: string;
    statistics: {
        averageDaily: {
            volume: number;
            count: number;
        };
    };
}

interface IBolusStatisticalDay {
    data: IBolusStatisticalDayData[];
    total: IPartsOfDayTotal;
}

export function createBolus(count: number) {
    // 0.2 -> .2; 1.2 -> 1.2
    // let str = '';
    // if (count < 1) {
    //     str = count.toString().replace(/^0+/, '');
    // } else {
    //     str = count.toString();
    // }
    // return str;
    return `${count}`;
}

export function createPartsOfDay(bolusStatisticalDay: IBolusStatisticalDay) {
    const { data, total } = bolusStatisticalDay;

    return {
        nighttime: {
            patientAverageDailyBolusVolume: createBolus(data[0].statistics.averageDaily.volume),
            patientBolusCount: createBolus(data[0].statistics.averageDaily.count),
        },
        morning: {
            patientAverageDailyBolusVolume: createBolus(data[1].statistics.averageDaily.volume),
            patientBolusCount: createBolus(data[1].statistics.averageDaily.count),
        },
        afternoon: {
            patientAverageDailyBolusVolume: createBolus(data[2].statistics.averageDaily.volume),
            patientBolusCount: createBolus(data[2].statistics.averageDaily.count),
        },
        evening: {
            patientAverageDailyBolusVolume: createBolus(data[3].statistics.averageDaily.volume),
            patientBolusCount: createBolus(data[3].statistics.averageDaily.count),
        },
        total,
    };
}

export function createEmptyPartsOfDay() {
    return {
        nighttime: {
            patientAverageDailyBolusVolume: '',
            patientBolusCount: '',
        },
        morning: {
            patientAverageDailyBolusVolume: '',
            patientBolusCount: '',
        },
        afternoon: {
            patientAverageDailyBolusVolume: '',
            patientBolusCount: '',
        },
        evening: {
            patientAverageDailyBolusVolume: '',
            patientBolusCount: '',
        },
        total: {
            statistics: {
                averageDaily: {
                    volume: 0,
                    count: 0,
                },
            },
        },
    };
}
