import {
    approvePendingClinicalWorkersData,
    denyPendingClinicalWorkersData,
    getPendingClinicalWorkersData,
} from 'src/web-services/Admin.service';

import { createPendingProvidersStructure } from '../createPendingProvidersStructure';

export async function getPendingClinicalWorkers() {
    const res = await getPendingClinicalWorkersData();
    const { items, totalCount } = res?.data || { items: null };

    return {
        items: items?.length ? createPendingProvidersStructure(items) : [],
        totalCount: totalCount || 0,
    };
}

export async function approveClinicalWorkers(clinicalWorkersId: string[]) {
    await approvePendingClinicalWorkersData(
        clinicalWorkersId.map((id) => ({
            id,
        }))
    );
}

export async function denyClinicalWorkers(clinicalWorkersId: string[]) {
    await denyPendingClinicalWorkersData(
        clinicalWorkersId.map((id) => ({
            id,
        }))
    );
}
