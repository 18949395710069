import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import { IPatientInviteFormValues } from './patientInviteForm';

import { getInvalidEmailError } from '../../helpers/formFieldValidation';

export interface IPatientInviteFormEmailValue {
    patientsEmail: string;
}

interface IPatientsEmailControllerProps {
    control: Control<IPatientInviteFormValues>;
    isSubmitted: boolean;
}

const patientsEmailDefaultValue = '';
const patientsEmailMaxLength = 80;
const patientsEmailName = 'patientsEmail';

const PatientsEmailController = ({ control, isSubmitted }: IPatientsEmailControllerProps) => {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            defaultValue={patientsEmailDefaultValue}
            name={patientsEmailName}
            render={({ field, fieldState, formState }) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="TextField"
                    error={!!formState.errors.patientsEmail}
                    fullWidth
                    helperText={fieldState.error?.message}
                    inputProps={{
                        'data-testid': patientsEmailName,
                        maxLength: patientsEmailMaxLength,
                    }}
                    label={t('patientInvite.patientsEmail')}
                    variant="outlined"
                    inputRef={field.ref}
                    type="email"
                />
            )}
            rules={{
                required: t<string>('patientInvite.enterPatientsEmail'),
                validate: (value) => getInvalidEmailError(value, t('patientInvite.invalidEmail'), isSubmitted, false),
            }}
        />
    );
};

export default PatientsEmailController;
