import { TypographyOptions } from '@mui/material/styles/createTypography';

const defaultFontFamily = ['IBMPlexSans', 'sans-serif'].join(',');
export const secondaryFontFamily = ['OpenSans', 'sans-serif'].join(',');

// These values are defined in ./src/styles/_variables.scss and ./src/styles/common.scss.
// When updating, please do in parallel.
const typography: TypographyOptions = {
    fontFamily: defaultFontFamily,
    fontWeightBold: 600,
    fontWeightMedium: 500,
    fontWeightRegular: 'normal',
    htmlFontSize: 10,
};

export default typography;
