import { PdmDeviceClasses } from './app.models';

export enum PatientExistErrorCodes {
    PatientExistsInDifferentClinic = 'PatientExistsInDifferentClinic',
    PatientExistsInSameClinic = 'PatientExistsInSameClinic',
    PatientApprovedInSameClinic = 'PatientApprovedInSameClinic',
    PatientDeniedInSameClinic = 'PatientDeniedInSameClinic',
    PatientPendingInSameClinic = 'PatientPendingInSameClinic',
    PatientInvitedToSameClinic = 'PatientInvitedToSameClinic',
}

export interface IPatientInvitation {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    deviceClass: PdmDeviceClasses;
    email: string;
}
