import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';

import { sendAzureEvent } from 'src/helpers/appInsights';
import { createPwdPublicPagesPath } from 'src/helpers/createPath';
import { analyticsEventNames } from 'src/models/consts/analytics';
import { variables } from 'src/models/consts/variables';
import { getRuntimeConfig } from 'src/helpers/envReader';
import { isPauseItEnabled } from 'src/helpers/featureToggles';

import { ReactComponent as Address } from '../../assets/images/Regulatory-Address.svg';

const About = () => {
    const { t } = useTranslation();

    return (
        <div className="main-content resources">
            <h1 className="main-content__title">{t('about.title')}</h1>

            <div className="resources__wrapper">
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <p className="resources__black">{t('about.omnipodDiscover')}</p>
                                    <p className="resources__black">{t('about.dataManagementSoftware')}</p>
                                    <h3 className="resources__title_reduced_bottom_margin resources__title_about-version">
                                        {t('about.version')}
                                    </h3>
                                    <p className="resources__descr_no_top_margin">
                                        {getRuntimeConfig(variables.VERSION) ?? '--'}
                                    </p>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <h3 className="resources__title_reduced_bottom_margin">{t('about.legal')}</h3>
                                    <a
                                        className="resources__link"
                                        target="_blank"
                                        href={createPwdPublicPagesPath('docs/legal.html')}
                                        rel="noopener noreferrer"
                                        data-testid="eula"
                                        onClick={() => sendAzureEvent(analyticsEventNames.VIEW_EULA)}
                                    >
                                        {t('about.eulaInformation')}
                                    </a>
                                    <div className="resources__margin-top" />
                                    <a
                                        className="resources__link"
                                        target="_blank"
                                        href={createPwdPublicPagesPath('docs/cookies.html')}
                                        rel="noopener noreferrer"
                                        data-testid="cookie"
                                        onClick={() => sendAzureEvent(analyticsEventNames.VIEW_COOKIE_POLICY)}
                                    >
                                        {t('about.cookiePolicy')}
                                    </a>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} spacing={4}>
                        <Grid container spacing={4}>
                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <h3 className="resources__title_reduced_bottom_margin">
                                        {t('about.deviceIdentifier')}
                                    </h3>
                                    <p className="resources__descr_no_top_margin">
                                        {`(01)${getRuntimeConfig(variables.DEVICE_ID) || ' -- '}(10)${
                                            getRuntimeConfig(variables.VERSION) || ' -- '
                                        }`}
                                    </p>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="resources__block">
                                    <Grid container spacing={1}>
                                        <Grid item>
                                            <Address />
                                        </Grid>

                                        <Grid item>
                                            <p>{t('about.insulet')}</p>
                                            <p>{t('about.address')}</p>
                                            {!isPauseItEnabled() && (
                                                <>
                                                    <p>{t('about.phone')}</p>
                                                    <p>{t('about.phoneTwo')}</p>
                                                </>
                                            )}
                                            <p>{t('about.website')}</p>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default About;
