import { ITimePoint } from 'src/models/report.models';

export const combineEqualValues = <T extends ITimePoint>(
    timePoints: T[],
    getComparableValue: (key: T) => T[keyof T]
) => {
    if (!timePoints.length) return timePoints;

    let accumulator: T[] = [timePoints[0]];

    for (let index = 1; index < timePoints.length; index += 1) {
        const lastAccumulatedValue = accumulator[accumulator.length - 1];
        if (getComparableValue(lastAccumulatedValue) === getComparableValue(timePoints[index])) {
            const accumulatedValues = accumulator.slice(0, accumulator.length - 1);
            accumulator = [...accumulatedValues, { ...lastAccumulatedValue, endTime: timePoints[index].endTime }];
        } else accumulator.push(timePoints[index]);
    }

    return accumulator;
};
