import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import TextField from '@mui/material/TextField';

import { IClinicalWorkerInviteFormValues } from './clinicalWorkerInviteFormValues';

import { getInvalidNameFieldError, getNameFieldWhitespaceError } from '../../helpers/formFieldValidation';

export interface IClinicalWorkerInviteFormFirstNameValue {
    clinicalWorkersFirstName: string;
}

interface IClinicalWorkersFirstNameControllerProps {
    control: Control<IClinicalWorkerInviteFormValues>;
    isSubmitted: boolean;
}

const clinicalWorkersFirstNameDefaultValue = '';
const clinicalWorkersFirstNameMaxLength = 40;
const clinicalWorkersFirstNameName = 'clinicalWorkersFirstName';

const ClinicalWorkersFirstNameController = ({ control, isSubmitted }: IClinicalWorkersFirstNameControllerProps) => {
    const { t } = useTranslation();

    return (
        <Controller
            control={control}
            defaultValue={clinicalWorkersFirstNameDefaultValue}
            name={clinicalWorkersFirstNameName}
            render={({ field, fieldState, formState }) => (
                <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    className="TextField"
                    error={!!formState.errors.clinicalWorkersFirstName}
                    fullWidth
                    helperText={fieldState.error?.message}
                    inputProps={{
                        'data-testid': clinicalWorkersFirstNameName,
                        maxLength: clinicalWorkersFirstNameMaxLength,
                    }}
                    label={t('clinicalWorkerInvite.firstNameLabel')}
                    variant="outlined"
                />
            )}
            rules={{
                required: t<string>('clinicalWorkerInvite.requiredFirstNameError'),
                validate: {
                    default: (value) =>
                        getInvalidNameFieldError(
                            value,
                            t('clinicalWorkerInvite.invalidCharactersNameError'),
                            isSubmitted,
                            false
                        ),
                    whitespace: (value) =>
                        getNameFieldWhitespaceError(value, t('clinicalWorkerInvite.requiredFirstNameError')),
                },
            }}
        />
    );
};

export default ClinicalWorkersFirstNameController;
