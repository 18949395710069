import { TFunction } from 'i18next';

import { SHORT_MONTH_DATE_FORMAT, formatDate, formatDateWithTimezone } from './formatDate';

import { IPatientsTableRows, PatientSource, PatientStatus } from '../models/table.models';
import { PdmDeviceClasses } from '../models/app.models';

interface IDetailedPatientsData {
    birthdate: string;
    clinic: {
        id: string;
        name: string;
    };
    deviceClass: PdmDeviceClasses;
    email: string;
    id: string;
    name: { first: string; last: string };
    physician: {
        id: string;
    };
    status: PatientStatus;
}

interface IPatientInvitationData {
    birthdate: string;
    deviceClass: PdmDeviceClasses;
    email: string;
    id: string;
    name: { first: string; last: string };
}

interface IListPatientsData {
    birthdate: string;
    clinic?: {
        id: string;
        name?: string;
    };
    deviceClass?: PdmDeviceClasses;
    email?: string;
    id: string;
    name: { first: string; last: string };
    physician?: {
        id: string;
    };
    status: PatientStatus;
    source: PatientSource;
}

interface IListPatientsDataWithDeviceStatistics {
    id: string;
    firstName: string;
    lastName: string;
    dob: string;
    hasDeviceSettings: boolean;
    deviceData: {
        available: { days: number };
        last: { date: string };
        uploaded: { last: { date: string } } | null;
    } | null;
    deviceClass: string;
    status: PatientStatus;
    source: PatientSource;
}

export function createPatientStructure(item: IListPatientsData, t: TFunction) {
    return {
        id: item.id,
        firstName: item.name?.first ? item.name?.first : '',
        lastName: item.name?.last ? item.name?.last : '',
        dob: formatDate(item?.birthdate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
        status: item.status,
        source: item.source,
        deviceClass: item.deviceClass,
    };
}

export function createPatientInvitationStructure(item: IPatientInvitationData, t: TFunction) {
    return {
        id: item.id,
        firstName: item.name?.first ? item.name?.first : '',
        lastName: item.name?.last ? item.name?.last : '',
        dob: formatDate(item?.birthdate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
        deviceClass: item.deviceClass,
        email: item.email ?? '',
    }; // TODO: Move common.noValue out of this level to the component level
}

export function createPatientDetailedStructure(item: IDetailedPatientsData, t: TFunction) {
    return {
        id: item.id,
        firstName: item.name?.first ? item.name?.first : '',
        lastName: item.name?.last ? item.name?.last : '',
        dob: formatDate(item?.birthdate, SHORT_MONTH_DATE_FORMAT, t('common.noValue')),
        status: item.status,
        ...(item.clinic && { clinicId: item.clinic.id }),
        clinicName: item.clinic?.name ?? '',
        deviceClass: item.deviceClass,
        email: item.email ?? '',
        ...(item.physician && { physicianId: item.physician.id }),
    };
}

export function createPatientsStructure(items: IListPatientsData[], t: TFunction) {
    return items.map((item) => createPatientStructure(item, t));
}

export function createPatientsFullStructure(patients: IListPatientsDataWithDeviceStatistics[]): IPatientsTableRows[] {
    return patients.map((patient) => ({
        id: patient.id,
        firstName: patient.firstName,
        lastName: patient.lastName,
        dob: patient.dob,
        days: patient.deviceData?.available?.days ?? 0,
        hasDeviceSettings: patient.hasDeviceSettings,
        lastDate: formatDateWithTimezone(patient.deviceData?.last?.date, SHORT_MONTH_DATE_FORMAT, ''),
        lastUploaded: patient.deviceData?.uploaded?.last?.date ?? '',
        deviceClass: patient.deviceClass,
        status: patient.status,
        source: patient.source,
    }));
}
