import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Dispatch } from 'redux';

import { ErrorPages } from 'src/models/table.models';
import { AgreementStatus, AxiosRequestConfigWithRetry } from 'src/models/app.models';
import { setActivePage, setAgreementStatus } from 'src/stores/appStore/appActions';

export const handleError = (dispatch: Dispatch<any>, err: Error | AxiosError | unknown) => {
    if (axios.isAxiosError(err)) {
        if (err.message === 'Network Error') dispatch(setActivePage(ErrorPages.recall));
        else if (err.response?.data) {
            switch (err.response.data.error?.code) {
                case 'ClinicalWorkerEulaNotAccepted':
                    dispatch(setAgreementStatus(AgreementStatus.EULA));
                    break;
                case 'ClinicalWorkerNotFound':
                    dispatch(setActivePage(ErrorPages.clinicalWorkerNotFound));
                    break;
                case 'ClinicalWorkerNotApproved':
                    dispatch(setActivePage(ErrorPages.clinicalWorkerNotApproved));
                    break;
                case 'PatientsClinicAssociationStatusChangeFailed':
                    dispatch(setActivePage(ErrorPages.patientsClinicAssociationStatusChangeFailed));
                    break;
                case 'PatientsClinicAssociationStatusChangePartiallyFailed':
                    dispatch(setActivePage(ErrorPages.patientsClinicAssociationStatusChangePartiallyFailed));
                    break;

                default:
                    dispatch(setActivePage(ErrorPages.recall));
                    break;
            }
        } else dispatch(setActivePage(ErrorPages.recall));
    }
};

export const retryErrorHandler = (error: AxiosError) => {
    const config = error.config as AxiosRequestConfigWithRetry;
    if (config.retryErrorHandler && Number(config.retry) >= 0) {
        config.retryErrorHandler(
            error.response as AxiosResponse,
            config.retry !== 0
                ? async () => {
                      const newConfig = {
                          ...config,
                          retry: Number(config.retry) - 1,
                      } as AxiosRequestConfig;

                      await axios.request(newConfig);
                      return axios(newConfig);
                  }
                : undefined
        );
    }
};
