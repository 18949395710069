import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { IPendingProvidersTableRows, ITableColumns } from 'src/models/table.models';
import { formatClinicalWorkerCredentials } from 'src/helpers/formatClinicalWorkerCredentials';

import CheckBox from '../checkBox/CheckBox';
import noProvidersImage from '../../assets/images/webImages/noPatients.png';
import Button from '../button/Button';
import TruncateText from '../truncateText/TruncateText';

const maximumProviderNameWidth = 260;
const maximumProviderEmailWidth = 300;
const maximumProviderCredentialsWidth = 300;

interface IPendingProvidersTableProps {
    columns: ITableColumns[];
    rows: IPendingProvidersTableRows[];
    activeRows?: string[];
    onChoose: (id: string) => void;
    onRedirect: () => void;
}

const PendingProvidersTable = ({ columns, rows, activeRows, onChoose, onRedirect }: IPendingProvidersTableProps) => {
    const { t } = useTranslation();
    const handleChoose = (patientId: string) => {
        onChoose(patientId);
    };

    const showEmptyContent = () => {
        return (
            <div className="tableEmptyContent">
                <img src={noProvidersImage} alt={t('pendingProviders.altNoProviders')} />
                <h2 className="tableEmptyContent__title">{t('pendingProviders.emptyTitle')}</h2>
                <p className="tableEmptyContent__descr">{t('pendingProviders.emptyDescr')}</p>
                <Button
                    text={t('pendingProviders.redirectPendingProviders')}
                    onClick={() => onRedirect()}
                    dataTestId="redirectApprovedProviders"
                />
            </div>
        );
    };

    return (
        <div className="table">
            <table>
                <thead>
                    <tr>
                        {columns.map((item) => (
                            <td key={uuidv4()} className={`${item?.size ? item.size : ''}`}>
                                {item.sort ? <UnfoldMoreIcon /> : null} {t(item.name)}
                            </td>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows &&
                        rows.map((row, i) => {
                            let providerName: string;

                            if (!row.firstName && !row.lastName) {
                                providerName = t('common.noValue');
                            } else if (row.firstName && row.lastName) {
                                providerName = t('patientDirectory.nameFull', {
                                    first: row.firstName,
                                    last: row.lastName,
                                });
                            } else {
                                providerName = t('patientDirectory.name', {
                                    first: row.firstName,
                                    last: row.lastName,
                                });
                            }

                            return (
                                <tr
                                    data-testid={`row-${row.id}`}
                                    className={activeRows?.indexOf(row.id) !== -1 ? 'active' : ''}
                                    key={row.id}
                                >
                                    <td className="large">
                                        <CheckBox
                                            activeId={activeRows?.filter((id) => id === row.id)[0]}
                                            id={row.id}
                                            dataTestId={`checkbox-${i}`}
                                            onChange={handleChoose}
                                        />
                                        <TruncateText text={providerName} maximumTextWidth={maximumProviderNameWidth} />
                                    </td>
                                    <td className="large">
                                        <TruncateText text={row.email} maximumTextWidth={maximumProviderEmailWidth} />
                                    </td>
                                    <td className="large">
                                        {row.credentials ? (
                                            <TruncateText
                                                text={formatClinicalWorkerCredentials(row.credentials)}
                                                maximumTextWidth={maximumProviderCredentialsWidth}
                                            />
                                        ) : (
                                            <>{t('common.notAvailable')}</>
                                        )}
                                    </td>
                                    <td className="small">{row.npi || t('common.notAvailable')}</td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {!rows.length && showEmptyContent()}
        </div>
    );
};

PendingProvidersTable.defaultProps = {
    activeRows: [],
};

export default PendingProvidersTable;
