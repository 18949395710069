import { IReportDaysSelectMenuItem } from '../patientDirectory.model';

export const SEARCH_BOX_MINIMUM_PREFIX_LENGTH = 2;
export const SEARCH_BOX_MAXIMUM_DROP_DOWN_ITEMS = 5;

export const TABLE_COLUMNS = [
    {
        name: 'table.patientName',
        size: 'large',
    },
    {
        name: 'table.dateOfBirth',
        size: 'small',
    },
    {
        name: 'table.device',
        size: 'small',
    },
    {
        name: 'table.lastUpdated',
        size: 'small',
    },
];

export const LAST_UPLOAD_THRESHOLD_IN_HOURS = 24;

export const LESS_THAN_SEVEN_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '<7',
    value: 7,
};

export const SEVEN_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '7',
    value: 7,
};

export const FOURTEEN_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '14',
    value: 14,
};

export const THIRTY_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '30',
    value: 30,
};

export const SIXTY_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '60',
    value: 60,
};

export const NINETY_REPORT_DAYS_MENU_ITEM: IReportDaysSelectMenuItem = {
    text: '90',
    value: 90,
};

export const INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS: IReportDaysSelectMenuItem[] = [
    {
        ...SEVEN_REPORT_DAYS_MENU_ITEM,
    },
    {
        ...FOURTEEN_REPORT_DAYS_MENU_ITEM,
    },
    {
        ...THIRTY_REPORT_DAYS_MENU_ITEM,
    },
    {
        ...SIXTY_REPORT_DAYS_MENU_ITEM,
    },
    {
        ...NINETY_REPORT_DAYS_MENU_ITEM,
    },
];

export const INITIAL_SELECTED_REPORT_DAYS: IReportDaysSelectMenuItem = INITIAL_REPORT_DAYS_SELECT_MENU_ITEMS[1];
