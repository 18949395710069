import { PdmDeviceClasses } from 'src/models/app.models';

export default function showDeviceClass(deviceClass: string) {
    switch (deviceClass) {
        case PdmDeviceClasses.Omnipod5:
            return 'PDMProduct.omnipod5';
        case PdmDeviceClasses.DASH:
            return 'PDMProduct.dash';
        case PdmDeviceClasses.Eros:
            return 'PDMProduct.eros';
        default:
            return '';
    }
}
