import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ErrorPages } from 'src/models/table.models';
import { RootStateType } from 'src/stores/store';
import { isErrorPage } from 'src/helpers/routing';

import ErrorPage from '../errorPage/ErrorPage';
import RecallPage from '../recallPage/RecallPage';

interface IErrorHandlerNavigation {
    children?: React.ReactNode | React.ReactNode[];
}

// TODO: Remove this component when addressing app's error handling.
const ErrorHandlerNavigation = ({ children }: IErrorHandlerNavigation) => {
    const { t } = useTranslation();
    const activePage = useSelector((state: RootStateType) => state.appState.activePage);

    const content = useMemo(() => {
        switch (activePage) {
            case ErrorPages.authenticationFailure: // TODO: Look into removing. This is not used in the app. Also, if it was to be used, message will be hidden behind app header, because of no styling.
                return <div>{t('errorPage.authenticationFailure')}</div>;
            case ErrorPages.clinicalWorkerNotApproved:
                return (
                    <ErrorPage
                        title={t('errorPage.clinicalWorkerNotApprovedTitle')}
                        description={t('errorPage.clinicalWorkerNotApprovedDescription')}
                    />
                );
            case ErrorPages.clinicalWorkerNotFound:
            case ErrorPages.recall:
                return <RecallPage />;

            default:
                return null;
        }
    }, [activePage, t]);

    if (isErrorPage(activePage)) return content;

    return <>{children}</>;
};

ErrorHandlerNavigation.defaultProps = {
    children: undefined,
};

export default ErrorHandlerNavigation;
