import { useEffect, useRef, useState } from 'react';
import { Tooltip } from '@mui/material';

import { TOOLTIP_ENTER_DELAY } from 'src/models/consts/tooltip';

const defaultProps = {
    showTooltip: true,
};
interface ITruncateTextProps {
    text: string;
    maximumTextWidth: number;
    showTooltip?: boolean;
}

const bemTextElementName = 'truncate-text';

const TruncateText = ({ text, maximumTextWidth, showTooltip }: ITruncateTextProps) => {
    const [isTextOverMaximumWidth, setIsTextOverMaximumWidth] = useState(false);
    const textRef = useRef<HTMLSpanElement>(null);

    useEffect(() => {
        if (textRef.current) {
            setIsTextOverMaximumWidth(Math.ceil(textRef.current.scrollWidth) > maximumTextWidth);
        }
    }, [maximumTextWidth, text]);

    return (
        <Tooltip
            arrow
            className="Tooltip"
            enterDelay={TOOLTIP_ENTER_DELAY}
            PopperProps={{ disablePortal: true }}
            title={showTooltip && isTextOverMaximumWidth ? <span>{text}</span> : null}
        >
            <span
                className={bemTextElementName}
                ref={textRef}
                style={{
                    maxWidth: maximumTextWidth,
                }}
            >
                {text}
            </span>
        </Tooltip>
    );
};

TruncateText.defaultProps = defaultProps;

export default TruncateText;
