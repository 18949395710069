import { AgreementStatus } from 'src/models/app.models';

export const agreements = {
    [AgreementStatus.EULA]: {
        source: 'docs/legal.html',
        searchParams: [{ key: 'noheader', value: 'true' }],
        title: 'agreement.legalTitle',
        checkBox: 'agreement.legalCheckBox',
        button: 'agreement.legalButton',
        AgreementStatus: AgreementStatus.EULA,
    },
};
